import { Box, HStack, tokens } from "@vygruppen/spor-react";
import { FC } from "react";
import styled from "styled-components";
/*
import { DashboardHistoryTabs } from "features/Header/DashboardHistoryTabs";
*/
import { getEnvBool } from "api/common";
import { Environment, environment } from "environment";
import Clock from "features/Header/Clock/Clock";
import DarkModeSwitch from "features/Header/DarkModeSwitch/DarkModeSwitch";
import { Logo } from "features/Header/Logo/Logo";
import { Menu } from "features/Header/Menu/Menu";
import Puzzel from "features/Header/PuzzelTelephone/Puzzel";
import SelectRole from "features/Header/SelectRole/selectRole";
import { OpenAlerts } from "features/Header/TrainResumed/OpenAlerts";

export const HeaderWrapper = styled.div`
  grid-area: header;
  height: 5rem;
  font-weight: 300;
  font-size: 15px;
  align-self: stretch;
  z-index: ${tokens.default.depth["z-index"].banner};
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.__headerBackground};
  border-bottom: 1px solid ${({ theme }) => theme.colorOutline};
`;

const HeaderFlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DevelopmentStatusBox = styled(Box)`
  margin-left: 20px;
  position: absolute;
  text-align: center;
  width: 90px;
  color: grey;
`;

const env: Environment = environment();

const Header: FC = () => (
  <HeaderWrapper>
    {env === Environment.Development && (
      <DevelopmentStatusBox fontSize="2xs" as="span" flex="1" textAlign="left">
        {getEnvBool("VITE_USE_LOCAL_BACKEND") ? "Local" : "Test"}
      </DevelopmentStatusBox>
    )}
    <HStack gap={5} px={4}>
      <Logo />
      <Clock />
    </HStack>
    <HeaderFlexContainer>
      <Puzzel />

      <OpenAlerts />
      <SelectRole />
      <DarkModeSwitch />
      <Menu />
    </HeaderFlexContainer>
  </HeaderWrapper>
);

export default Header;
