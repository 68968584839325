import { Td, Tr } from "@chakra-ui/react";
import { FilterType } from "features/CenterContent/RoleContent/Personellstyrer/DayPlanTrackings/TableFilter/constants";

import { tableColumns } from "features/CenterContent/RoleContent/Personellstyrer/DayPlanTrackings/DayPlanTrackings";
import DayPlanTrackingActionButton from "features/CenterContent/RoleContent/Personellstyrer/DayPlanTrackings/TableContent/ActionButton";
import {
  filterDayPlanTrackings,
  getStatus,
} from "features/CenterContent/RoleContent/Personellstyrer/DayPlanTrackings/TableContent/utils";
import { DayPlanTrackingType } from "features/CenterContent/RoleContent/Personellstyrer/DayPlanTrackings/types";
import { getPhoneNumberWithoutNOPrefix } from "features/CenterContent/shared/utils";
import { FC } from "react";
import { CallButton } from "shared/components/buttons/CallButton";
import { NoDataMessage } from "shared/components/feedback/NoDataMessage/NoDataMessage";
import { formatDayDateString } from "shared/utils/datetime";
import { useTheme } from "styled-components";

type TableProps = {
  data: DayPlanTrackingType[];
  filterParams: FilterType;
  onRowClick: (dayPlanTrackingType: DayPlanTrackingType) => void;
};

export const TableContent: FC<TableProps> = ({
  data,
  filterParams,
  onRowClick,
}) => {
  const theme = useTheme();
  const filteredDayPlanTrackings = filterDayPlanTrackings(data, filterParams);

  const getSeverityColor = (severityStatus: string) => {
    switch (severityStatus) {
      case "WARN":
        return theme.__tableRowWarnHighlight;
      case "ERROR":
        return theme.__tableRowAlertHighlight;
      default:
        return "inherit";
    }
  };

  return filteredDayPlanTrackings.length > 0 ? (
    <>
      {filteredDayPlanTrackings.map((it) => {
        const status = getStatus(it.status);
        return (
          <Tr
            cursor="pointer"
            key={`${it.employeeId}-${it.dayPlanDate}`}
            backgroundColor={getSeverityColor(status.severity)}
            onClick={() => onRowClick(it)}
          >
            <Td>{status.text}</Td>
            <Td>{it.companyCode}</Td>
            <Td>{formatDayDateString(it.lastUpdatedAt)}</Td>
            <Td>{formatDayDateString(it.dutyStart)}</Td>
            <Td>
              {it.fullName
                ? `${it.fullName} (${it.employeeId})`
                : it.employeeId}
            </Td>
            <Td>{`${it.isConductor ? "K" : ""} ${it.isDriver ? "L" : ""}`}</Td>
            <Td>{`${it.dutyId} ${it.deletion ? "(slettet)" : ""}`}</Td>
            <Td>
              {it.phoneNumber && (
                <CallButton
                  phoneNumber={getPhoneNumberWithoutNOPrefix(it.phoneNumber)}
                  size="xs"
                />
              )}
            </Td>
            <Td>
              <DayPlanTrackingActionButton
                status={it.status}
                employeeId={it.employeeId}
              />
            </Td>
          </Tr>
        );
      })}
    </>
  ) : (
    <Tr>
      <Td colSpan={tableColumns.length} p={5}>
        <NoDataMessage reason="Fant ingen tjenestevarslinger som matcher aktivt filter" />
      </Td>
    </Tr>
  );
};
