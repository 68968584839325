import { useMutation } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";

import {
  InfrastructureEventCreatedResponse,
  OpenInfrastructureEventBody,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";

export const useCreateInfrastructureEvent = () => {
  const {
    mutate,
    isPending: isCreateRequestLoading,
    isSuccess: isCreateRequestSuccess,
    isError: isCreateRequestError,
  } = useMutation({
    mutationFn: (body: OpenInfrastructureEventBody) =>
      mutationFnPOST<
        InfrastructureEventCreatedResponse,
        OpenInfrastructureEventBody
      >(`${getBackendUrl()}/infrastructure-event`, body),
  });

  return {
    mutate,
    isCreateRequestLoading,
    isCreateRequestSuccess,
    isCreateRequestError,
  };
};
