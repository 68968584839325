import { Stop } from "features/CenterContent/VehicleDetails/TrainDetails/useTrainRoute";

/**
 *
 * @param stop
 * @param fromStop
 *
 * @returns List of <option /> elements with stops after fromStop
 */
export const getToStopOptions = (stops: Stop[], fromStop: string) =>
  stops.slice(stops.findIndex((s) => s.stopId === fromStop) + 1).map((stop) => (
    <option key={stop.stopId} value={stop.stopId}>
      {stop.name}
    </option>
  ));
