import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { FormSchema } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";

type IncidentHiddenInputProps = {
  incidentIds?: string[];
};

const isEqual = (arr1: string[] | undefined, arr2: string[] | undefined) => {
  if (arr1 === undefined && arr2 === undefined) {
    return true;
  }
  return arr1?.toSorted().join(";") === arr2?.toSorted().join(";");
};

export const IncidentHiddenInput: FC<IncidentHiddenInputProps> = ({
  incidentIds,
}) => {
  const { setValue, getValues } = useFormContext<FormSchema>();
  const currentIds = getValues("trainForm.incidentIds");

  useEffect(() => {
    // This component seem to cause render-loops, so add some guardrails
    if (isEqual(currentIds, incidentIds)) return;
    setValue("trainForm.incidentIds", incidentIds, {
      shouldValidate: true,
    });
  }, [incidentIds]);

  // this has no function yet, but will be replaced by some way to display the incidents
  return <input type="hidden" value={incidentIds} />;
};
