import { BadgeProps } from "@vygruppen/spor-react";
import { TrainIdentifier } from "@vygruppen/vy-train-map";
import { AffectedTrain } from "features/CenterContent/RoleContent/Vaktleder/types";
import { FormSchema } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { TrainEventTypeEnum } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainEventTypeEnum";
import { TrainInfoMessage } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationAggregated";
import {
  isCustomInputType,
  optionalInputWithDescription,
} from "features/CenterContent/shared/operationalInformation/utils";
import {
  InfrastructureEventType,
  customTypeSchema,
} from "shared/types/infrastructureResponse";
import { z } from "zod";

export const getColorSchemeForTrainInfo: (
  trainInfo: TrainInfoMessage,
) => BadgeProps = (trainInfo) => {
  if (trainInfo.state === "CLOSED") {
    return {
      colorScheme: "light-green",
    };
  }

  if (trainInfo.priority <= 3) {
    return {
      colorScheme: "red",
    };
  }
  if (trainInfo.priority <= 5) {
    return {
      colorScheme: "light-yellow",
    };
  }

  return {
    colorScheme: "grey",
  };
};

/**
 * Create shorter names for train info, with fallback to title. CLOSED events will be
 * translated to "Friskmeldt".
 */
export const getInfoTitle = (trainInfo: TrainInfoMessage) => {
  if (trainInfo.state === "CLOSED") {
    return "Friskmeldt";
  }

  const prefacedFromStop = (preface: string) => {
    const { fromStop } = trainInfo;
    if (!fromStop) {
      return "";
    }

    return ` ${preface} ${fromStop}`;
  };

  switch (trainInfo.type) {
    case "TRAIN_CANCELLED":
      return `Innstilt${prefacedFromStop("fra")}`; // Tog innstilt
    case "TRAIN_STOPPING_EXTRA_AT_STATION":
      return `Stopper ekstra${prefacedFromStop("på")}`; // Stopper ekstra på stasjon
    case "TRAIN_DELAYED":
      return `Forsinket${prefacedFromStop("fra")}`; // Tog forsinket
    case "TRAIN_CAPACITY_REDUCED":
      return "Redusert kapasitet"; // Redusert setekapasitet
    case "TRAIN_CAPACITY_INCREASED":
      return "Økt kapasitet"; // Økt setekapasitet
    case "TRAIN_STOPPED":
      return `Står${prefacedFromStop("på")}`; // Tog står
    case "TRAIN_NOT_STOPPING_AT_STATION":
      return `Stopper ikke${prefacedFromStop("på")}`; // Stopper ikke på stasjon
    case "TRAIN_LATE_TO_TRACK":
      return "Sent satt opp"; // Sent satt opp (ikke forsinket)
    case "TRAIN_DELAY_EXPECTED":
      return "Forsinkelse forventes"; // Forsinkelse forventes
    case "TRAIN_MISSING_PRODUCT":
      return "Produkt mangler"; // Produkt mangler
    case "TRAIN_CLOSED_SET":
      return "Avstengt togsett"; // Avstengt togsett
    default:
      return trainInfo.title;
  }
};

// Find last digit, as last char in identifier is not necessarily last char in identifier
const getDirectionDigit = (identifier: string) => {
  const lastDigit = identifier
    .split("")
    .findLast((c) => !Number.isNaN(parseInt(c, 10)));
  return lastDigit !== undefined ? parseInt(lastDigit, 10) : -1;
};

// Assume last digit in given trainIdentifier dictates direction
export const findTrainInOtherDirection = (
  representativeIdentifier: string,
  trains: AffectedTrain[],
) => {
  const directionDigit = getDirectionDigit(representativeIdentifier);

  if (directionDigit === -1) {
    return undefined;
  }

  const directionDigitRemainder = directionDigit % 2;

  // Find a train with a reminder not equal to the representative
  return trains.find((t) => {
    const otherDirectionCandidate = getDirectionDigit(t.trainId.identifier);
    if (otherDirectionCandidate === -1) {
      return false;
    }
    return otherDirectionCandidate % 2 !== directionDigitRemainder;
  });
};

export type InfrastructureConsequence =
  | "TRAIN_CANCELLATIONS"
  | "TRAIN_DELAYS"
  | "TRAIN_CANCELLATIONS_AND_DELAYS";

export const mapInfrastructureConsequence = (
  infraConsequence: InfrastructureConsequence,
): TrainEventTypeEnum.TRAIN_CANCELLED | TrainEventTypeEnum.TRAIN_DELAYED => {
  switch (infraConsequence) {
    case "TRAIN_CANCELLATIONS":
      return TrainEventTypeEnum.TRAIN_CANCELLED;
    case "TRAIN_DELAYS":
      return TrainEventTypeEnum.TRAIN_DELAYED;
    default:
      return TrainEventTypeEnum.TRAIN_CANCELLED;
  }
};

const mapCustomFieldFromInfrastructureEvent = (
  customField: z.infer<typeof customTypeSchema>,
): z.infer<typeof optionalInputWithDescription> => {
  if (
    customField?.type &&
    customField.description.nob &&
    customField.description.eng &&
    isCustomInputType(customField?.type)
  ) {
    return {
      type: customField.type,
      description: {
        NOB: customField.description.nob,
        ENG: customField.description.eng,
      },
    };
  }
  return { type: customField?.type ?? "" };
};

export const mapInfrastructureEventToTrainEvent = (
  data: InfrastructureEventType,
) => {
  if (!data) return undefined;
  const { consequence, action, reason } = data.infrastructureInformation;
  const schema: FormSchema = {
    trainForm: {
      type: mapInfrastructureConsequence(
        consequence?.type as InfrastructureConsequence,
      ),
      action: mapCustomFieldFromInfrastructureEvent(action),
      reason: mapCustomFieldFromInfrastructureEvent(reason),
      dropsLogText: { enabled: false, texts: { NOB: "" } },
    } as FormSchema["trainForm"],
  };

  return schema;
};

export const trainIdKey = (trainId: TrainIdentifier) =>
  `${trainId.countryCode}_${trainId.identifier}_${trainId.nominalDate}`;
