import { FC } from "react";
import {
  CommonSubTypeProps,
  CommonTrainInfoFormProps,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import { AffectedStopsFields } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/AffectedStopsFields";
import { useFormContext } from "react-hook-form";
import {
  DelayedInMinutesFormValue,
  FormSchema,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { Input } from "@vygruppen/spor-react";
import { clamp } from "../../../../../../shared/utils";

export const TrainDelayed: FC<CommonTrainInfoFormProps & CommonSubTypeProps> = (
  props,
) => {
  const { forOtherDirection } = props;
  const { register, setValue } = useFormContext<FormSchema>();
  const MIN_VALUE = 1;
  const MAX_VALUE = 60 * 24;

  const delayFormValue: DelayedInMinutesFormValue = `trainForm.${forOtherDirection ? "delayInMinutesOtherDirection" : "delayInMinutes"}`;

  return (
    <>
      <AffectedStopsFields {...props} />
      <Input
        {...register(delayFormValue)}
        type="number"
        onChange={(e) =>
          setValue(
            delayFormValue,
            clamp(MAX_VALUE, MIN_VALUE, parseInt(e.target.value, 10)),
          )
        }
        width="13.5rem"
        label="Forsinkelse (min)"
        step={1}
      />
    </>
  );
};
