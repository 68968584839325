import { FilterOutline24Icon } from "@vygruppen/spor-icon-react";
import { Box, Button, Divider } from "@vygruppen/spor-react";
import { LogLevel, log } from "api/cloudWatch";
import {
  getLocalStorageTodoFilterSettings,
  setLocalStorageTodoFilterSettings,
} from "api/dropsLocalStorage";
import { useEffect } from "react";
import { SectionTitle, Text } from "shared/components/typography/TitleStyles";
import { dropsRole } from "stores/dropsRole";
import SkeletonLoader from "../../../shared/components/feedback/SkeletonLoader/SkeletonLoader";
import {
  ListContainer,
  TodoListHeader,
  TodoMessageWrapper,
} from "./TodoStyles";
import { TodoFilter } from "./todoFilter/TodoFilter";
import useTodoFilterState, {
  defaultTodoFilterState,
} from "./todoFilter/useTodoFilterState";
import TodoList from "./todoList/TodoList";
import {
  useData,
  useSetShowFilter,
  useShowFilter,
  useStatus,
} from "./useTodoContext";

export type TodoStatus = "active" | "inActive";

const TodoComponent = () => {
  const {
    todoFilterState,
    setTodoFilterState,
    toggleRoles,
    toggleVehicleSetTypes,
    setTrainNumber,
    toggleTodoStatus,
  } = useTodoFilterState(getLocalStorageTodoFilterSettings());

  const data = useData();
  const status = useStatus();

  const { role: currentRole } = dropsRole();

  useEffect(() => {
    setLocalStorageTodoFilterSettings(todoFilterState);
  }, [todoFilterState]);

  useEffect(() => {
    if (!todoFilterState.roles.includes(currentRole))
      setTodoFilterState({
        ...defaultTodoFilterState,
        roles: [currentRole],
      });
  }, [currentRole]);

  const setShowFilter = useSetShowFilter();
  const showFilter = useShowFilter();

  switch (status) {
    case "pending":
      return (
        <TodoMessageWrapper>
          <Divider />
          <TodoListHeader>
            <SectionTitle>Todo</SectionTitle>
            <Button
              variant="ghost"
              size="sm"
              leftIcon={<FilterOutline24Icon />}
            >
              Filter
            </Button>
          </TodoListHeader>
          <Divider />
          <SkeletonLoader skeletonType="todo" />
        </TodoMessageWrapper>
      );
    case "error":
      log(LogLevel.error, "TodoComponent", "Feil ved henting av todo-data");
      return (
        <TodoMessageWrapper>
          <Divider />
          <Box m="8px">
            <Text>Feil ved henting av todo-data</Text>
          </Box>
          <Divider />
        </TodoMessageWrapper>
      );
    case "success":
      return (
        <TodoMessageWrapper>
          <Divider />
          <TodoListHeader>
            <SectionTitle>Todo</SectionTitle>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setShowFilter(!showFilter)}
              leftIcon={<FilterOutline24Icon />}
            >
              Filter
            </Button>
          </TodoListHeader>
          <Divider />
          {showFilter && (
            <TodoFilter
              todoFilterState={todoFilterState}
              clearTodoFilterState={() => {
                setTodoFilterState({
                  ...defaultTodoFilterState,
                  roles: [currentRole],
                });
              }}
              toggleRoles={toggleRoles}
              toggleVehicleSetTypes={toggleVehicleSetTypes}
              setTrainNumber={setTrainNumber}
              toggleTodoStatus={toggleTodoStatus}
            />
          )}
          <ListContainer>
            {data && (
              <TodoList todoList={data} todoFilterState={todoFilterState} />
            )}
          </ListContainer>
        </TodoMessageWrapper>
      );
    default:
      return null;
  }
};

export default TodoComponent;
