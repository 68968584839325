import React, { useEffect, useState } from "react";
import { CallIconStyled, CallStatusStyled } from "../../../../PuzzelStyle";
import { Text } from "../../../../../../../shared/components/typography/TitleStyles";
import {
  IqSessionRequestType,
  IqSessionStateTypes,
  SessionCommands,
  Status,
} from "../../../types";
import { usePuzzelExtendedStateInformation } from "../../../../provider/contexts/ListenForCallStatusContext";
import { useApiAction } from "../../../../../../../api/drops/useApiAction";
import { performPuzzelCatalogSearch } from "../../../../../../../api/puzzel/puzzel";
import { usePuzzelAuthenticationContext } from "../../../../provider/contexts/PuzzelAuthenticationContext";
import { useUserValidationState } from "../../../../provider/contexts/UserValidationContext";
import { assembleFullName, removePhoneNumberPrefix } from "../../../../utils";
import CallerNumberInfo from "../../../CallerNumberInfo";
import CallTransfer from "./CallTransfer/CallTransfer";
import SessionCommand from "../../../SessionCommand";

type Props = {
  req: IqSessionRequestType;
};

const StatusConnected = (props: Props) => {
  const { req } = props;
  const { status, remoteAddress } = req;
  let searchString = remoteAddress;
  searchString = removePhoneNumberPrefix(
    searchString,
    usePuzzelAuthenticationContext().phoneNumberPrefix,
  );
  searchString = removePhoneNumberPrefix(
    searchString,
    usePuzzelAuthenticationContext().functionalNumberPrefix,
  );

  const puzzelUserId = useUserValidationState().userId;
  const { iqSessionState }: any = usePuzzelExtendedStateInformation();

  const ConsultationCall =
    iqSessionState === IqSessionStateTypes.ConsultationCall;
  const [callerInfo, setCallerInfo] = useState("");
  const [catalogRawResponse, performCatalogSearch] = useApiAction(
    performPuzzelCatalogSearch,
    false,
  );

  useEffect(() => {
    if (catalogRawResponse.data?.result.length > 0) {
      const fullName = assembleFullName(catalogRawResponse.data?.result);
      setCallerInfo(fullName);
    } else {
      setCallerInfo("");
    }
  }, [catalogRawResponse]);

  useEffect(() => {
    if (status === Status.Connected) {
      performCatalogSearch({
        searchString,
        userId: puzzelUserId,
      });
    }
  }, [status]);

  if (status === Status.Connected) {
    return (
      <div style={{ display: "flex", gap: "1.5rem", alignItems: "center" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <Text bold>I samtale</Text>
          <CallerNumberInfo
            status={status}
            remoteAddress={`${callerInfo} ${searchString}`}
          />
        </div>
        <CallIconStyled />
        <SessionCommand
          command={
            ConsultationCall
              ? SessionCommands.ConsultOff
              : SessionCommands.Hangup
          }
          requestId={req.id}
        />
        {!ConsultationCall ? (
          <SessionCommand command={SessionCommands.MuteOn} requestId={req.id} />
        ) : null}
        {!ConsultationCall ? (
          <CallStatusStyled>
            <CallTransfer requestId={req.id} />
          </CallStatusStyled>
        ) : null}
      </div>
    );
  }
  return null;
};

export default StatusConnected;
