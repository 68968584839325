import { roleTexts, UserRole } from "shared/types/roles";
import { Checkbox, Flex } from "@vygruppen/spor-react";
import { CheckboxListContainer, FilterWrapper } from "../TodoStyles";
import {
  FILTER_STATUS_LIST,
  TodoFilterState,
  TodoFilterStatus,
  VEHICLE_SET_TYPES,
} from "./useTodoFilterState";

interface TodoFilterProps {
  todoFilterState: TodoFilterState;
  clearTodoFilterState: () => void;
  toggleRoles: (role: UserRole) => void;
  toggleVehicleSetTypes: (vehicleSetType: string) => void;
  setTrainNumber: (trainNumber: string) => void;
  toggleTodoStatus: (status: TodoFilterStatus) => void;
}

export const TodoFilter = ({
  todoFilterState,
  clearTodoFilterState,
  toggleRoles,
  toggleVehicleSetTypes,
  setTrainNumber,
  toggleTodoStatus,
}: TodoFilterProps) => (
  <FilterWrapper>
    <CheckboxListContainer>
      <Flex style={{ flex: 2 }} flexDirection="column" gap={1}>
        {roleTexts.map((role) => (
          <Checkbox
            name={`filter-${role.text}`}
            key={role.value}
            isChecked={todoFilterState.roles.includes(role.value)}
            onChange={() => toggleRoles(role.value)}
            value={role.text}
          >
            {role.text}
          </Checkbox>
        ))}
      </Flex>
      <Flex style={{ flex: 1 }} flexDirection="column" gap={1}>
        {VEHICLE_SET_TYPES.map(({ text, value }) => (
          <Checkbox
            name={`filter-${text}`}
            key={text}
            isChecked={todoFilterState.vehicleSetTypes.includes(value)}
            onChange={() => toggleVehicleSetTypes(value)}
            value={text}
          >
            {text}
          </Checkbox>
        ))}
      </Flex>
    </CheckboxListContainer>

    <CheckboxListContainer>
      {Object.entries(FILTER_STATUS_LIST).map(([status, statusText]) => (
        <div key={status}>
          <Checkbox
            name={`filter-${status}`}
            key={status}
            isChecked={todoFilterState.todoStatus.includes(
              status as TodoFilterStatus,
            )}
            onChange={() => toggleTodoStatus(status as TodoFilterStatus)}
            value={statusText}
          >
            {statusText}
          </Checkbox>
        </div>
      ))}
    </CheckboxListContainer>
  </FilterWrapper>
);
