import { useQuery } from "@tanstack/react-query";
import { queryFnPOST } from "api/tanStackQuery/helpers";
import {
  OperationalIdentifier_JSON,
  PreviewResponseBatch,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";
import { getBackendUrl } from "api/common";
import { trainFormSchema } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import {
  TrainOnTrack,
  trainFormToRequestBody,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/utils";
import { TrainInfoRequest } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationRequest";
import { BatchFormState } from "./TrainInfoBatchModal";

type PreviewProps = {
  batchFormState: BatchFormState;
  trains: TrainOnTrack[];
};

type Body = {
  info:
    | (TrainInfoRequest & { trainIdentifier: OperationalIdentifier_JSON })
    | null;
  train: OperationalIdentifier_JSON;
}[];

const QUERY_KEY = "OPERATIONAL_INFO_BATCH_PREVIEW";

export const useOperationalInfoPreviews = ({
  batchFormState,
  trains,
}: PreviewProps) => {
  const dict: { [key: string]: TrainOnTrack } = trains.reduce(
    (acc, curr) => ({
      ...acc,
      [`${curr.identifier}_${curr.nominalDate}`]: curr,
    }),
    {},
  );

  const parsedForms = Object.values(batchFormState).flatMap((trainGroup) =>
    Object.values(trainGroup).map(({ formState, affectedTrains }) => ({
      formState: trainFormSchema.safeParse(formState.trainForm),
      affectedTrains: affectedTrains.map(
        ({ trainId }) => dict[`${trainId.identifier}_${trainId.nominalDate}`],
      ),
    })),
  );

  const { status: previewStatus, data: previewData } = useQuery({
    queryKey: [QUERY_KEY, ...parsedForms.map((t) => t.formState), ...trains],
    refetchOnWindowFocus: false,
    queryFn: ({ signal }) => {
      const body: Body = parsedForms
        .filter((t) => t.formState.success)
        .flatMap(({ affectedTrains, formState }) =>
          affectedTrains.map((train) => {
            const trainForm = formState.success
              ? trainFormToRequestBody(formState.data, train)
              : null;

            let info: Body[0]["info"] = null;
            if (trainForm) {
              info = {
                ...trainForm,
                trainIdentifier: {
                  operational_identifier: train.identifier,
                  country_code: train.countryCode,
                  nominal_date: train.nominalDate,
                },
              };
            }

            return {
              info,
              train: {
                operational_identifier: train.identifier,
                country_code: train.countryCode,
                nominal_date: train.nominalDate,
              },
            };
          }),
        );
      return queryFnPOST<PreviewResponseBatch>({
        url: `${getBackendUrl()}/trainInformation/preview`,
        body,
        signal,
      });
    },
  });

  return { previewData, previewStatus };
};
