export const appPalette = {
  wine: "#99002E",
  raspberry: "#CC003D",
  rose: "#FF3370",
  operations: "#383E42",
  blossom: "#FFCCDB",
  whiteSmoke: "#F7F7F7",
  whiteSnow: "#FDFDFD",
  darkGloomyShade: "#212121",
  iconHighlight: "#00685E",
};
