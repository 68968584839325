import { ChangeEvent, FC, ReactElement } from "react";
import {
  CommonSubTypeProps,
  CommonTrainInfoFormProps,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import { AffectedStopsFields } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/AffectedStopsFields";
import { ChoiceChip, FormLabel, Stack } from "@vygruppen/spor-react";
import { useFormContext } from "react-hook-form";
import {
  FormSchema,
  TrainMissingProductsEnum,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import {
  CafeFill24Icon,
  CafeOutline24Icon,
  FamilyFill24Icon,
  FamilyOutline24Icon,
  PetAllowedFill24Icon,
  PetAllowedOutline24Icon,
  PetNotAllowedFill24Icon,
  PetNotAllowedOutline24Icon,
  PlussSeatFill24Icon,
  PlussSeatOutline24Icon,
  RecliningSeatFill24Icon,
  RecliningSeatOutline24Icon,
  Sleep6BedsFill24Icon,
  Sleep6BedsOutline24Icon,
  SleepFill24Icon,
  SleepOutline24Icon,
  SofaFill24Icon,
  SofaOutline24Icon,
  SoundOffFill24Icon,
  SoundOffOutline24Icon,
  ToiletFill24Icon,
  ToiletOutline24Icon,
  WifiFill24Icon,
  WifiOutline24Icon,
} from "@vygruppen/spor-icon-react";
import { Select } from "shared/components/forms/Select";

type ProductType = {
  type: TrainMissingProductsEnum;
  name: string;
  outlineIcon: ReactElement;
  fillIcon: ReactElement;
};

const products: ProductType[] = [
  {
    type: "FIRST_CLASS_CAR",
    name: "Pluss",
    outlineIcon: <PlussSeatOutline24Icon />,
    fillIcon: <PlussSeatFill24Icon />,
  },
  {
    type: "SLEEPING_CAR",
    name: "Sovevogn",
    outlineIcon: <SleepOutline24Icon />,
    fillIcon: <SleepFill24Icon />,
  },
  {
    type: "FAMILY_CAR",
    name: "Familievogn",
    outlineIcon: <FamilyOutline24Icon />,
    fillIcon: <FamilyFill24Icon />,
  },
  {
    type: "ANIMALS_ALLOWED_CAR",
    name: "Dyr tillatt vogn",
    outlineIcon: <PetAllowedOutline24Icon />,
    fillIcon: <PetAllowedFill24Icon />,
  },
  {
    type: "ANIMALS_NOT_ALLOWED_CAR",
    name: "Dyrefri vogn",
    outlineIcon: <PetNotAllowedOutline24Icon />,
    fillIcon: <PetNotAllowedFill24Icon />,
  },
  {
    type: "RESTAURANT_CAR",
    name: "Kafévogn",
    outlineIcon: <CafeOutline24Icon />,
    fillIcon: <CafeFill24Icon />,
  },
  {
    type: "WIFI",
    name: "Trådløst internett",
    outlineIcon: <WifiOutline24Icon />,
    fillIcon: <WifiFill24Icon />,
  },
  {
    type: "QUIET_SECTION",
    name: "Stille",
    outlineIcon: <SoundOffOutline24Icon />,
    fillIcon: <SoundOffFill24Icon />,
  },
  {
    type: "FUNCTIONING_TOILETS",
    name: "Fungerende toaletter",
    outlineIcon: <ToiletOutline24Icon />,
    fillIcon: <ToiletFill24Icon />,
  },
  {
    type: "COMPARTMENT",
    name: "Kupé",
    outlineIcon: <SofaOutline24Icon />,
    fillIcon: <SofaFill24Icon />,
  },
  {
    type: "REST",
    name: "Hvile",
    outlineIcon: <Sleep6BedsOutline24Icon />,
    fillIcon: <Sleep6BedsFill24Icon />,
  },
  {
    type: "PLUS_NIGHT",
    name: "PlussNatt",
    outlineIcon: <RecliningSeatOutline24Icon />,
    fillIcon: <RecliningSeatFill24Icon />,
  },
];

export const TrainMissingProduct: FC<
  CommonTrainInfoFormProps & CommonSubTypeProps
> = ({ forOtherDirection, ...props }) => {
  const { setValue, getValues, register } = useFormContext<FormSchema>();

  const productFormValue = forOtherDirection
    ? "trainForm.productsOtherDirection"
    : "trainForm.products";

  const coverageFormValue = forOtherDirection
    ? "trainForm.coverageOtherDirection"
    : "trainForm.coverage";

  const productIsChecked = (product: TrainMissingProductsEnum): boolean =>
    getValues(productFormValue)?.some((v) => v === product) ?? false;

  const onProductClick = (
    product: TrainMissingProductsEnum,
    evt: ChangeEvent<HTMLInputElement>,
  ) => {
    const oldValues = getValues(productFormValue) ?? [];
    if (evt.target.checked) {
      if (!productIsChecked(product)) {
        setValue(productFormValue, [...oldValues, product], {
          shouldValidate: true,
        });
      }
    } else {
      setValue(
        productFormValue,
        oldValues.filter((v) => v !== product),
        { shouldValidate: true },
      );
    }
  };

  return (
    <>
      <AffectedStopsFields {...props} forOtherDirection={forOtherDirection} />
      <div>
        <FormLabel marginBottom="2">Produkter som mangler:</FormLabel>
        <Stack flexDirection="row" flexWrap="wrap">
          {products.map((product) => (
            <ChoiceChip
              key={product.type}
              icon={{
                default: product.outlineIcon,
                checked: product.fillIcon,
              }}
              onChange={(evt) => onProductClick(product.type, evt)}
              isChecked={productIsChecked(product.type)}
            >
              {product.name}
            </ChoiceChip>
          ))}
        </Stack>
      </div>
      <Select
        {...register(coverageFormValue)}
        label="Dekning"
        placeholder="Velg hvor produktene mangler"
      >
        <option value="WHOLE_TRAIN">Hele toget</option>
        <option value="FRONT_SET">Fremre togsett</option>
        <option value="REAR_SET">Bakre togsett</option>
      </Select>
    </>
  );
};
