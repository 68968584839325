import { Tooltip } from "@chakra-ui/react";
import { EditOutline18Icon } from "@vygruppen/spor-icon-react";
import {
  Badge,
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Skeleton,
  SkeletonText,
  Stack,
  StaticAlert,
  Text,
} from "@vygruppen/spor-react";
import { ErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import {
  FormSchema,
  formToStationInformationRequest,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/formSchema";
import { SubTypeInputs } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/subTypeInputs";
import { StationInfoRequest } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/types/stationInformationRequest";
import { getStopsTexts } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stretchBuilderTexts";
import {
  EventGroup,
  useOperationalInformationTypes,
} from "features/CenterContent/shared/operationalInformation/hooks/useOperationalInformationTypes";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ActionModal } from "shared/components/ActionModal";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { Select } from "shared/components/forms/Select";
import { useStretchBuilder } from "stores/useStretchBuilder";

type ModalContentProps = {
  onFormSubmit: (body: StationInfoRequest) => void;
  formSubmitStatus: "error" | "pending" | "idle" | "success";
};

export const StationEventModal: FC<ModalContentProps> = ({
  onFormSubmit,
  formSubmitStatus,
}) => {
  const [
    selectedStops,
    allStopsSelected,
    closeStretchBuilder,
    stretchBuilderModalState,
    setStretchBuilderModalState,
  ] = useStretchBuilder((state) => [
    state.selectedStops,
    state.allStopsSelected,
    state.closeStretchBuilder,
    state.stretchBuilderModalState,
    state.setStretchBuilderModalState,
  ]);

  const mode = stretchBuilderModalState.type;

  const { data: opInfoTypes, status: opInfoTypesStatus } =
    useOperationalInformationTypes();

  const formMethods = useFormContext<FormSchema>();
  const type = formMethods.watch("stationForm.stationInformationType");
  const stationFormErrors = formMethods.formState.errors.stationForm;

  useEffect(() => {
    if (selectedStops.length > 0) {
      formMethods.setValue(
        "stationForm.stops",
        selectedStops.map((stop) => stop.id),
      );
    }
  }, [selectedStops]);

  useEffect(() => {
    if (type) {
      formMethods.clearErrors("stationForm");
    }
  }, [type]);

  if (mode !== "create" && mode !== "edit") return null;

  const stopsTexts = getStopsTexts(selectedStops, allStopsSelected);

  return (
    <ActionModal
      title={`${mode === "create" ? "Opprett" : "Endre"} stasjonshendelse`}
      actionTitle={`${mode === "create" ? "Opprett" : "Endre"} hendelse`}
      onClose={closeStretchBuilder}
      onSubmit={formMethods.handleSubmit((data) =>
        onFormSubmit(formToStationInformationRequest(data)),
      )}
      isLoading={formSubmitStatus === "pending"}
      isSuccess={formSubmitStatus === "success"}
      isError={formSubmitStatus === "error"}
      successMessage={
        mode === "create" ? "Hendelse opprettet" : "Hendelsen er endret"
      }
      failureMessage={`Kunne ikke ${mode === "create" ? "opprette" : "endre"} hendelsen. Prøv på nytt, eller kontakt IT hvis feilen vedvarer.`}
    >
      <ErrorBoundary>
        <Box w="100%" display="grid" gap={3}>
          <Flex gap="6px">
            <Text>
              {selectedStops.length < 2 ? "Stasjon: " : "Stasjoner: "}
              {stopsTexts.slice(0, 3).map((it) => (
                <Badge
                  key={it}
                  colorScheme="light-green"
                  borderRadius="9px"
                  fontWeight="400"
                  paddingX="12px !important"
                  marginX="3px"
                >
                  {it}
                </Badge>
              ))}
              {stopsTexts.length > 3 && (
                <Tooltip label={stopsTexts.slice(3).join(", ")}>
                  <Badge
                    colorScheme="light-green"
                    borderRadius="9px"
                    fontWeight="400"
                    paddingX="12px !important"
                    marginX="3px"
                  >
                    +{stopsTexts.length - 3}
                  </Badge>
                </Tooltip>
              )}
            </Text>
            <IconButton
              size="xs"
              variant="tertiary"
              icon={<EditOutline18Icon />}
              onClick={() =>
                setStretchBuilderModalState({
                  ...stretchBuilderModalState,
                  stretchBuilderMode: true,
                })
              }
              aria-label="Rediger stasjoner"
              width="fit-content"
              height="fit-content"
            />
          </Flex>
          {opInfoTypesStatus === "pending" && (
            <>
              <Stack gap={2}>
                <Skeleton height={6} />
                <Skeleton height={6} />
              </Stack>
              <SkeletonText noOfLines={3} width="30%" />
            </>
          )}

          {opInfoTypesStatus === "error" && <FailureMessage />}

          {opInfoTypesStatus === "success" && (
            <>
              <FormControl
                isInvalid={!!stationFormErrors?.stationInformationType}
                as={Stack}
                gap="2"
              >
                <Select
                  {...formMethods.register(
                    "stationForm.stationInformationType",
                    {},
                  )}
                  label="Hendelse"
                  placeholder="Velg en hendelse"
                  isInvalid={!!stationFormErrors?.stationInformationType}
                >
                  {opInfoTypes.events
                    .filter((event) => event.group === EventGroup.STATION)
                    .toSorted((a, b) => a.label.localeCompare(b.label))
                    .map((event) => (
                      <option key={event.type} value={event.type}>
                        {event.label}
                      </option>
                    ))}
                </Select>

                <FormErrorMessage>
                  {stationFormErrors?.stationInformationType?.message}
                </FormErrorMessage>
              </FormControl>

              {type && <SubTypeInputs type={type} />}

              {type === "STATION_GENERAL" && (
                <StaticAlert variant="info" id="infoBoxActionModal">
                  Hendelsen inneholder egendefinert tekst. Sjekk at beskrivelsen
                  ser riktig ut før du går videre.
                </StaticAlert>
              )}
            </>
          )}
        </Box>
      </ErrorBoundary>
    </ActionModal>
  );
};
