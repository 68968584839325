import { WarningOutline24Icon } from "@vygruppen/spor-icon-react";
import { Box, HStack, Stack, Text } from "@vygruppen/spor-react";
import { FC } from "react";
import { BORDER_RADIUS } from "shared/components/feedback/InfoMessage/style";
import { useTheme } from "styled-components";
import { TrainRouteDiscrepancies } from "./UnhandledTrains";

export const TrainRouteDiscrepancyWarning: FC<{
  trainRouteDiscrepancies: TrainRouteDiscrepancies;
}> = ({ trainRouteDiscrepancies }) => {
  const theme = useTheme();

  if (trainRouteDiscrepancies === null) return null;

  const longestSeriesKey = Object.keys(trainRouteDiscrepancies).reduce(
    (currentLength, key) =>
      key.length > currentLength ? key.length : currentLength,
    0,
  );

  return (
    <Box
      p={2}
      m={2}
      border={`1px solid ${theme.__borderColor}`}
      borderRadius={BORDER_RADIUS}
    >
      <HStack alignItems="flex-start">
        <WarningOutline24Icon flex="0 0 auto" />
        <Stack>
          <Text>
            Noen tog i serien(e) kjører ulike traseer. Sjekk at du velger
            riktige tog til behandling.
          </Text>
          <div>
            {Object.entries(trainRouteDiscrepancies).map(([series, group]) => (
              <HStack
                gridAutoColumns={`${(longestSeriesKey + 3.5) * 8}px 1fr`}
                key={series}
                display="grid"
                mt={1}
              >
                <Text
                  alignSelf="flex-start"
                  gridColumn="1"
                  variant="xs"
                  fontWeight="bold"
                  textAlign="center"
                  border={`1px solid ${theme.colorOutline}`}
                  borderRadius={26}
                  px={1.5}
                  py={0.5}
                >
                  {series}
                </Text>
                <Text gridColumn="2" maxWidth="100%">
                  {group
                    .map((route) => route.split("___").join(`\u2013 `).trim())
                    .join(", ")}
                </Text>
              </HStack>
            ))}
          </div>
        </Stack>
      </HStack>
    </Box>
  );
};
