import { AffectedTrain } from "features/CenterContent/RoleContent/Vaktleder/types";

// Group Bergensbanen 61 ... 606 together with 6X
// Three-digit codes other than 600 are shortened
export const getTrainSeriesFromIdentifier = (identifier: string) => {
  if (identifier.length === 2 || identifier.startsWith("60")) {
    return `${identifier[0]}x`;
  }
  if (identifier.length === 4) {
    return `${identifier[0]}${identifier[1]}xx`;
  }

  return `${identifier.substring(0, 1)}${identifier.substring(1).replaceAll(/[0-9]/g, "x")}`;
};

export type GroupedTrains = {
  [incidentId: string]: {
    trainSeries: {
      [trainSeriesId: string]: AffectedTrain[];
    };
    stretchName: string;
    eventUuid: string;
  };
};

export const groupTrains = (affectedTrains: AffectedTrain[]) =>
  affectedTrains.reduce((acc: GroupedTrains, affectedTrain) => {
    const { trainId, incidentId, eventStretchName, eventUuid } = affectedTrain;
    const { identifier } = trainId;

    const trainSeries = getTrainSeriesFromIdentifier(identifier);

    if (acc[incidentId] && acc[incidentId].trainSeries) {
      if (acc[incidentId].trainSeries[trainSeries]) {
        acc[incidentId].trainSeries[trainSeries].push(affectedTrain);
      } else {
        acc[incidentId].trainSeries = {
          ...acc[incidentId].trainSeries,
          [trainSeries]: [affectedTrain],
        };
      }
    } else {
      acc[incidentId] = {
        trainSeries: {
          [trainSeries]: [affectedTrain],
        },
        stretchName: eventStretchName,
        eventUuid,
      };
    }
    return acc;
  }, {});

export type GroupedAffectedTrainIdentifiers = {
  [incidentId: string]: {
    trains: AffectedTrain[];
    stretchName: string;
  };
};

type TrainSeriesGroup = {
  [key: string]: string[];
};

// Check if a train in the same series has different start and stop stations
// TODO: Expand to check for discrepancies en route. 2xx series is a prime example
export const findSeriesDiscrepancies = (trains: AffectedTrain[]) => {
  const trainGroupByTrainSeries = trains.reduce(
    (groups: TrainSeriesGroup, train) => {
      const series = getTrainSeriesFromIdentifier(train.trainId.identifier);

      // Treat oncoming and ongoing trains as the same
      const trainString = [train.origin ?? "YYY", train.destination ?? "ZZZ"]
        .toSorted()
        .join("___");

      if (groups[series]) {
        return {
          ...groups,
          [series]: Array.from(new Set([...groups[series], trainString])),
        };
      }
      return {
        ...groups,
        [series]: [trainString],
      };
    },
    {},
  );

  const discrepantSeries = { ...trainGroupByTrainSeries };

  Object.entries(trainGroupByTrainSeries).forEach(([series, group]) => {
    if (group.length <= 1) {
      delete discrepantSeries[series];
    }
  });

  return discrepantSeries;
};
