import React, { ReactElement } from "react";
import styled from "styled-components";
import { FCWC } from "../../utils/FCWC";

type Variant = "primary" | "secondary";
type Size = "x-small" | "small" | "medium" | "large";
export const PrimaryLinkBase = styled.a`
  display: flex;
  align-items: center;
  font-family: "Vy Sans", sans-serif;
  gap: 3px;
  transition: all 150ms ease-in-out;

  &:link,
  &:visited {
    cursor: pointer;
    color: ${({ theme }) => theme.__textColor};
    text-decoration: underline;
    text-underline-position: under;
    border-radius: 6px;

    path {
      fill: ${({ theme }) => theme.__textColor};
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.__primaryLinkHoverBackgroundColor};
    text-decoration: none;
    box-shadow: ${({ theme }) => theme.__primaryLinkHoverBackgroundColor} 0 0 0
      3px;
  }

  &:focus,
  &:focus-visible {
    background-color: ${({ theme }) => theme.__primaryLinkFocusBackgroundColor};
    box-shadow: ${({ theme }) => theme.__primaryLinkFocusBackgroundColor} 0 0 0
      3px;
    color: ${({ theme }) => theme.__primaryLinkFocusColor};
    text-decoration: none;
    outline: none;

    path {
      fill: ${({ theme }) => theme.__primaryLinkFocusColor};
    }
  }

  &:active {
    background-color: ${({ theme }) =>
      theme.__primaryLinkActiveBackgroundColor};
    box-shadow: ${({ theme }) => theme.__primaryLinkActiveBackgroundColor} 0 0 0
      3px;
    color: ${({ theme }) => theme.__primaryLinkActiveColor};
    text-decoration: none;

    path {
      fill: ${({ theme }) => theme.__primaryLinkActiveColor};
    }
  }
`;

interface PrimaryLinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  size?: Size;
  variant?: Variant;
  isLoading?: boolean;
  isDynamic?: boolean;
}

export const PrimaryLink: FCWC<PrimaryLinkProps> = ({
  children,
  leftIcon,
  rightIcon,
  ...rest
}) => (
  <PrimaryLinkBase {...rest}>
    {leftIcon}
    {children}
    {rightIcon}
  </PrimaryLinkBase>
);
