import { ObtrusiveScrollCss } from "shared/components/transitions/scroll";
import { dropsRole } from "stores/dropsRole";
import styled from "styled-components";
import { ExternalLinkItem } from "./ExternalLinkItem";
import { AdminLink, adminLinks } from "./links";

const ExternalLinkList = styled.ul`
  ${ObtrusiveScrollCss};
  list-style: none;
  padding: 0;
  overflow: auto;
  margin: 0;
`;

const LinkTitle = styled.div`
  font-size: 1rem;
  padding: 12px 0;
  margin-top: 6px;
  font-family: "Vy Sans", sans-serif;
  font-weight: bold;
  color: ${({ theme }) => theme.__textColor};
`;

export const ExternalLinks = () => {
  const { role: roleState } = dropsRole();

  const linksForUserRole = adminLinks
    .filter((link: AdminLink) => link.roles.includes(roleState))
    .sort((a: AdminLink, b: AdminLink) => a.label.localeCompare(b.label));

  if (linksForUserRole.length > 0) {
    return (
      <>
        <LinkTitle>Lenker</LinkTitle>
        <ExternalLinkList>
          {linksForUserRole.map((link) => (
            <ExternalLinkItem
              key={`link-admin-${link.label}`}
              url={link.url}
              name={link.label}
            />
          ))}
        </ExternalLinkList>
      </>
    );
  }

  return null;
};
