import { FC } from "react";
import { HStack, SkeletonText, Text } from "@vygruppen/spor-react";
import { TrainOutline24Icon } from "@vygruppen/spor-icon-react";
import { useTrainRouteWithoutRefetch } from "features/CenterContent/VehicleDetails/TrainDetails/useTrainRoute";
import { InfrastructureEventType } from "shared/types/infrastructureResponse";
import { SubTypeInputs } from ".";
import { TrainEventType } from "../formSchema";
import { CommonSubTypeProps } from "../TrainInfoBatchModal";

type BatchFormProps = {
  type: TrainEventType;
  infrastructureEvent: InfrastructureEventType;
};

const DirectionDescriptor: FC<{
  direction: "left" | "right";
  trainRepresentative: { trainId: string; nominalDate: string };
}> = ({ direction, trainRepresentative }) => {
  const { trainId, nominalDate } = trainRepresentative;
  const { data, status } = useTrainRouteWithoutRefetch({
    trainId,
    nominalDate,
    countryCode: "NO",
  });

  const skeleton = <SkeletonText height={1} width="10" noOfLines={1} />;

  const origin = data?.train?.stops[0].name ?? data?.train?.origin;
  const destination =
    data?.train?.stops.at(-1)?.name ?? data?.train?.destination;

  return (
    <HStack>
      <TrainOutline24Icon
        style={{ transform: direction === "left" ? "scaleX(-1)" : "scaleX(1)" }}
      />
      {status === "pending" ? (
        <HStack alignItems="center">
          {skeleton}
          <Text fontWeight="bold"> - </Text>
          {skeleton}
        </HStack>
      ) : (
        <Text fontWeight="bold">
          {origin && destination && `${origin} \u2013 ${destination}`} (f.eks{" "}
          {trainId})
        </Text>
      )}
    </HStack>
  );
};

export const BatchSubTypeInput: FC<BatchFormProps & CommonSubTypeProps> = ({
  type,
  trainRepresentative,
  trainRepresentativeOtherDirection,
  infrastructureEvent,
}) => (
  <>
    <DirectionDescriptor
      direction="right"
      trainRepresentative={trainRepresentative}
    />
    <SubTypeInputs
      infrastructureEvent={infrastructureEvent}
      type={type}
      mode="create"
      {...trainRepresentative}
    />
    {trainRepresentativeOtherDirection && (
      <>
        <DirectionDescriptor
          direction="left"
          trainRepresentative={trainRepresentativeOtherDirection}
        />
        <SubTypeInputs
          infrastructureEvent={infrastructureEvent}
          forOtherDirection
          type={type}
          mode="create"
          {...trainRepresentativeOtherDirection}
        />
      </>
    )}
  </>
);
