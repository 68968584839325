import {
  BaseGpsData,
  BaseGpsResponseSchema,
  BusGpsData,
  TrainGpsData,
} from "./types";

const NOCountryCodePrefix = "47";

export const getPhoneNumberWithoutNOPrefix = (phoneNumber: string) => {
  if (phoneNumber.startsWith(NOCountryCodePrefix) && phoneNumber.length > 8) {
    return phoneNumber.slice(NOCountryCodePrefix.length);
  }
  return phoneNumber;
};

export const clamp = (max: number, min: number, value: number) =>
  Math.min(Math.max(value, min), max);

export const addClosestNeighbor = (
  oldData: BusGpsData[] | TrainGpsData[],
  newData: BaseGpsData[],
) => {
  const closestIndexes = oldData.map((it) =>
    it.closestNeighbor
      ? oldData.findIndex(
          (vehicle) => vehicle.closestNeighbor === it.closestNeighbor,
        )
      : null,
  );
  newData.forEach((it, index) => {
    const closestIndex = closestIndexes[index];
    if (!closestIndex) return;
    it.closestNeighbor = newData[closestIndex];
  });
};

export const busGpsDataToBaseGpsData = (data: BusGpsData[]) => {
  const baseGps = BaseGpsResponseSchema.parse({
    data:
      data.map((it) => ({
        id: [it.workshiftId, it.trainNumber].filter(Boolean).join(" - "),
        phoneNumber: it.phoneNumber,
        timestamp: it.timestamp,
        gps: it.gps,
        closestNeighbor: null,
        path: it.path,
      })) ?? [],
  });
  addClosestNeighbor(data, baseGps.data);
  return baseGps.data;
};

export const trainGpsDataToBaseGpsData = (data: TrainGpsData[]) => {
  const baseGps = BaseGpsResponseSchema.parse({
    data:
      data.map((it) => ({
        id: it.resourceId,
        phoneNumber: null,
        timestamp: it.timestamp,
        gps: it.gps,
        closestNeighbor: null,
        path: null,
      })) ?? [],
  });
  addClosestNeighbor(data, baseGps.data);
  return baseGps.data;
};
