import { FC } from "react";
import { Stop } from "features/CenterContent/VehicleDetails/TrainDetails/useTrainRoute";
import colors from "shared/theme/colors";

/**
 * Helper functions for sorting trainRoute stops into (probable) passed and notPassed stops,
 * then displaying them as grouped options in the choose stop dropdown.
 *
 * Based on: stop.isArrived
 *
 * Best guess: Split on last index of stop which isArrived
 * */

export const AffectedStopOption: FC<{ stop: Stop }> = ({
  stop: { stopId, name },
}) => <option value={stopId}>{name}</option>;

export function buildOptGroups(passed: Stop[], notPassed: Stop[]) {
  const passedOpts = passed.map((stop) => (
    <AffectedStopOption key={stop.stopId} stop={stop} />
  ));
  const notPassedOpts = notPassed.map((stop) => (
    <AffectedStopOption key={stop.stopId} stop={stop} />
  ));
  return (
    <>
      <optgroup label="Passerte" color={colors.steel}>
        {passedOpts}
      </optgroup>
      <optgroup label="Kommende" color={colors.steel}>
        {notPassedOpts}
      </optgroup>
    </>
  );
}

export function splitPassedStopsAndBuildOptGroups(stops?: Stop[]) {
  // Early out if trainRoute stops is undefined or empty
  if (stops === undefined || stops.length === 0) {
    return buildOptGroups([], []);
  }

  // Because our data is spotty we find index of last stop with isArrived and split there
  const lastPassedIndex = stops.findLastIndex((stop) => stop.isArrived);

  // if lastPassedIndex is -1 we did not find any isArrived, so all should be notPassed
  if (lastPassedIndex === -1) {
    return buildOptGroups([], stops);
  }

  const passed = stops.slice(0, lastPassedIndex + 1);
  const notPassed = stops.slice(lastPassedIndex + 1);

  return buildOptGroups(passed, notPassed);
}
