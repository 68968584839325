import { FC } from "react";
import { TrainStopped } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/TrainStopped";
import { TrainEventType } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import {
  CommonSubTypeProps,
  CommonTrainInfoFormProps,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import { TrainCancelled } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/TrainCancelled";
import { TrainCapacityReduced } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/TrainCapacityReduced";
import { TrainCapacityIncreased } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/TrainCapacityIncreased";
import { TrainMissingProduct } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/TrainMissingProduct";
import { TrainDelayed } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/TrainDelayed";
import { TrainDelayExpected } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/TrainDelayExpected";
import { TrainNotStoppingAtStation } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/TrainNotStoppingAtStation";
import { TrainStoppingExtraAtStation } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/TrainStoppingExtraAtStation";
import { TrainClosedSet } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/TrainClosedSet";
import { TrainLateToTrack } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/TrainLateToTrack";
import { TrainCustom } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/TrainCustom";
import { TrainChangedRoute } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/TrainChangedRoute";
import { InfrastructureEventType } from "shared/types/infrastructureResponse";

type FormProps = {
  type: TrainEventType;
  forOtherDirection?: boolean;
  infrastructureEvent?: InfrastructureEventType;
};

export const SubTypeInputs: FC<
  FormProps & CommonTrainInfoFormProps & CommonSubTypeProps
> = (props) => {
  const { type } = props;
  switch (type) {
    case "TRAIN_STOPPED":
      return <TrainStopped {...props} />;
    case "TRAIN_CANCELLED":
      return <TrainCancelled {...props} />;
    case "TRAIN_CAPACITY_REDUCED":
      return <TrainCapacityReduced {...props} />;
    case "TRAIN_CAPACITY_INCREASED":
      return <TrainCapacityIncreased {...props} />;
    case "TRAIN_DELAYED":
      return <TrainDelayed {...props} />;
    case "TRAIN_DELAY_EXPECTED":
      return <TrainDelayExpected {...props} />;
    case "TRAIN_NOT_STOPPING_AT_STATION":
      return <TrainNotStoppingAtStation {...props} />;
    case "TRAIN_MISSING_PRODUCT":
      return <TrainMissingProduct {...props} />;
    case "TRAIN_STOPPING_EXTRA_AT_STATION":
      return <TrainStoppingExtraAtStation {...props} />;
    case "TRAIN_CLOSED_SET":
      return <TrainClosedSet {...props} />;
    case "TRAIN_LATE_TO_TRACK":
      return <TrainLateToTrack {...props} />;
    case "TRAIN_GENERAL":
      return <TrainCustom {...props} />;
    case "TRAIN_CHANGED_ROUTE":
      return <TrainChangedRoute {...props} />;
    default:
      return null;
  }
};
