import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Flex, FormControl, Grid } from "@vygruppen/spor-react";
import { ExpandableTextarea } from "features/TodoOplogg/common/ExpandableTextarea";
import { ShowIfTrue } from "features/TodoOplogg/common/ShowIfTrue";

import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { SectionTitle } from "shared/components/typography/TitleStyles";
import { OploggForm, commonPadding, oploggForm } from "../formSchema";
import { usePost, usePostStatus } from "./useOploggContext";

export const OpLoggInput = () => {
  const post = usePost();
  const postStatus = usePostStatus();
  const [textareaExpanded, setTextareaExpanded] = useState(false);

  const formMethods = useForm<OploggForm>({
    resolver: zodResolver(oploggForm),
    defaultValues: {
      message: "",
    },
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const onSubmit = handleSubmit((data) => {
    post(data);
  });

  useEffect(() => {
    if (postStatus !== "success") {
      return undefined;
    }

    const timeout = setTimeout(() => {
      reset();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [postStatus]);

  return (
    <Grid
      gridArea="todooplogg"
      flexDirection="column"
      gap={2}
      padding={commonPadding}
    >
      <SectionTitle>Ny melding</SectionTitle>
      <FormProvider {...formMethods}>
        <form onSubmit={onSubmit}>
          <FormControl>
            <ExpandableTextarea
              label="Skriv inn tekst her"
              onExpandChange={setTextareaExpanded}
              formField="message"
            />
          </FormControl>
          <ShowIfTrue show={textareaExpanded}>
            <Flex justifyContent="flex-end" marginTop={2}>
              <Button
                type="submit"
                size="sm"
                variant="primary"
                isLoading={postStatus === "pending"}
              >
                Send
              </Button>
            </Flex>
          </ShowIfTrue>
        </form>
      </FormProvider>
    </Grid>
  );
};
