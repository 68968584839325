import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Button,
  ChoiceChip,
  FormControl,
  FormErrorMessage,
  HStack,
  Skeleton,
  SkeletonText,
  Stack,
  StaticAlert,
  Text,
  VStack,
} from "@vygruppen/spor-react";
import { log, LogLevel } from "api/cloudWatch";
import { ErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { useInfrastructureEvent } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/hooks/useInfrastructureEvent";
import { AffectedTrain } from "features/CenterContent/RoleContent/Vaktleder/types";
import {
  FieldErrorOrUndefined,
  FormSchema,
  formSchema,
  trainFormSchema,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { useOperationalInfoPreviews } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/useOperationalInfoPreviews";
import {
  affectedTrainToTrainOnTrack,
  defaultFormSchema,
  formStateHasCustomField,
  trainFormToRequestBody,
  TrainOnTrack,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/utils";
import { CustomEventInput } from "features/CenterContent/shared/operationalInformation/components/CustomEventInput";
import {
  EventGroup,
  useOperationalInformationTypes,
} from "features/CenterContent/shared/operationalInformation/hooks/useOperationalInformationTypes";
import {
  filterActions,
  sortAndFilterReasons,
} from "features/CenterContent/shared/operationalInformation/utils";
import { FC, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { ActionModal } from "shared/components/ActionModal";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import SkeletonLoader from "shared/components/feedback/SkeletonLoader/SkeletonLoader";
import { InfrastructureEventType } from "shared/types/infrastructureResponse";
import { TrainInfoRequest } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationRequest";
import { OperationalIdentifier_JSON } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";
import { dropsRole } from "stores/dropsRole";
import {
  GroupedTrains,
  groupTrains,
} from "features/CenterContent/RoleContent/AffectedTrains/utils/groupTrainSeries";
import {
  findTrainInOtherDirection,
  mapInfrastructureEventToTrainEvent,
} from "features/CenterContent/RoleContent/AffectedTrains/utils/utils";
import { IncidentHiddenInput } from "features/CenterContent/shared/operationalInformation/components/IncidentHiddenInput";
import { FormControlErrorMessage } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/FormControlErrorMessage";
import { BatchSubTypeInput } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/BatchSubTypeInput";
import { BatchPreview } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/BatchPreviewList";

export type CommonSubTypeProps = {
  trainRepresentative: { trainId: string; nominalDate: string };
  trainRepresentativeOtherDirection?: {
    trainId: string;
    nominalDate: string;
  };
};

type SelectedTrains = {
  selectedTrains: AffectedTrain[];
  updateSelectedTrains: (selectedTrain: AffectedTrain) => void;
};

export type BatchRequestBodyCreate = {
  type: "create";
  body: (TrainInfoRequest & {
    trainIdentifier: OperationalIdentifier_JSON;
  })[];
};

export type RequestBody = BatchRequestBodyCreate;

type TrainInfoFormProps = {
  onSubmit: (body: RequestBody) => void;
  onClose: () => void;
  submitStatus: "error" | "pending" | "idle" | "success";
  title?: string;
  submitButtonLabel?: string;
};

type SeriesData = {
  formState: FormSchema;
  affectedTrains: AffectedTrain[];
  eventData: InfrastructureEventType;
};

export type BatchFormState = {
  [incidentId: string]: { [series: string]: SeriesData };
};

/**
 * Render the train info form modal, either for editing an existing train Event or creating a new train Event
 * @param selectedTrains one or more trains selected in the batch
 * @param updateSelectedTrains edit trains in batch
 * @param onSubmit callback to perform when the form is submitted. The form must be valid before this callback is called.
 * @param onClose callback to perform when closing the modal
 * @param submitStatus the status of the action being performed onSubmit. Typically, the status of
 *                     the create/edit mutation
 * @param title override the title
 * @param submitButtonLabel override the label on the submit button
 */

const TrainInfoBatchModal: FC<TrainInfoFormProps & SelectedTrains> = ({
  selectedTrains,
  updateSelectedTrains,
  onSubmit,
  onClose,
  submitStatus,
  title,
  submitButtonLabel,
}) => {
  const { role } = dropsRole();

  const { trainGroupsByIncident, trainGroupsByIncidentKeys } = useMemo<{
    trainGroupsByIncident: GroupedTrains;
    trainGroupsByIncidentKeys: string[];
  }>(() => {
    const group = groupTrains(selectedTrains);
    const keys = Object.keys(group);
    return { trainGroupsByIncident: group, trainGroupsByIncidentKeys: keys };
  }, [selectedTrains]);

  const [activeTrainGroupIndex, setActiveTrainGroupIndex] = useState(0);
  const activeIncidentKey = trainGroupsByIncidentKeys[activeTrainGroupIndex];
  const activeTrainGroup = trainGroupsByIncident[activeIncidentKey];

  const [activeTrainSeriesIndex, setActiveTrainSeriesIndex] = useState(0);
  const trainSeriesOfActiveGroup = Object.keys(activeTrainGroup.trainSeries);
  const activeSeriesKey = trainSeriesOfActiveGroup[activeTrainSeriesIndex];
  const activeTrainSeries = activeTrainGroup.trainSeries[activeSeriesKey];

  const representativeTrain = activeTrainSeries[0];
  const representativeTrainForOtherDirection = findTrainInOtherDirection(
    representativeTrain.trainId.identifier,
    activeTrainSeries,
  );

  const { data: opInfoTypes, status: opInfoTypesStatus } =
    useOperationalInformationTypes();

  const { data: infraStructureEventData } = useInfrastructureEvent(
    Array.from(new Set(selectedTrains.map((t) => t.eventUuid))),
  );

  const formMethods = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultFormSchema(role),
  });
  const {
    handleSubmit,
    control,
    reset,
    trigger,
    formState: { errors },
  } = formMethods;

  // We need to store the individual formStates of each Series
  const [batchFormState, setBatchFormState] = useState<BatchFormState>();
  const [errorTrains, setErrorTrains] = useState<string[]>([]);

  // Populate with batch state with data from infrastructure event
  useEffect(() => {
    if (!infraStructureEventData) return;
    const initialFormState = Object.entries(trainGroupsByIncident).reduce(
      (incidentGroup: BatchFormState, incident) => {
        const [incidentId, trainGroup] = incident;
        const series: { [key: string]: SeriesData } = Object.keys(
          trainGroup.trainSeries,
        ).reduce((seriesGroup, seriesKey) => {
          // eventUUID from first train in series should be representative for all trains in same series
          const { eventUuid } = trainGroup.trainSeries[seriesKey][0];
          const formState = mapInfrastructureEventToTrainEvent(
            infraStructureEventData[eventUuid],
          );

          return {
            ...seriesGroup,
            [seriesKey]: {
              formState,
              affectedTrains: trainGroup.trainSeries[seriesKey],
              eventData: infraStructureEventData[eventUuid],
            },
          };
        }, {});
        return {
          ...incidentGroup,
          [incidentId]: series,
        };
      },
      {},
    );
    // Set the form of the first train in the form
    const firstForm =
      initialFormState[activeIncidentKey][activeSeriesKey].formState;

    reset(firstForm);

    setBatchFormState(initialFormState);
  }, [infraStructureEventData]);

  // Watch the form state and fetch preview data when it changes
  const formState = useWatch({
    control,
    name: "trainForm",
  });
  const { type } = formState;

  // Update batchFormState when the formState of the particular is updated
  useEffect(() => {
    if (!batchFormState) return;
    const groupKey = trainGroupsByIncidentKeys[activeTrainGroupIndex];
    const seriesKey = trainSeriesOfActiveGroup[activeTrainSeriesIndex];

    // Update the state using updater function: https://react.dev/reference/react/useState#updating-state-based-on-the-previous-state
    setBatchFormState((prevBatchFormState) => {
      if (!prevBatchFormState) {
        return prevBatchFormState;
      }

      return {
        ...prevBatchFormState,
        [groupKey]: {
          ...prevBatchFormState[groupKey],
          [seriesKey]: {
            ...prevBatchFormState[groupKey][seriesKey],
            formState: { trainForm: formState },
          },
        },
      };
    });
  }, [formState]);

  // Set the formState to that particular trainSeries
  useEffect(() => {
    if (!batchFormState) return;
    const groupKey = trainGroupsByIncidentKeys[activeTrainGroupIndex];
    const seriesKey = trainSeriesOfActiveGroup[activeTrainSeriesIndex];
    const formVals = batchFormState[groupKey][seriesKey].formState;

    reset(formVals);
    setErrorTrains([]);
  }, [activeTrainGroupIndex, activeTrainSeriesIndex]);

  const formValidCheck: {
    seriesSuccessful: string[];
    seriesUnsuccessful: string[];
  } = useMemo(() => {
    if (!batchFormState) {
      return { seriesSuccessful: [], seriesUnsuccessful: [] };
    }

    const series = Object.values(batchFormState);
    const checkSerie = (serie: SeriesData, successful: boolean) =>
      trainFormSchema.safeParse(serie.formState.trainForm).success ===
      successful;
    const filterSeries = (successful: boolean) =>
      series.flatMap((serie) =>
        Object.entries(serie)
          .filter(([, value]) => checkSerie(value, successful))
          .map(([key]) => key),
      );

    const seriesUnsuccessful = filterSeries(false);
    const seriesSuccessful = filterSeries(true);

    return { seriesUnsuccessful, seriesSuccessful };
  }, [batchFormState, infraStructureEventData]);

  const formValidTooltip: () => string | undefined = () => {
    const { seriesSuccessful, seriesUnsuccessful } = formValidCheck;
    if (seriesUnsuccessful.length === 0)
      return seriesSuccessful.length === 0 ? "Laster inn..." : undefined;
    return `Mangler utfylling: ${seriesUnsuccessful.join(", ")}`;
  };

  const areFormsValid: () => "valid" | "partial" | "none" = () => {
    const { seriesSuccessful, seriesUnsuccessful } = formValidCheck;
    if (seriesSuccessful.length === 0) return "none";
    if (seriesUnsuccessful.length === 0) return "valid";
    return "partial";
  };

  const trainsDetails: TrainOnTrack[] = selectedTrains.map((train) =>
    affectedTrainToTrainOnTrack(train, representativeTrain),
  );

  const { previewData, previewStatus } = useOperationalInfoPreviews({
    batchFormState: batchFormState ?? {},
    trains: trainsDetails,
  });

  const previewTexts = useMemo(
    () => previewData?.map(({ preview }) => preview.internalMessage),
    [previewData],
  );

  const reasons = useMemo(() => {
    if (opInfoTypes) {
      return sortAndFilterReasons(opInfoTypes.reasons, "TRAIN");
    }
    return [];
  }, [opInfoTypes?.reasons]);

  const showForm = opInfoTypesStatus === "success" && batchFormState;

  function makeRequestBody(): RequestBody {
    const incidentGroups = Object.values(batchFormState!); // non-nullable is checked at onSubmit
    return {
      type: "create",
      body: incidentGroups.flatMap((incidentGroup) => {
        const trainSeries = Object.values(incidentGroup);
        return trainSeries.flatMap((trainSerie) => {
          const { affectedTrains } = trainSerie;
          return affectedTrains.map((affectedTrain) => {
            const details = affectedTrainToTrainOnTrack(
              affectedTrain,
              representativeTrain,
            );
            const trainInfoBody = trainFormToRequestBody(
              trainSerie.formState.trainForm,
              details,
            );
            return {
              ...trainInfoBody!,
              trainIdentifier: {
                country_code: details.countryCode,
                nominal_date: details.nominalDate,
                operational_identifier: details.identifier,
              },
            };
          });
        });
      }),
    };
  }

  // Submitting the form
  const onSubmitForm = handleSubmit(() => {
    if (!batchFormState) {
      log(LogLevel.error, `TrainInfoBatch`, `BatchFormState is missing`);
      throw new Error("Missing batch form state, this should not be possible.");
    }
    const requestBody = makeRequestBody();
    if (requestBody) {
      onSubmit(requestBody);
    }
  });

  return (
    <FormProvider {...formMethods}>
      <ActionModal
        title={title ?? "Opprett toghendelse"}
        actionTitle={submitButtonLabel ?? "Opprett hendelse"}
        onClose={onClose}
        onSubmit={onSubmitForm}
        onSubmitDisabled={() => {
          trigger(); // Generate detailed errors on current train series
          setErrorTrains(formValidCheck.seriesUnsuccessful); // Generate simple errors for all train series
        }}
        isSubmitDisabled={areFormsValid() !== "valid"}
        onSubmitTooltip={formValidTooltip()}
        isLoading={submitStatus === "pending"}
        isSuccess={submitStatus === "success"}
        isError={submitStatus === "error"}
        successMessage="Hendelse opprettet"
        failureMessage="Kunne ikke opprette hendelse. Prøv på nytt, eller kontakt IT hvis feilen vedvarer"
      >
        <ErrorBoundary>
          <Box w="100%" display="grid" gap={5}>
            {opInfoTypesStatus === "pending" && (
              <>
                <Stack gap={2}>
                  <Skeleton height={6} />
                  <Skeleton height={6} />
                </Stack>
                <SkeletonText noOfLines={3} width="30%" />
              </>
            )}
            {opInfoTypesStatus === "error" && <FailureMessage />}
            {selectedTrains.length === 1 && (
              <HStack wrap="wrap">
                {selectedTrains[0].eventStretchName && (
                  <Text fontWeight="bold">
                    Hendelse mellom {selectedTrains[0].eventStretchName}
                  </Text>
                )}
                <Text>
                  Opprett hendelse for {selectedTrains[0].trainId.identifier} (
                  {selectedTrains[0].trainId.nominalDate})
                </Text>
              </HStack>
            )}

            {trainGroupsByIncidentKeys.length > 1 ? (
              <HStack flexWrap="wrap">
                {Object.entries(trainGroupsByIncident).map(
                  ([incidentId, group], i) => (
                    <VStack key={incidentId}>
                      <Button
                        variant={
                          activeTrainGroupIndex === i ? "primary" : "secondary"
                        }
                        type="button"
                        onClick={() => {
                          setActiveTrainSeriesIndex(0);
                          setActiveTrainGroupIndex(i);
                        }}
                      >
                        {group.stretchName}
                      </Button>
                      <FormControl
                        isInvalid={errorTrains.some((it) =>
                          Object.keys(group.trainSeries).includes(it),
                        )}
                      >
                        <FormErrorMessage>Mangler utfylling</FormErrorMessage>
                      </FormControl>
                    </VStack>
                  ),
                )}
              </HStack>
            ) : (
              <Text>{Object.values(trainGroupsByIncident)[0].stretchName}</Text>
            )}

            {Object.values(activeTrainGroup.trainSeries).length > 1 ? (
              <HStack flexWrap="wrap">
                {Object.keys(activeTrainGroup.trainSeries).map((series, i) => (
                  <VStack key={series}>
                    <Button
                      size="sm"
                      variant={
                        activeTrainSeriesIndex === i ? "primary" : "secondary"
                      }
                      type="button"
                      onClick={() => setActiveTrainSeriesIndex(i)}
                    >
                      {series}
                    </Button>
                    <FormControl isInvalid={errorTrains.includes(series)}>
                      <FormErrorMessage>Mangler utfylling</FormErrorMessage>
                    </FormControl>
                  </VStack>
                ))}
              </HStack>
            ) : (
              <Text>{Object.keys(activeTrainGroup.trainSeries)[0]}</Text>
            )}

            <HStack wrap="wrap">
              {activeTrainSeries.length > 1 ? (
                activeTrainSeries.map((train, index) => (
                  <ChoiceChip
                    size="sm"
                    chipType="filter"
                    isChecked
                    key={`${train.trainId.identifier}_${train.trainId.nominalDate}_${train.incidentId ?? index}`}
                    onChange={() => updateSelectedTrains(train)}
                  >
                    {train.trainId.identifier}
                  </ChoiceChip>
                ))
              ) : (
                <Text>{activeTrainSeries[0].trainId.identifier}</Text>
              )}
            </HStack>
            <IncidentHiddenInput
              incidentIds={[trainGroupsByIncidentKeys[activeTrainGroupIndex]]}
            />
            {showForm && (
              <>
                <Stack gap={2}>
                  <CustomEventInput
                    items={opInfoTypes.events.filter(
                      (event) => event.group === EventGroup.TRAIN,
                    )}
                    formFieldType="event"
                    optionDisabledPredicate={
                      (event) => false // Consider adding this. Currently only supported in non-batch actions.
                    }
                  />
                  <FormControlErrorMessage
                    field={errors?.trainForm?.type as FieldErrorOrUndefined}
                  />
                  <CustomEventInput items={reasons} formFieldType="reason" />
                  <FormControlErrorMessage
                    field={
                      errors.trainForm?.reason?.type as FieldErrorOrUndefined
                    }
                  />
                  <CustomEventInput
                    items={filterActions(opInfoTypes.actions)}
                    formFieldType="action"
                  />
                </Stack>
                {type && (
                  <BatchSubTypeInput
                    type={type}
                    infrastructureEvent={
                      batchFormState[activeIncidentKey][activeSeriesKey]
                        .eventData
                    }
                    trainRepresentative={{
                      trainId: representativeTrain.trainId.identifier,
                      nominalDate: representativeTrain.trainId.nominalDate,
                    }}
                    trainRepresentativeOtherDirection={
                      representativeTrainForOtherDirection && {
                        trainId:
                          representativeTrainForOtherDirection.trainId
                            .identifier,
                        nominalDate:
                          representativeTrainForOtherDirection.trainId
                            .nominalDate,
                      }
                    }
                  />
                )}
                <BatchPreview
                  previewStatus={previewStatus}
                  previewTexts={previewTexts}
                  isFormValid={areFormsValid() !== "none"}
                />
                {formStateHasCustomField(formState) && (
                  <StaticAlert variant="info" id="infoBoxActionModal">
                    Hendelsen inneholder egendefinert tekst. Sjekk at
                    oppsummeringen ser riktig ut før du går videre.
                  </StaticAlert>
                )}
              </>
            )}
            {!showForm && <SkeletonLoader skeletonType="affectedTrains" />}
          </Box>
        </ErrorBoundary>
      </ActionModal>
    </FormProvider>
  );
};

export default TrainInfoBatchModal;
