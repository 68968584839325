import { getCognitoClientId } from "app/Auth/authConfig";
import { cognitoHttpClient } from "./cognitoHttpClient";
import { LOGIN_REDIRECT } from "../../shared/utils/routes";

export interface ICognitoTokenRequest {
  authenticationCode: string;
  pkceVerifier: string;
}

interface RefreshCognitoTokenResponse {
  access_token: string;
  id_token: string;
  token_type: string;
  expires_in: number;
}

interface CognitoAccessTokenResponse {
  access_token: string;
  id_token: string;
  refresh_token: string;
  token_type: string;
  expires_in: number;
}

export const getCognitoAccessToken = (cognitoTokens: ICognitoTokenRequest) =>
  cognitoHttpClient.request<CognitoAccessTokenResponse>({
    method: "POST",
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    url: `oauth2/token`,
    data: `code=${cognitoTokens.authenticationCode}&code_verifier=${
      cognitoTokens.pkceVerifier
    }&redirect_uri=${
      window.location.origin + LOGIN_REDIRECT
    }&client_id=${getCognitoClientId()}&grant_type=authorization_code`,
  });

export const refreshCognitoTokens = (refreshToken: string) =>
  cognitoHttpClient.request<RefreshCognitoTokenResponse>({
    method: "POST",
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    url: `oauth2/token`,
    data: `grant_type=refresh_token&client_id=${getCognitoClientId()}&refresh_token=${refreshToken}`,
    timeout: 3000,
  });
