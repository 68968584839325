import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { dropsRole } from "stores/dropsRole";
import { getDashboardStartPath } from "shared/utils/routes";
import Tabs from "../shared/Tabs/Tabs";
import { getTabsForRole } from "./getTabsForRole";

export const RoleContent = () => {
  const { tabIdParam, roleIdParam } = useParams();
  const { role } = dropsRole();
  const navigate = useNavigate();

  useEffect(() => {
    if (role !== roleIdParam) {
      navigate(getDashboardStartPath(role));
    }
  }, [role]);

  const TabsForRole = getTabsForRole(role);

  const TabPage = TabsForRole?.find((it) => it.id === tabIdParam);
  return (
    <>
      <Tabs />
      {TabPage?.content}
    </>
  );
};
