import {
  ErrorFill24Icon,
  InformationFill24Icon,
  SuccessFill24Icon,
  WarningFill24Icon,
} from "@vygruppen/spor-icon-react";
import { useTheme } from "styled-components";

export type InfoMessageSeverity = "info" | "success" | "error" | "warning";

type SeverityIconProps = {
  severity: InfoMessageSeverity;
  fill?: string;
};

export const SeverityIcon = ({ severity, fill }: SeverityIconProps) => {
  const theme = useTheme();

  switch (severity) {
    case "info":
      return (
        <InformationFill24Icon
          color={fill ?? theme.__blueIndicatorColor}
          title="Informasjon"
        />
      );
    case "warning":
      return (
        <WarningFill24Icon
          color={fill ?? theme.__yellowIndicatorColor}
          title="Varsel"
        />
      );
    case "success":
      return (
        <SuccessFill24Icon
          color={fill ?? theme.__greenIndicatorColor}
          title="Suksess"
        />
      );
    case "error":
      return (
        <ErrorFill24Icon
          color={fill ?? theme.__redIndicatorColor}
          title="Feilmelding"
        />
      );
    default:
      return null;
  }
};

export const SeverityColor = (severity: InfoMessageSeverity) => {
  const theme = useTheme();

  switch (severity) {
    case "info":
      return theme.__blueIndicatorColor;
    case "warning":
      return theme.__yellowIndicatorColor;
    case "success":
      return theme.__greenIndicatorColor;
    case "error":
      return theme.__redIndicatorColor;
    default:
      return theme.__textColor;
  }
};
