import styled from "styled-components";

export const InputField = styled.input<{ $hasDropdown?: boolean }>`
  border-radius: ${({ $hasDropdown }) =>
    $hasDropdown ? "6px 6px 0 0" : "6px"};
  width: 180px;
  font-size: 16px;
  font-family: "Vy Sans";
  border-color: ${({ theme }) => theme.__textColor};
  border-width: 1px;
  border-style: solid;
  height: 38px;
  color: ${({ theme }) => theme.__textColor};
  background-color: ${({ theme }) => theme.__inputBackgroundColor};
  text-indent: 10px;
  outline: none;

  &::placeholder {
    color: ${({ theme }) => theme.__textColor};
    opacity: 1;
  }
`;
