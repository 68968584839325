import { Tooltip } from "@chakra-ui/react";
import {
  PhoneOutline18Icon,
  PhoneOutline24Icon,
} from "@vygruppen/spor-icon-react";
import { Button } from "@vygruppen/spor-react";
import React, { FC } from "react";
import { usePuzzelCurrentlyCalling } from "stores/usePuzzleCurrentlyCalling";

type Size = "xs" | "sm";

export const getCallIcon = (size: Size) => {
  switch (size) {
    case "xs":
      return <PhoneOutline18Icon />;
    case "sm":
    default:
      return <PhoneOutline24Icon />;
  }
};

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  phoneNumber: string;
  label?: string;
  size: Size;
}

/**
 * Secondary button with tooltip on hover, for initiating calls via Puzzel.
 * @param id - ID for linking up the button and tooltip components
 * @param phoneNumber - Phone number to initiate call with.
 * @param label - Optional label to display instead of the phone number.
 * @param size - Size of the secondary Button; only possible to choose between x-small and small atm.
 */
export const CallButton: FC<Props> = ({
  phoneNumber,
  label,
  size,
  ...props
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { setCurrentlyCalling } = usePuzzelCurrentlyCalling();

  const toolTipText = ["Ring til", phoneNumber, label]
    .filter(Boolean)
    .join(" ");

  return (
    <Tooltip hasArrow placement="top" label={toolTipText}>
      <Button
        variant="tertiary"
        size={size}
        leftIcon={<div style={{ marginTop: "3px" }}>{getCallIcon(size)}</div>}
        aria-label={`Ring til ${phoneNumber} ${label}`}
        onClick={(e) => {
          e.stopPropagation();
          setCurrentlyCalling(phoneNumber);
        }}
        {...props}
      >
        {label ?? phoneNumber}
      </Button>
    </Tooltip>
  );
};
