import {
  ListFill18Icon,
  NewspaperFill18Icon,
} from "@vygruppen/spor-icon-react";
import { Flex } from "@vygruppen/spor-react";
import {
  getLocalStorageOploggTodoTabsSettings,
  setLocalStorageOploggTodoTabsSettings,
} from "api/dropsLocalStorage";
import CollapseButton from "features/CenterContent/shared/Tabs/CollapseButton";
import {
  Tab,
  TabsWindows,
} from "features/CenterContent/shared/Tabs/TabsWindows";
import { useEffect, useState } from "react";
import { useSidePanelsState } from "stores/useSidePanelsState";
import styled, { useTheme } from "styled-components";
import Oplogg from "./Oplogg";
import {
  OploggProvider,
  useNotifications as useOploggNotifications,
  useSetNotifications as useSetOploggNotifications,
} from "./Oplogg/useOploggContext";
import Todo from "./Todo";
import {
  TodoProvider,
  useSetNotifications as useSetTodoNotifications,
  useNotifications as useTodoNotifications,
} from "./Todo/useTodoContext";

type Props = {
  $isOpen?: boolean;
};

export const CloseButtonWrapper = styled.div`
  grid-area: close;
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 3rem;
  border-radius: 6px;
  padding: 3px 5px;
  z-index: 1;
`;

// thusanarul TODO: This needs to be rewritten to reflect the new design
export const CollapsibleWrapper = styled.div<Props>`
  display: ${(props: Props) => (props.$isOpen === true ? "grid" : "none")};
  grid-area: collapsablemerged;
  grid-template-areas:
    "close"
    "todooplogg"
    "messages";
  grid-template-columns: auto;
  grid-template-rows: auto auto 1fr;
  grid-column-gap: 6px;
  padding-right: 6px;
  box-sizing: border-box;
  height: 100%;
  min-height: 0; // https://css-tricks.com/preventing-a-grid-blowout/
  background-color: ${({ theme }) => theme.colorBackgroundMain};
`;

const Content = () => {
  const tabs: Tab[] = [
    {
      id: "oplogg",
      icon: <NewspaperFill18Icon />,
      text: "Oplogg",
    },
    {
      id: "todo",
      icon: <ListFill18Icon />,
      text: "Todo",
    },
  ];

  const theme = useTheme();

  const [isOpen, setIsOpen] = useSidePanelsState((state) => [
    state.oploggTodoListOpen,
    state.setOploggTodoListOpen,
  ]);

  const [selectedTabIds, setSelectedTabIds] = useState(
    getLocalStorageOploggTodoTabsSettings(),
  );

  useEffect(() => {
    setLocalStorageOploggTodoTabsSettings(selectedTabIds);
  }, [selectedTabIds]);

  const oploggNotifications = useOploggNotifications();
  const todoNotifications = useTodoNotifications();
  const setOploggNotifications = useSetOploggNotifications();
  const setTodoNotifications = useSetTodoNotifications();

  return (
    <CollapsibleWrapper $isOpen={isOpen}>
      <Flex
        flexDirection="column"
        gap={2}
        backgroundColor={theme.colorBackgroundSecondary}
        borderLeft={`1px solid ${theme.colorOutline}`}
        overflowY="auto"
        position="relative"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <CloseButtonWrapper style={{ position: "absolute" }}>
            <CollapseButton
              isOpen={!isOpen}
              setOpen={() => setIsOpen(!isOpen)}
              inverted
              title="Lukk sidepanel"
            />
          </CloseButtonWrapper>
          <TabsWindows
            tabs={tabs}
            selectedTabIds={selectedTabIds}
            setSelectedTabIds={setSelectedTabIds}
            justifyContent="end"
            allowSplit
            notifications={[oploggNotifications, todoNotifications]}
            setNotifications={(value: number[]) => {
              setOploggNotifications(value[0]);
              setTodoNotifications(value[1]);
            }}
          />
        </Flex>
        <Flex overflowY="auto">
          {selectedTabIds.includes("oplogg") && <Oplogg />}
          {selectedTabIds.includes("todo") && <Todo />}
        </Flex>
      </Flex>
    </CollapsibleWrapper>
  );
};

export const TodoOplogg = () => (
  <OploggProvider>
    <TodoProvider>
      <Content />
    </TodoProvider>
  </OploggProvider>
);
