import { Table, Tbody, Th, Td } from "@vygruppen/spor-react";
import { Thead, Tr } from "@chakra-ui/react";
import { NoDataMessage } from "shared/components/feedback/NoDataMessage/NoDataMessage";
import styled from "styled-components";
import { ObtrusiveScrollCss } from "shared/components/transitions/scroll";
import { Text } from "shared/components/typography/TitleStyles";
import { sortFunction } from "features/CenterContent/shared/TilsynAlerts/utils";
import { TableSkeleton } from "shared/components/feedback/SkeletonLoader/TableSkeleton";
import React from "react";
import { VehicleAlerts } from "features/CenterContent/shared/TilsynAlerts/schema";
import TilsynAlertRow from "./TilsynAlertRow";

const minimizedColumns = ["Individ", "Aktivert", "Type", "Tiltak"];
const tableColumns = ["Togsett", ...minimizedColumns, "Tilstand", " "]; // empty column title for the action column

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  div {
    ${ObtrusiveScrollCss};
  }
`;

interface TilsynAlertProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  alerts: VehicleAlerts | undefined;
  isLoading: boolean;
  isSuccess: boolean;
  fullSize?: boolean;
}

const TilsynAlerts = ({
  alerts,
  isLoading,
  isSuccess,
  fullSize = false,
  ...props
}: TilsynAlertProps) => {
  const columns = fullSize ? tableColumns : minimizedColumns;

  const dataToShow = alerts && alerts.length > 0;

  return (
    <Container {...props}>
      <Text as="h2" bold style={{ marginBottom: "6px" }}>
        Alarmer i Tilsyn
      </Text>
      <Table
        variant="outline"
        colorScheme="grey"
        size="sm"
        display="flex"
        flexDirection="column"
      >
        <Thead position="sticky" top="0" zIndex="1">
          <Tr>
            {columns.map((column) => (
              <Th cursor="pointer" key={column}>
                {column}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody overflow="scroll">
          {isLoading && (
            <TableSkeleton numOfRows={3} numOfColumns={columns.length} />
          )}

          {isSuccess && dataToShow
            ? alerts
                .toSorted(sortFunction)
                .map((alert) => (
                  <TilsynAlertRow
                    key={alert.alertId}
                    alert={alert}
                    fullSize={fullSize}
                  />
                ))
            : isSuccess &&
              !dataToShow && (
                <Tr>
                  <Td colSpan={tableColumns.length} p={5}>
                    <NoDataMessage reason="Det er ingen aktive alarmer i Tilsyn for øyeblikket" />
                  </Td>
                </Tr>
              )}
        </Tbody>
      </Table>
    </Container>
  );
};

export default TilsynAlerts;
