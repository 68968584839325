import React from "react";
import { Text } from "shared/components/typography/TitleStyles";

type SearchResultTextProps = {
  searchQuery: string;
  resultLength: number;
};

export const SearchResultText: React.FC<SearchResultTextProps> = ({
  searchQuery,
  resultLength,
}) =>
  searchQuery.length > 0 ? (
    <Text
      style={{
        alignSelf: "start",
        paddingLeft: "12px",
        paddingBottom: "16px",
      }}
    >
      Ditt søk på <b>{searchQuery}</b> ga <b>{resultLength}</b> treff
    </Text>
  ) : null;
