import { Button, Divider, Flex, tokens } from "@vygruppen/spor-react";
import { withErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { OploggElement } from "features/TodoOplogg/Oplogg/OploggElement/OploggElement";
import { useState } from "react";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { NoDataMessage } from "shared/components/feedback/NoDataMessage/NoDataMessage";
import SkeletonLoader from "shared/components/feedback/SkeletonLoader/SkeletonLoader";
import { ObtrusiveScrollCss } from "shared/components/transitions/scroll";
import { SectionTitle } from "shared/components/typography/TitleStyles";
import styled from "styled-components";
import { commonPadding } from "../formSchema";
import {
  useData,
  useFetchNextPage,
  useHasNextPage,
  useIsFetchingNextPage,
  useStatus,
} from "./useOploggContext";

export const OploggMessageWrapper = styled.div`
  display: grid;
  grid-area: messages;
  flex-direction: column;
  overflow-y: auto;
  width: 420px;
  height: 100%;
`;
export const OploggHeader = styled.div`
  display: flex;
  flex-direction: column;
`;
export const OploggList = styled.ul`
  ${ObtrusiveScrollCss};
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 100%;
  margin: 0;
  list-style: none;
  padding: 6px 6px 0 6px;
  gap: 3px;
  height: 100%;
`;

export const BorderHr = styled.hr`
  color: ${({ theme }) => theme.colorSeparationLine};
  margin-left: -${tokens.default.size.spacing[commonPadding]};
  margin-right: -${tokens.default.size.spacing[commonPadding]};
`;

const Oplogg = () => {
  const data = useData();
  const status = useStatus();
  const hasNextPage = useHasNextPage();
  const isFetchingNextPage = useIsFetchingNextPage();
  const fetchNextPage = useFetchNextPage();

  const [highlightedLogId, setHighlightedLogId] = useState<number>(-1);
  const onHighlightItem = (id: number) => {
    setHighlightedLogId(id === highlightedLogId ? -1 : id);
  };

  const getContent = () => {
    switch (status) {
      case "pending":
        return (
          <>
            <SkeletonLoader skeletonType="oplogg" />
            <Divider />
          </>
        );
      case "success": {
        const allEntries = data?.pages.flatMap((group) => group.entries);
        if (allEntries && allEntries.length > 0) {
          return (
            <OploggList>
              {allEntries
                .sort(
                  (a, b) =>
                    new Date(b.reportedTime).valueOf() -
                    new Date(a.reportedTime).valueOf(),
                )
                .map((it, index, array) => {
                  const isLastItem = index + 1 === array.length;
                  return (
                    <OploggElement
                      key={it.id}
                      logEntry={it}
                      isLastEntry={isLastItem}
                      isHighlighted={it.id === highlightedLogId}
                      onClick={() => onHighlightItem(it.id)}
                    />
                  );
                })}
              {hasNextPage && (
                <Button
                  variant="secondary"
                  size="sm"
                  isLoading={isFetchingNextPage}
                  style={{
                    margin: "18px auto",
                  }}
                  onClick={() => fetchNextPage()}
                >
                  Hent flere
                </Button>
              )}
            </OploggList>
          );
        }
        return <NoDataMessage reason="Fant ingen oplogg-meldinger" />;
      }
      case "error":
      default:
        return <FailureMessage style={{ margin: "12px" }} />;
    }
  };

  return (
    <OploggMessageWrapper>
      <OploggHeader>
        <Divider />
        <Flex justifyContent="space-between" padding={commonPadding}>
          <SectionTitle>Operativ logg</SectionTitle>
        </Flex>
        <Divider />
      </OploggHeader>
      {getContent()}
    </OploggMessageWrapper>
  );
};

export default withErrorBoundary(Oplogg);
