import { FC } from "react";
import {
  CommonSubTypeProps,
  CommonTrainInfoFormProps,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import { AffectedStopsFields } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/AffectedStopsFields";
import { useFormContext } from "react-hook-form";
import {
  DelayedInMinutesFormValue,
  FormSchema,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { Input } from "@vygruppen/spor-react";
import { clamp } from "../../../../../../shared/utils";

export const TrainDelayExpected: FC<
  CommonTrainInfoFormProps & CommonSubTypeProps
> = ({ forOtherDirection, ...props }) => {
  const { register, setValue } = useFormContext<FormSchema>();
  const MIN_VALUE = 1;
  const MAX_VALUE = 60 * 24;

  const delayFormValue: DelayedInMinutesFormValue = forOtherDirection
    ? "trainForm.delayInMinutesOtherDirection"
    : "trainForm.delayInMinutes";

  return (
    <>
      <AffectedStopsFields forOtherDirection={forOtherDirection} {...props} />
      <Input
        {...register(delayFormValue)}
        width="15.5rem"
        type="number"
        max={MAX_VALUE}
        onChange={(e) =>
          setValue(
            delayFormValue,
            clamp(MAX_VALUE, MIN_VALUE, parseInt(e.target.value, 10)),
          )
        }
        label="Forventet forsinkelse (min)"
        step={1}
      />
    </>
  );
};
