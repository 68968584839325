import { useActiveTrainTabs } from "features/CenterContent/shared/Tabs/useTabsContext";
import { formatDateString } from "shared/utils/datetime";
import styled from "styled-components";
import { TrainListElement } from "./TrainListElement";
import { TrainData } from "./types";

type Props = {
  trainDatas: TrainData[];
};

const DateHeader = styled.div`
  padding: 5px 35px;
  background-color: ${({ theme }) => theme.colorBackgroundMain};
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colorSeparationLine};
`;

// Section: trainDatas of the same date
export const TrainListSection = ({ trainDatas }: Props) => {
  const activeTrainTabs = useActiveTrainTabs();

  const trainElementSelected = (train: TrainData) =>
    activeTrainTabs.filter(
      (it: any) =>
        it.trainNumber === train.trainNumber &&
        it.trainDate === train.trainDate,
    ).length !== 0;

  const date = trainDatas[0].trainDate;
  return (
    <>
      <DateHeader>{formatDateString(date, "dd.MM.yy")}</DateHeader>
      {trainDatas.map((train: TrainData) => (
        <TrainListElement
          key={`trainsList_item_${train.trainNumber}_${train.trainDate}`}
          train={train}
          trainElementSelected={trainElementSelected(train)}
        />
      ))}
    </>
  );
};
