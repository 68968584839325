import { zodResolver } from "@hookform/resolvers/zod";
import {
  formSchema,
  FormSchema,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/formSchema";
import { CreateInfrastructureEventModal } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/CreateInfrastructureEventModal";
import {
  getCurrentCalendarDateTime,
  getInitialOpenEndedDuration,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/initialDurationValues";
import { StretchBuilderModeInfo } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/StretchBuilderModeInfo";
import { getStretchName } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stretchBuilderTexts";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useStretchBuilder } from "stores/useStretchBuilder";

export const CreateInfrastructureEvent: FC = () => {
  const [
    clickedStops,
    selectedLegs,
    allStopsSelected,
    stretchBuilderModalState,
  ] = useStretchBuilder((state) => [
    state.clickedStops,
    state.selectedLegs,
    state.allStopsSelected,
    state.stretchBuilderModalState,
  ]);

  const formMethods = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      infrastructureForm: {
        event: "",
        reason: { type: "" },
        actionCard: "",
        consequence: { type: "" },
        prognosisType: undefined,
        prognosisTime: getCurrentCalendarDateTime(),
        stretchName: "",
        affectedLegs: [],
        durations: getInitialOpenEndedDuration(),
        isirkId: "",
      },
    },
  });

  if (stretchBuilderModalState.type !== "create") return null;

  return (
    <FormProvider {...formMethods}>
      {!stretchBuilderModalState.stretchBuilderMode ? (
        <CreateInfrastructureEventModal
          stretchTitle={getStretchName(
            selectedLegs,
            clickedStops,
            allStopsSelected,
          ).join(", ")}
        />
      ) : (
        <StretchBuilderModeInfo />
      )}
    </FormProvider>
  );
};
