import colors from "shared/theme/colors";

export const lightTheme = {
  themeName: "light",

  colorBackgroundMain: colors.lightGrey, // #F5F5F5
  colorBackgroundSecondary: colors.whiteSnow, // #FDFDFD
  colorBackgroundTetriary: colors.platinum, // #EBEBEC
  colorHoverItem: "rgba(136, 139, 142, 0.2)", // #888B8E 20%
  colorTextMain: colors.darkGrey, // #2B2B2C
  colorTextSecondary: colors.dimGrey, // #606568
  colorTextDisabled: colors.steel, // #AFB2B3
  colorSeparationLine: colors.silver, // #D7D8D9
  colorOutline: colors.blackAlpha["400"], // rgba(0, 0, 0, 0.4)
  colorAlarm: colors.raspberry, // #CC003D
  colorAlarmFill: colors.blossom, // #FFCCDB
  colorAlarmSecondary: colors.pumpkin, // #DF8200
  colorWarning: colors.burntYellow, // #F1C400
  colorWarningFill: colors.blonde, // #FDF3BA
  colorSuccess: colors.greenHaze, // #00957A
  colorSucessFill: colors.seaMist, // #CCEAE4
  colorInfo: colors.glacier, // #688CBA
  colorInfoFill: colors.sky, // #A7BCD6
  colorIconHighlight: colors.iconHighlight, // #00685E

  //-----
  // LEGACY
  //-----

  // General
  __contentBackgroundColor: colors.lightGrey,
  __contentHoverColor: colors.platinum,
  __contentSelectedColor: colors.platinum,
  __appBackgroundColor: colors.steel,
  __todoOploggBackgroundColor: colors.silver,
  __textColor: colors.darkGrey,
  __textColor2: colors.dimGrey,
  __inputBackgroundColor: colors.white,
  __borderColor: colors.silver,
  __focusBorderColor: colors.greenHaze,

  __redIndicatorColor: colors.raspberry,
  __orangeIndicatorColor: colors.pumpkin,
  __yellowIndicatorColor: colors.burntYellow,
  __blueIndicatorColor: colors.glacier,
  __greenIndicatorColor: colors.greenHaze,

  // Top Header
  __headerBackground: colors.whiteSnow,
  __menuTextHover: colors.pine,

  // Center Content Tabs
  __tabInactiveBackground: colors.silver,

  // Buttons
  __loadingBtnBackgroundColor: colors.silver,
  __loadingBtnColor: colors.white,

  __primaryBtnBackgroundColor: colors.seaMist,
  __primaryBtnHoverBackgroundColor: colors.coralGreen,
  __primaryBtnGhostHoverBackgroundColor: colors.coralGreen,
  __primaryBtnActiveBackgroundColor: colors.mint,
  __primaryBtnColor: colors.darkTeal,
  __primaryBtnDisabledBackgroundColor: colors.platinum,
  __primaryBtnDisabledColor: colors.dimGrey,
  __primaryBtnFocusBackgroundColor: colors.coralGreen,

  __underlineBtnDisabledColor: colors.steel,
  __underlineBtnDisabledtextColor: colors.osloGrey,
  __underlineButtonActiveColor: colors.dimGrey,
  __underlineButtonHoverColor: colors.darkGrey,
  __underlineButtonClickedColor: colors.osloGrey,

  // Links
  __primaryLinkHoverBackgroundColor: colors.platinum,
  __primaryLinkActiveBackgroundColor: colors.mint,
  __primaryLinkActiveColor: colors.pine,
  __primaryLinkFocusBackgroundColor: colors.pine,
  __primaryLinkFocusColor: colors.white,

  // Checkbox
  __checkboxBackgroundColor: colors.white,
  __checkboxActiveColor: colors.primaryGreen,
  __checkboxOutlineColor: colors.osloGrey,
  __checkboxCheckmarkColor: colors.white,
  __checkboxDisabledColor: colors.silver,

  // SkeletonLoader
  __skeletonElementColor: colors.silver,
  __skeletonGradientColor: colors.lightGrey,

  // RadioButton
  __radioBorderColor: colors.primaryGreen,
  __radioBackgroundColor: colors.white,
  __radioFocusBorderColor: colors.azure,
  __radioFocusBackgroundColor: colors.seaMist,

  // InfoMessages
  __expandableInfoMessageBtnHover: colors.white,
  __expandableInfoMessageBtnActive: colors.silver,

  // SelectedList (dropdown menu)
  __dropDownListRowBg: colors.white,
  __dropDownListRowBgHover: colors.pine,
  __dropDownListtextColor: colors.darkGrey,
  __dropDownListtextColorHover: colors.white,
  __dropDownListtextColorDisabled: colors.steel,
  __dropdownHover: colors.pine,
  __dropDownListActiveColor: colors.greenHaze,

  // TrainList
  __vehicleListBorderBottomSelected: colors.silver,

  // Oplogg
  __opLoggDotBorder: colors.osloGrey,
  __newOploggItem: colors.lightRed,
  __oploggDarkText: colors.darkGrey,

  // TrainStop
  __trainStopNotPassedLineColor: colors.steel,
  __trainStopNotPassedColor: colors.steel,

  // Tjenestevarsling Table
  __tableRowAlertHighlight: colors.lightRed,
  __tableRowWarnHighlight: colors.blonde,

  // Puzzle
  __onHoldBackgroundColor: colors.salmon,
  __onHoldColor: colors.darkGrey,

  // Dayplan
  __dayPlanItemBackgroundColor: colors.white,

  // TrainInfoMessage
  __trainInformationReplacedColor: colors.steel,

  // HistoryTable
  __historyTableHeader: colors.platinum,
  __historyTableContent: colors.white,
  __historyBackground: colors.white,
  __historyTableHeadingRow: colors.platinum,
  __historyTableRows: colors.lightGrey,

  // OverviewTable
  __overviewBorderColor: colors.darkGrey,
  __overviewBorderColorInner: colors.dimGrey,

  // TrainResumed
  __alertTextColor: colors.black,
  __alertBackgroundColor: colors.platinum,

  // Bookmark Items
  __bookmarkBackgroundColor: colors.platinum,
};

export type LightTheme = typeof lightTheme;
