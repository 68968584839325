import { Stack, Textarea } from "@vygruppen/spor-react";
import { FormSchema as InfraSchema } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/formSchema";
import { GenericType } from "features/CenterContent/shared/operationalInformation/hooks/useOperationalInformationTypes";
import { isCustomInputType } from "features/CenterContent/shared/operationalInformation/utils";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { Select } from "shared/components/forms/Select";
import { useTheme } from "styled-components";

type FieldType = "reason" | "action" | "consequence" | "prognosisType";

type CustomInputProps = {
  items: GenericType[];
  formFieldType: FieldType;
  isDisabled?: boolean;
};

const getLabel = (fieldType: FieldType) => {
  switch (fieldType) {
    case "reason":
      return "Årsak*";
    case "action":
      return "Handling";
    case "consequence":
      return "Konsekvens";
    case "prognosisType":
      return "Prognose";
    default:
      return "";
  }
};

export const InfrastructureCustomInput: FC<CustomInputProps> = ({
  items,
  formFieldType,
  isDisabled = false,
}) => {
  const theme = useTheme();

  const { register, getValues } = useFormContext<InfraSchema>();
  const type = getValues(`infrastructureForm.${formFieldType}.type`);
  const isCustomInput = isCustomInputType(type);

  const label = getLabel(formFieldType);

  if (!isCustomInput) {
    return (
      <Select
        label={label}
        placeholder={`Velg en ${label.toLowerCase().replace("*", "")}`}
        {...register(`infrastructureForm.${formFieldType}.type`)}
        disabled={isDisabled}
      >
        {items.map((item) => (
          <option key={item.type} value={item.type}>
            {item.label}
          </option>
        ))}
      </Select>
    );
  }

  return (
    <Stack
      gap={2}
      padding={4}
      boxShadow={`inset 0 0 0 1px ${theme.__borderColor}`}
      borderRadius="sm"
    >
      <Select
        label={label}
        placeholder={`Velg en ${label.toLowerCase()}`}
        {...register(`infrastructureForm.${formFieldType}.type`)}
      >
        {items.map((item) => (
          <option key={item.type} value={item.type}>
            {item.label}
          </option>
        ))}
      </Select>

      <Stack gap={2}>
        <Textarea
          label="Norsk tekst"
          {...register(`infrastructureForm.${formFieldType}.description.NOB`, {
            value: "",
          })}
        />
        <Textarea
          label="Engelsk tekst"
          {...register(`infrastructureForm.${formFieldType}.description.ENG`, {
            value: "",
          })}
        />
      </Stack>
    </Stack>
  );
};
