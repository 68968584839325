import { useState } from "react";
import { UserRole } from "shared/types/roles";

export type TodoFilterStatus = "incomplete" | "completed" | "futureAlarms";

export type TodoFilterState = {
  roles: UserRole[];
  vehicleSetTypes: string[];
  trainNumber: string;
  todoStatus: TodoFilterStatus[];
};

export const defaultTodoFilterState: TodoFilterState = {
  roles: [],
  vehicleSetTypes: [],
  trainNumber: "",
  todoStatus: ["incomplete"],
};

export const FILTER_STATUS_LIST = {
  incomplete: "Vis åpne",
  completed: "Vis utførte",
};

export const VEHICLE_SET_TYPES = [
  { text: "69", value: "69" },
  { text: "70", value: "70" },
  { text: "72", value: "72" },
  { text: "73", value: "73" },
  { text: "74", value: "74" },
  { text: "75", value: "75" },
  { text: "Lok og vogn", value: "lokvogn" },
];

const useTodoFilterState = (initialTodoFilterState?: TodoFilterState) => {
  const [todoFilterState, setTodoFilterState] = useState<TodoFilterState>(
    initialTodoFilterState ?? defaultTodoFilterState,
  );

  const toggleRoles = (role: UserRole) => {
    if (todoFilterState.roles.includes(role)) {
      setTodoFilterState({
        ...todoFilterState,
        roles: todoFilterState.roles.filter((r) => r !== role),
      });
    } else {
      setTodoFilterState({
        ...todoFilterState,
        roles: [...todoFilterState.roles, role],
      });
    }
  };

  const toggleVehicleSetTypes = (vehicleSetType: string) => {
    if (todoFilterState.vehicleSetTypes.includes(vehicleSetType)) {
      setTodoFilterState({
        ...todoFilterState,
        vehicleSetTypes: todoFilterState.vehicleSetTypes.filter(
          (v) => v !== vehicleSetType,
        ),
      });
    } else {
      setTodoFilterState({
        ...todoFilterState,
        vehicleSetTypes: [...todoFilterState.vehicleSetTypes, vehicleSetType],
      });
    }
  };

  const setTrainNumber = (trainNumber: string) => {
    setTodoFilterState({ ...todoFilterState, trainNumber });
  };

  const toggleTodoStatus = (status: TodoFilterStatus) => {
    if (todoFilterState.todoStatus.includes(status)) {
      setTodoFilterState({
        ...todoFilterState,
        todoStatus: todoFilterState.todoStatus.filter((s) => s !== status),
      });
    } else {
      setTodoFilterState({
        ...todoFilterState,
        todoStatus: [...todoFilterState.todoStatus, status],
      });
    }
  };

  const clearTodoFilterState = () => {
    setTodoFilterState(defaultTodoFilterState);
  };

  return {
    todoFilterState,
    setTodoFilterState,
    clearTodoFilterState,
    toggleRoles,
    toggleVehicleSetTypes,
    setTrainNumber,
    toggleTodoStatus,
  };
};

export default useTodoFilterState;
