import { CopyOutline30Icon } from "@vygruppen/spor-icon-react";
import {
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Grid,
  Text,
  VStack,
} from "@vygruppen/spor-react";
import { format } from "date-fns";
import { groupBy } from "features/VehicleList/TrainList/TrainList";
import { FC, Fragment } from "react";
import { Link } from "react-router-dom";
import { NoStyleButton } from "shared/components/buttons/NoStyleButton";
import { AffectedStop, Distribution } from "shared/types/historyDetail";
import styled, { css, useTheme } from "styled-components";
import { Group, OriginSystem, Visibility } from "../fetchHistoryDetail";
import { TitleSubtitleChildren } from "./TitleSubtitleChildren";
import { copyToClipboard } from "./copyToClipboard";

const InnerTable = styled(Box)<{ outline?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  border-radius: 6px;
  padding: 1.5rem 0;
  ${({ outline, theme }) =>
    outline &&
    css`
      border: 0.5px solid ${theme.__overviewBorderColorInner};
    `}
`;

export const TextOutline = styled(Text)`
  outline: 1px solid ${({ theme }) => theme.__overviewBorderColorInner};
  padding: 5px 0.75rem;
  border-radius: 99px;
  width: fit-content;
`;

export type TableDetailAccordionPanelProps = {
  group: Group;
  reason: string;
  actionCard: string;
  visibility: Visibility;
  originSystem: OriginSystem;
  distributions: Distribution[];
  affectedStops: AffectedStop[];
};

const DistributionContainer = styled(Grid)`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px; /* This adds some space between the two texts */
  align-items: center;
`;

export const TableDetailAccordionPanel: FC<TableDetailAccordionPanelProps> = ({
  group,
  reason,
  actionCard,
  visibility,
  originSystem,
  distributions,
  affectedStops,
}) => {
  const theme = useTheme();
  return (
    <AccordionPanel>
      <VStack flex="1" w={{ base: "100%" }}>
        <Divider
          borderWidth="0.5px"
          width="999%" // overflow is hidden therefore its okay
          borderColor={theme.colorOutline}
          marginTop={-2}
        />

        <InnerTable>
          {group !== "STATION" && (
            <TitleSubtitleChildren row title="Årsak:" subtitle={reason} />
          )}
          {group === "INFRASTRUCTURE" && (
            <TitleSubtitleChildren
              row
              title="Aksjonskort:"
              subtitle={actionCard}
            />
          )}
          <TitleSubtitleChildren row title="Opprettet av:">
            <Flex flexDirection="column">
              <Text>{originSystem.name}</Text>

              <Flex>
                <Link to={`mailto:${originSystem.user}` ?? "-"}>
                  <Text as="u" fontSize="2xs" fontWeight="normal">
                    {originSystem.user ?? "-"}
                  </Text>
                </Link>
                <NoStyleButton
                  onClick={() => {
                    copyToClipboard(originSystem.user ?? "-");
                  }}
                >
                  <CopyOutline30Icon height="20px" />
                </NoStyleButton>
              </Flex>
            </Flex>
          </TitleSubtitleChildren>
          <TitleSubtitleChildren row title="Synlighet:">
            <Flex flexDirection="column">
              {visibility.fromTime && (
                <Text>
                  fra {format(visibility.fromTime, "dd.MM.yyyy - HH:mm")}
                </Text>
              )}
              {visibility.toTime && (
                <Text>
                  til {format(visibility.toTime, "dd.MM.yyyy - HH:mm")}
                </Text>
              )}
            </Flex>
          </TitleSubtitleChildren>

          <TitleSubtitleChildren row title="Påvirkede stasjoner:">
            <Flex flexDirection="column">
              <Text>
                {affectedStops?.map((stop) => stop.name).join(" - ") ?? " - "}
              </Text>
            </Flex>
          </TitleSubtitleChildren>

          <TitleSubtitleChildren row title="Distribusjon:">
            <DistributionContainer gap="8px">
              {Object.values(
                groupBy(
                  distributions,
                  (distribution: Distribution) => distribution.text.message,
                ),
              ).map((distributionsGrouped, distributionsIndex) => {
                const contextNames = distributionsGrouped.map(
                  (it) => it.contextName,
                );
                const { text } = distributionsGrouped[0]; // groupBy text, so any text will do
                return (
                  <Fragment key={distributionsIndex}>
                    {distributionsIndex > 0 && (
                      <Divider
                        gridColumnStart="1"
                        gridColumnEnd="3"
                        marginY="1rem"
                      />
                    )}
                    <Box display="flex" flexDirection="column" gap="4px">
                      {contextNames.map((contextName) => (
                        <TextOutline key={contextName}>
                          {contextName}
                        </TextOutline>
                      ))}
                    </Box>
                    <Text paddingX="1rem">{text.message}</Text>
                  </Fragment>
                );
              })}
            </DistributionContainer>
          </TitleSubtitleChildren>
        </InnerTable>
      </VStack>
    </AccordionPanel>
  );
};
