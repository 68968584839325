import { zodResolver } from "@hookform/resolvers/zod";
import { toCalendarDateTime } from "@internationalized/date";
import { useMutation } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import {
  formSchema,
  FormSchema,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/formSchema";
import { getInfrastructureEventBody } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/createRequestUtils";
import {
  getCurrentCalendarDateTime,
  getInitialDuration,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/initialDurationValues";
import { InfrastructureModalContent } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/InfrastructureModalContent";
import { InfrastructureProviderEnum } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/InfrastructureProvider";
import {
  InfrastructureEventCreatedResponse,
  OpenInfrastructureEventBody,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";
import { StretchBuilderModeInfo } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/StretchBuilderModeInfo";
import { getStretchName } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stretchBuilderTexts";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ActionModal } from "shared/components/ActionModal";
import { InfraStructureInformation } from "shared/types/infrastructureResponse";
import { isPlannedInfraStructureEvent } from "shared/utils/infrastructureEvent";
import { useOpenEditFields } from "stores/useOpenEditFields";
import { useStretchBuilder } from "stores/useStretchBuilder";

type EditEventProps = {
  event: InfraStructureInformation;
};

export const EditInfrastructureEvent: FC<EditEventProps> = ({ event }) => {
  const [
    clickedStops,
    selectedLegs,
    allStopsSelected,
    closeStretchBuilder,
    stretchBuilderModalState,
  ] = useStretchBuilder((state) => [
    state.clickedStops,
    state.selectedLegs,
    state.allStopsSelected,
    state.closeStretchBuilder,
    state.stretchBuilderModalState,
  ]);

  const getReason = () => {
    if (isPlannedInfraStructureEvent(event.type))
      return { type: "WORK_ON_TRACK" };
    return event.reason
      ? {
          type: event.reason.type,
          description: {
            NOB: event.reason.description.nob || "",
            ENG: event.reason.description.eng || "",
          },
        }
      : { type: "" };
  };

  const getPrognosisTime = () => {
    if (event.prognosis) {
      if (
        event.prognosis.type === "ESTIMATED_OPENING" &&
        event.prognosis.estimatedResolved
      )
        return toCalendarDateTime(event.prognosis.estimatedResolved);
      if (
        event.prognosis.type === "ESTIMATED_INFO_UPDATE" &&
        event.prognosis.estimatedInfoUpdate
      )
        return toCalendarDateTime(event.prognosis.estimatedInfoUpdate);
    }
    return getCurrentCalendarDateTime();
  };

  const formMethods = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      infrastructureForm: {
        event: event.type,
        reason: getReason(),
        action: event.action
          ? {
              type: event.action.type,
              description: {
                NOB: event.action.description.nob || "",
                ENG: event.action.description.eng || "",
              },
            }
          : { type: "" },
        actionCard: event.actionCard?.type || "",
        consequence: event.consequence
          ? {
              type: event.consequence.type,
              description: {
                NOB: event.consequence.description.nob || "",
                ENG: event.consequence.description.eng || "",
              },
            }
          : { type: "" },
        prognosisType: event.prognosis
          ? {
              type: event.prognosis.type,
              description: {
                NOB: event.prognosis.description.nob || "",
                ENG: event.prognosis.description.eng || "",
              },
            }
          : { type: "" },
        prognosisTime: getPrognosisTime(),
        stretchName: event.stretchName,
        affectedLegs: event.affectedLegs,
        durations: getInitialDuration(event.durations),
        isirkId: event.isirkId,
        infrastructureProvider:
          event.infrastructureProvider === null
            ? InfrastructureProviderEnum.NONE
            : event.infrastructureProvider,
      },
    },
  });
  const { handleSubmit } = formMethods;

  const openEditFields = useOpenEditFields((state) => state.openEditFields);

  const { mutate, isSuccess, isError, isPending } = useMutation({
    mutationFn: (body: OpenInfrastructureEventBody) =>
      mutationFnPOST<
        InfrastructureEventCreatedResponse,
        OpenInfrastructureEventBody
      >(`${getBackendUrl()}/infrastructure-event/${event.uuid}/change`, body),
  });
  const onSubmit = handleSubmit((data) => {
    const requestBody = getInfrastructureEventBody(
      data.infrastructureForm,
      event.referenceId,
    );
    mutate(requestBody);
  });

  if (stretchBuilderModalState.type !== "edit") return null;

  return (
    <FormProvider {...formMethods}>
      {!stretchBuilderModalState.stretchBuilderMode ? (
        <ActionModal
          title="Endre infrastrukturhendelse"
          actionTitle="Endre hendelse"
          onClose={closeStretchBuilder}
          onSubmit={onSubmit}
          isLoading={isPending}
          isSuccess={isSuccess}
          isError={isError}
          isSubmitDisabled={openEditFields.length > 0}
          successMessage="Hendelsen er endret"
          failureMessage="Kunne ikke endre hendelse. Prøv på nytt, eller kontakt IT hvis feilen vedvarer."
        >
          <InfrastructureModalContent
            stretchTitle={
              clickedStops.length > 0
                ? getStretchName(
                    selectedLegs,
                    clickedStops,
                    allStopsSelected,
                  ).join(", ")
                : event.stretchName
            }
          />
        </ActionModal>
      ) : (
        <StretchBuilderModeInfo />
      )}
    </FormProvider>
  );
};
