import size from "shared/theme/size";
import styled from "styled-components";

const ClockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 24px;
  align-items: center;
  color: ${({ theme }) => theme.__textColor};
  @media (max-width: ${size.breakpoints.xxl}px) {
    font-size: 1.6rem;
  }
  font-family: "Vy Sans";
`;
export const Heartbeat = styled.span`
  display: flex;
  align-items: center;
  vertical-align: middle;
  padding: 0 1px;
  animation: blink 2s infinite;
  @keyframes blink {
    0% {
      opacity: 0;
    }
    49% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  @media (max-width: ${size.breakpoints.xxl}px) {
    height: 33px;
  }
`;

export { ClockWrapper };
