import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import { StretchBuilderModeInfo } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/StretchBuilderModeInfo";
import { StationEventModal } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/StationEventModal";
import {
  FormSchema,
  formSchema,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/formSchema";
import { StationInfoRequest } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/types/stationInformationRequest";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useStretchBuilder } from "stores/useStretchBuilder";

export const CreateStationEvent: FC = () => {
  const [stretchBuilderModalState] = useStretchBuilder((state) => [
    state.stretchBuilderModalState,
  ]);

  const formMethods = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      stationForm: {
        stationInformationType: undefined,
        stops: [],
      },
    },
    mode: "onSubmit",
  });

  const { mutate: postStationInfo, status: postStationInfoStatus } =
    useMutation({
      mutationFn: (data: StationInfoRequest) =>
        mutationFnPOST<undefined, StationInfoRequest>(
          `${getBackendUrl()}/station-information`,
          data,
        ),
    });

  if (stretchBuilderModalState.type !== "create") return null;

  return (
    <FormProvider {...formMethods}>
      {!stretchBuilderModalState.stretchBuilderMode ? (
        <StationEventModal
          onFormSubmit={postStationInfo}
          formSubmitStatus={postStationInfoStatus}
        />
      ) : (
        <StretchBuilderModeInfo />
      )}
    </FormProvider>
  );
};
