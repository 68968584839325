/* eslint-disable no-prototype-builtins */

export const getFieldFromNestedObject = <V>(
  obj: object | undefined,
  path: string,
): V | undefined => {
  if (!obj) {
    return undefined;
  }

  const [first, ...rest] = path.split(".");

  if (rest.length === 0) {
    if (!obj.hasOwnProperty(first)) {
      return undefined;
    }

    return obj[first as keyof object] as V;
  }

  if (!obj.hasOwnProperty(first)) {
    return undefined;
  }

  return getFieldFromNestedObject(obj[first as keyof object], rest.join("."));
};
