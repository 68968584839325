import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import { StretchBuilderModeInfo } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/StretchBuilderModeInfo";
import { StationEventModal } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/StationEventModal";
import {
  FormSchema,
  formSchema,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/formSchema";
import { StationEventResponse } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/stationEventResponseSchema";
import { StationInfoRequest } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/types/stationInformationRequest";
import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useStretchBuilder } from "stores/useStretchBuilder";

type EditStationEventProps = {
  event: StationEventResponse;
};

export const EditStationEvent: FC<EditStationEventProps> = ({ event }) => {
  const [selectedStops, setSelectedStops, stretchBuilderModalState] =
    useStretchBuilder((state) => [
      state.selectedStops,
      state.setSelectedStops,
      state.stretchBuilderModalState,
    ]);

  const formMethods = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      stationForm: event,
    },
  });

  const { mutate: editStationInfo, status: editStationInfoStatus } =
    useMutation({
      mutationFn: (data: StationInfoRequest) =>
        mutationFnPOST<undefined, StationInfoRequest>(
          `${getBackendUrl()}/station-information/${event.uuid}/change`,
          data,
        ),
    });

  useEffect(() => {
    if (selectedStops.length <= 0) {
      const stop = formMethods.getValues("stationForm.stop");
      const stops = formMethods.getValues("stationForm.stops");
      if (stop !== undefined) {
        setSelectedStops([{ name: stop, id: stop }]);
      } else if (stops.length > 0) {
        setSelectedStops(stops.map((st) => ({ name: st, id: st })));
      }
    }
  }, []);

  if (stretchBuilderModalState.type !== "edit") return null;

  return (
    <FormProvider {...formMethods}>
      {!stretchBuilderModalState.stretchBuilderMode ? (
        <StationEventModal
          onFormSubmit={editStationInfo}
          formSubmitStatus={editStationInfoStatus}
        />
      ) : (
        <StretchBuilderModeInfo />
      )}
    </FormProvider>
  );
};
