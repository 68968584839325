import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Divider,
  HStack,
} from "@vygruppen/spor-react";
import { format } from "date-fns/format";
import { nb } from "date-fns/locale/nb";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import React from "react";
import { SectionTitle, Text } from "shared/components/typography/TitleStyles";
import { useTheme } from "styled-components";
import ISirkLogButtons from "./ISirkLogButtons";
import { IncidentNote } from "./types";

const ISirkNote: React.FC<IncidentNote> = ({
  content,
  summary,
  fromDate,
  toDate,
  isirk,
}) => {
  const theme = useTheme();
  const parsedFromDate = Date.parse(new Date(fromDate).toISOString());
  const parsedToDate = Date.parse(new Date(toDate).toISOString());

  return (
    <>
      <HStack gap={2}>
        <AccordionItem my={1} w="100%">
          <AccordionButton
            pl={0.5}
            color={theme.__textColor}
            _hover={{ backgroundColor: theme.__contentHoverColor }}
          >
            <div>
              <HStack>
                <SectionTitle>{summary}</SectionTitle>
                <Text>{isirk}</Text>
              </HStack>

              <Text>
                {format(parsedFromDate, "dd.MM.yyyy", { locale: nb })} -{" "}
                {format(parsedToDate, "dd.MM.yyyy", { locale: nb })}
              </Text>
            </div>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel
            color={theme.__textColor}
            display="flex"
            flexDirection="column"
          >
            <ISirkLogButtons isirk={isirk} />
            {parse(DOMPurify.sanitize(content))}
          </AccordionPanel>
        </AccordionItem>
      </HStack>
      <Divider />
    </>
  );
};

export default ISirkNote;
