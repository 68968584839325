import { DropsLogAge } from "features/TodoOplogg/Oplogg/OploggElement/utils";
import styled from "styled-components";

interface LogItemProps {
  age: DropsLogAge;
  bookmarked: boolean;
}

export const LogItem = styled.li<LogItemProps>`
  display: grid;
  cursor: pointer;
  padding: 12px;
  gap: 6px;
  border-radius: 6px;
  background-color: ${({ age, bookmarked, theme }) => {
    if (age === "NEW") {
      return theme.__newOploggItem;
    }
    if (bookmarked) {
      return theme.__bookmarkBackgroundColor;
    }
    return "transparent";
  }};
  outline: ${({ bookmarked, theme }) =>
    bookmarked ? `2px solid ${theme.__opLoggDotBorder}` : "none"};
  color: ${({ bookmarked, theme }) =>
    bookmarked ? theme.__textColor : "transparent"};
  grid-template-columns: 15px max-content 1fr;
  box-sizing: border-box;
`;

export const IndicatorWrapper = styled.div`
  grid-row: 1 / 3;
`;

export const VerticalLineForHighlightedBox = styled.div`
  width: 1px;
  height: calc(100% + 30px);
  background-color: ${({ color }) => color};
  margin-top: -2px;
  margin-left: 4.8%;
`;

export const VerticalLine = styled.div<{
  color: string;
}>`
  width: 1px;
  height: calc(100% + 20px);
  background-color: ${({ color }) => color};
  margin: auto;
`;
