import { LinkOverlay } from "@chakra-ui/react";
import { Badge, HStack } from "@vygruppen/spor-react";
import { format, isToday } from "date-fns";
import { nb } from "date-fns/locale";
import { DateSpan } from "features/VehicleList/common/DateSpan";
import ElementWrapper from "features/VehicleList/common/ElementWrapper";
import { FC } from "react";
import { Link, generatePath, useSearchParams } from "react-router-dom";
import { CallButton } from "shared/components/buttons/CallButton";
import { Text } from "shared/components/typography/TitleStyles";
import { ReserveAlternativeTransport } from "shared/types/alternativeTransport";
import { getPrintableFromVehicleType } from "shared/utils/alternativeTransport";
import { RESERVEBUS } from "shared/utils/routes";
import { useTheme } from "styled-components";

type Props = {
  reserveAlternativeTransport: ReserveAlternativeTransport;
  elementSelected: boolean;
};

export const ReserveAlternativeTransportElement: FC<Props> = ({
  reserveAlternativeTransport,
  elementSelected,
}) => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();

  const {
    workShiftId,
    shiftStartTime,
    shiftEndTime,
    driverPhoneNumber,
    isAvailable,
    baseStopName,
    type,
  } = reserveAlternativeTransport;

  const formattedShiftStartTime = shiftStartTime
    ? format(new Date(shiftStartTime), "HH:mm", { locale: nb })
    : "";

  const formattedShiftEndTime = shiftEndTime
    ? format(new Date(shiftEndTime), "HH:mm", { locale: nb })
    : "";

  const isNotToday = shiftStartTime ? !isToday(shiftStartTime) : false;

  const hasPhoneNumber = !!driverPhoneNumber && driverPhoneNumber.length > 0;

  return (
    <ElementWrapper selected={elementSelected}>
      <LinkOverlay
        as={Link}
        display="flex"
        flexDir="column"
        gap={0.5}
        flex={1}
        to={`${generatePath(RESERVEBUS, {
          vehicleUuid: reserveAlternativeTransport.id,
        })}?${searchParams.toString()}`}
        _focusVisible={{
          outline: `2px solid ${theme.__focusBorderColor}`,
          borderRadius: "6px",
        }}
      >
        <HStack height="25px" gap={1}>
          <Text bold>{`Reserve ${workShiftId ? `${workShiftId}` : ""}`}</Text>
          {!isAvailable && (
            <Badge size="sm" colorScheme="light-blue">
              I bruk
            </Badge>
          )}
        </HStack>

        <Text>
          {`${baseStopName}, ${getPrintableFromVehicleType(type) ?? ""}`}
        </Text>
        <Text>
          {formattedShiftStartTime} - {formattedShiftEndTime}
          {isNotToday && shiftStartTime && (
            <DateSpan>({format(shiftStartTime, "dd.MM.yy")})</DateSpan>
          )}
        </Text>
      </LinkOverlay>

      {hasPhoneNumber && (
        <CallButton phoneNumber={driverPhoneNumber} size="xs" />
      )}
    </ElementWrapper>
  );
};
