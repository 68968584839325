import { FC, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  Box,
  FormLabel,
  HStack,
  NumericStepper,
  Text,
  VStack,
} from "@vygruppen/spor-react";
import {
  CommonSubTypeProps,
  CommonTrainInfoFormProps,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import { useQuery } from "@tanstack/react-query";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { getBackendUrl } from "api/common";
import { TrainCompositionResponse } from "features/CenterContent/VehicleDetails/TrainDetails/TrainComposition/types";
import { FormSchema } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { AffectedStopsFields } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/AffectedStopsFields";
import {
  calcTrainCompMetaData,
  getDefaultCapacity,
  getNumPassengerCarsFromLineId,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/utils/changedCapacity";

export const TrainCapacityReduced: FC<
  CommonTrainInfoFormProps & CommonSubTypeProps
> = ({ mode, trainId, nominalDate, lineNumber }) => {
  const { control, setValue, getValues } = useFormContext<FormSchema>();

  useEffect(() => {
    setValue("trainForm.oldCapacityUnit", "CARS", {
      shouldValidate: true,
    });
    setValue("trainForm.newCapacityUnit", "CARS", {
      shouldValidate: true,
    });
  }, []);

  const MIN_CARS_OLD = 2;
  const MIN_CARS_NEW = 1;
  const MAX_CARS = 15;

  const oldCapacity = getValues("trainForm.oldCapacity") ?? MIN_CARS_OLD;
  const newCapacity = getValues("trainForm.newCapacity") ?? MIN_CARS_NEW;

  const { status: compositionStatus, data: compositionData } = useQuery({
    queryKey: ["trainComposition", nominalDate, trainId],
    refetchOnWindowFocus: true,
    queryFn: ({ signal }) =>
      queryFnGET<TrainCompositionResponse>({
        signal,
        url: `${getBackendUrl()}/trainComposition/NO/${nominalDate}/${trainId}`,
      }),
  });

  // Autofill based on train composition
  useEffect(() => {
    if (
      mode === "create" ||
      (!getValues("trainForm.oldCapacity") &&
        !getValues("trainForm.newCapacity"))
    ) {
      if (
        compositionStatus === "success" &&
        compositionData.trainComposition.length > 0
      ) {
        const { numPassengerCars, type } = calcTrainCompMetaData(
          compositionData.trainComposition,
        );
        const capacity = getDefaultCapacity(numPassengerCars, type, "reduced");
        setValue("trainForm.oldCapacity", capacity.old, {
          shouldValidate: true,
        });
        setValue("trainForm.newCapacity", capacity.new, {
          shouldValidate: true,
        });
      } else {
        const numPassengerCars = lineNumber
          ? getNumPassengerCarsFromLineId(lineNumber)
          : 5;
        const capacity = getDefaultCapacity(
          numPassengerCars,
          "MULTIPLE_UNIT",
          "reduced",
        );
        if (!getValues("trainForm.oldCapacity")) {
          setValue("trainForm.oldCapacity", capacity.old, {
            shouldValidate: true,
          });
        }

        if (!getValues("trainForm.newCapacity")) {
          setValue("trainForm.newCapacity", capacity.new, {
            shouldValidate: true,
          });
        }
      }
    }
  }, [compositionStatus]);

  return (
    <VStack width="100%" alignItems="flex-start" gap={3}>
      <AffectedStopsFields
        mode={mode}
        trainId={trainId}
        nominalDate={nominalDate}
      />
      <VStack paddingTop="1rem">
        <Box>
          <FormLabel marginBottom="0">Gammel kapasitet</FormLabel>
          <HStack gap="1rem" alignContent="center" display="flex">
            <Controller
              control={control}
              name="trainForm.oldCapacity"
              render={({ field }) => (
                <NumericStepper
                  name="trainForm.oldCapacity"
                  minValue={MIN_CARS_OLD}
                  maxValue={MAX_CARS}
                  withInput={false}
                  showZero
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);

                    // Make sure 'newCapacity' is always lower than 'oldCapacity'
                    if (value - 1 < newCapacity) {
                      setValue(
                        "trainForm.newCapacity",
                        Math.max(value - 1, MIN_CARS_NEW),
                        { shouldValidate: true },
                      );
                    }
                  }}
                />
              )}
            />
            <Text>Vogner</Text>
          </HStack>
        </Box>
        <Box>
          <FormLabel marginBottom="0">Ny kapasitet</FormLabel>
          <HStack gap="1rem">
            <Controller
              control={control}
              name="trainForm.newCapacity"
              render={({ field }) => (
                <NumericStepper
                  name="trainForm.newCapacity"
                  minValue={MIN_CARS_NEW}
                  maxValue={oldCapacity - 1}
                  showZero
                  withInput={false}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
            <Text>Vogner</Text>
          </HStack>
        </Box>
      </VStack>
    </VStack>
  );
};
