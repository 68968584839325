import { Td, Tr } from "@chakra-ui/react";
import { Button } from "@vygruppen/spor-react";
import {
  alarmRowOnClick,
  getMappedAlertState,
  getMappedAlertType,
} from "features/CenterContent/shared/TilsynAlerts/utils";
import { formatDateString } from "shared/utils/datetime";
import { useTheme } from "styled-components";
import { VehicleAlert } from "./schema";

type TilsynAlertRowProps = {
  alert: VehicleAlert;
  fullSize: boolean;
};

const TilsynAlertRow = ({ alert, fullSize = false }: TilsynAlertRowProps) => {
  const theme = useTheme();

  const alertTime = formatDateString(
    alert.createdAt,
    "dd.MM.yyyy HH:mm",
    "Ukjent datoformat",
  );
  const actionTime = formatDateString(
    alert.inProgressAt,
    "dd.MM.yyyy HH:mm",
    "Ikke påbegynt",
  );

  const getStateColor = () => {
    switch (alert.state) {
      case "DELAYED":
      case "PENDING":
        return theme.__tableRowWarnHighlight;
      case "ACTIVE":
        return theme.__tableRowAlertHighlight;
      default:
        return "inherit";
    }
  };

  return (
    <Tr backgroundColor={getStateColor()}>
      {fullSize && <Td>{alert.setId}</Td>}
      <Td>{alert.vehicleId}</Td>
      <Td>{alertTime}</Td>
      <Td style={{ wordBreak: "break-word" }}>{getMappedAlertType(alert)}</Td>
      <Td>{actionTime}</Td>
      {fullSize && <Td>{getMappedAlertState(alert)}</Td>}

      {fullSize && (
        <Td>
          <Button
            title="Åpne i Tilsyn"
            size="xs"
            variant="tertiary"
            onClick={() => alarmRowOnClick(alert)}
          >
            Åpne
          </Button>
        </Td>
      )}
    </Tr>
  );
};

export default TilsynAlertRow;
