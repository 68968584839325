import { Grid } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { Divider, Stack, Text } from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import { EventActionButtons } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/EventActionButtons";
import { FC, useEffect } from "react";
import { InfraStructureInformation } from "shared/types/infrastructureResponse";
import { isPlannedInfraStructureEvent } from "shared/utils/infrastructureEvent";
import { useStretchBuilder } from "stores/useStretchBuilder";
import { useTheme } from "styled-components";

type InfrastructureEventItemProps = {
  event: InfraStructureInformation;
  isFirstItem: boolean;
  onClose: () => void;
  askConfirmation: boolean;
  setAskConfirmation: (value: string | null) => void;
};

export const InfrastructureEventItem: FC<InfrastructureEventItemProps> = ({
  event,
  isFirstItem,
  onClose,
  askConfirmation,
  setAskConfirmation,
}) => {
  const theme = useTheme();

  const setStretchBuilderModalState = useStretchBuilder(
    (state) => state.setStretchBuilderModalState,
  );
  const { mutate: closeInfrastructureEvent, status: closeStatus } = useMutation(
    {
      mutationFn: (uuid: string) =>
        mutationFnPOST<string, undefined>(
          `${getBackendUrl()}/infrastructure-event/${uuid}/close`,
        ),
    },
  );
  // Close the selectEvent modal after one second if closeEvent is successful
  useEffect(() => {
    if (closeStatus === "success") {
      setTimeout(onClose, 1000);
    }
  }, [closeStatus]);

  return (
    <>
      {!isFirstItem && <Divider size="sm" my={0} />}
      <Grid
        as="li"
        autoFlow="column"
        autoColumns="1fr max-content"
        gap={1}
        py="9px"
        my={1}
        style={
          askConfirmation
            ? {
                border: `1px solid ${theme.colorInfo}`,
                borderRadius: "6px",
              }
            : {}
        }
        px={askConfirmation ? "9px" : "0"}
        alignItems="center"
      >
        <EventActionButtons
          closeStatus={closeStatus}
          askConfirmation={askConfirmation}
          setAskConfirmation={() => setAskConfirmation(event.uuid)}
          closeEvent={() => closeInfrastructureEvent(event.uuid)}
          editEvent={() =>
            setStretchBuilderModalState({
              type: "edit",
              eventType: "infrastructure",
              eventInfo: event,
              stretchBuilderMode: false,
            })
          }
          cancelCloseEvent={() => setAskConfirmation(null)}
          disableCloseButton={isPlannedInfraStructureEvent(event.type)}
        >
          <Stack gridColumn="span 2" gap={1}>
            <Text variant="xs" fontWeight="bold">
              {event.stretchName}
            </Text>
            <Text variant="xs">{event.title}</Text>
          </Stack>
        </EventActionButtons>
      </Grid>
    </>
  );
};
