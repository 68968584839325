import { tokens } from "@vygruppen/spor-react";
import size from "shared/theme/size";
import styled, { css } from "styled-components";
import { ObtrusiveScrollCss } from "../../../shared/components/transitions/scroll";

export const SharedGridStyle = css`
  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
  border: 1px solid ${({ theme }) => theme.colorOutline};
  border-radius: 6px;
  padding: ${tokens.default.size.spacing[2]};
`;

export const SharedContentGridLayout = css`
  display: grid;
  grid-gap: 6px;
  color: ${({ theme }) => theme.__textColor};
  height: calc(100% - 3rem);
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colorBackgroundMain};
`;

export const LandingpageContentGrid = styled.div`
  ${SharedContentGridLayout};
  grid-template-columns: 6fr 4fr;
  grid-template-rows: 7fr 3fr;
  grid-template-areas:
    "content-middle content-sidebar"
    "content-bottom content-bottom";

  @media (max-width: ${size.breakpoints.xxl}px) {
    grid-column: 1/3;
  }
`;

export const Sidebar = styled.div`
  ${SharedGridStyle};
  grid-area: content-sidebar;
  overflow: hidden;
`;

export const Bottom = styled.div`
  ${SharedGridStyle};
  grid-area: content-bottom;
  overflow: hidden;
  ${ObtrusiveScrollCss};
`;
