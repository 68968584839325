import { z } from "zod";

export enum UserRole {
  personellstyrerkond = "personellstyrerkond",
  personellstyrerlok = "personellstyrerlok",
  personellstyrervest = "personellstyrervest",
  vaktleder = "vaktleder",
  materiellstyrer = "materiellstyrer",
  trafikkplanlegger = "trafikkplanlegger",
  lokleder = "lokleder",
  transportleder = "transportleder",
}

export const UserRoleSchema = z.enum([
  UserRole.personellstyrerlok,
  UserRole.personellstyrervest,
  UserRole.vaktleder,
  UserRole.materiellstyrer,
  UserRole.trafikkplanlegger,
  UserRole.lokleder,
  UserRole.transportleder,
]);

export const isValidUserRole = (value: string | undefined): value is UserRole =>
  !!value && Object.keys(UserRole).includes(value);

export const roleTexts = [
  {
    text: "Vaktleder",
    value: UserRole.vaktleder,
  },
  {
    text: "Transportleder",
    value: UserRole.transportleder,
  },
  {
    text: "Personellstyrer (konduktør)",
    value: UserRole.personellstyrerkond,
  },
  {
    text: "Personellstyrer (lokomotivfører)",
    value: UserRole.personellstyrerlok,
  },
  {
    text: "Personellstyrer (vest)",
    value: UserRole.personellstyrervest,
  },
  {
    text: "Materiellstyrer",
    value: UserRole.materiellstyrer,
  },
  {
    text: "Trafikkplanlegger",
    value: UserRole.trafikkplanlegger,
  },
  {
    text: "Lokleder",
    value: UserRole.lokleder,
  },
];

export const getRoleText = (roleValue: string) => {
  const ind = roleTexts.findIndex((it) => it.value === roleValue);
  return roleTexts[ind]?.text;
};
