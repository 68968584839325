import { FC, useEffect, useState } from "react";
import {
  CommonSubTypeProps,
  CommonTrainInfoFormProps,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import { useExistingTrainCancelledEvents } from "features/CenterContent/VehicleDetails/TrainDetails/useExistingTrainCancelledEvents";
import { AffectedStopsFields } from "./AffectedStopsFields";

function isNotUndefined<T>(element: T | undefined): element is T {
  return element !== undefined;
}

export const TrainCancelled: FC<
  CommonTrainInfoFormProps & CommonSubTypeProps
> = (props) => {
  const { mode, trainId, nominalDate, uuid } = props;
  const { data: existingEvents } = useExistingTrainCancelledEvents({
    trainId,
    nominalDate,
    uuid,
  });

  const [alreadySelectedStops, setAlreadySelectedStops] = useState<string[]>(
    [],
  );
  useEffect(() => {
    let idsOrUndefined;
    if (existingEvents) {
      if (uuid && mode === "edit") {
        idsOrUndefined = existingEvents
          .filter((e) => e.uuid !== uuid)
          .flatMap((e) =>
            e.stops.flatMap((event) => event.ids.flatMap((ev) => ev.id)),
          );
      } else {
        idsOrUndefined = existingEvents.flatMap((event) =>
          event.stops.map(
            (stop) => stop.ids.find((id) => id.context === "JBV")?.id,
          ),
        );
      }

      setAlreadySelectedStops(
        idsOrUndefined.filter(isNotUndefined).map((id) => id as string),
      );
    }
  }, [existingEvents]);

  return (
    <AffectedStopsFields
      {...props}
      alreadySelectedStops={alreadySelectedStops}
    />
  );
};
