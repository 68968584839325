import { withErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import TrainStaff from "features/CenterContent/VehicleDetails/TrainDetails/TrainStaff/TrainStaff";
import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import TrainComposition from "features/CenterContent/VehicleDetails/TrainDetails/TrainComposition/TrainComposition";
import {
  SharedContentGridLayout,
  SharedGridStyle,
} from "../../shared/MainContentGrid";
import Tabs from "../../shared/Tabs/Tabs";
import TrainCondition from "./TrainCondition/TrainCondition";
import { TrainGPS } from "./TrainGPS/TrainGPS";
import TrainRoute from "./TrainRoute/TrainRoute";

const ContentGrid = styled.div`
  ${SharedContentGridLayout};
  margin: 0;
  grid-template-columns: minmax(16rem, 0.65fr) minmax(18rem, 1fr) minmax(
      16rem,
      0.65fr
    );
  grid-template-rows: 11rem 11rem minmax(16rem, 1fr);
  grid-template-areas:
    "train-route train-route train-route"
    "train-composition train-composition train-composition"
    "train-gps train-condition train-staff";
`;

const TrainRouteArea = styled.div`
  ${SharedGridStyle};
  grid-area: train-route;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
`;

export const TrainCompositionArea = styled.div`
  ${SharedGridStyle};
  grid-area: train-composition;
  display: flex;
  flex-direction: row;
  min-width: 220px;
  align-items: center;
  justify-content: center;
  padding: 24px 12px;
`;

const TrainConditionArea = styled.div`
  ${SharedGridStyle};
  grid-area: train-condition;
  overflow-y: auto;
`;

const TrainGPSArea = styled.div`
  aspect-ratio: 1/1;
  max-height: 100%;
  min-width: 100%;
  ${SharedGridStyle};
  grid-area: train-gps;
  padding: 0;
`;

const TrainStaffArea = styled.div`
  ${SharedGridStyle};
  grid-area: train-staff;
`;

export type DetailsParams = {
  trainNumber?: string;
  trainDate?: string;
};

const TrainDetails = () => {
  const { trainNumber, trainDate } = useParams<DetailsParams>();

  return (
    <>
      <Tabs />
      <ContentGrid>
        <TrainRouteArea>
          <TrainRoute trainId={trainNumber} nominalDate={trainDate} />
        </TrainRouteArea>

        <TrainCompositionArea>
          <TrainComposition nominalDate={trainDate} trainId={trainNumber} />
        </TrainCompositionArea>

        <TrainGPSArea>
          <TrainGPS />
        </TrainGPSArea>

        <TrainConditionArea>
          <TrainCondition />
        </TrainConditionArea>

        <TrainStaffArea>
          <TrainStaff nominalDate={trainDate} trainId={trainNumber} />
        </TrainStaffArea>
      </ContentGrid>
    </>
  );
};

export default withErrorBoundary(TrainDetails);
