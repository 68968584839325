import styled from "styled-components";

export const TopContainer = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  padding: 12px;
  gap: 3px 6px;
  border-style: solid;
  border-radius: 6px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.__borderColor};
`;

export const FieldContainer = styled.div`
  grid-column: 1 / span 3;
`;
