import { TrainIdentifier } from "@vygruppen/vy-train-map";
import { FormSchema as InfrastructureFormSchema } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/formSchema";
import { AffectedTrain } from "features/CenterContent/RoleContent/Vaktleder/types";
import {
  FormSchema,
  TrainFormSchema,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { TrainEventTypeEnum } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainEventTypeEnum";
import {
  TrainInformationDetailedResponse,
  trainResponseIsTrainCustomResponse,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationDetailedResponse";
import {
  BaseRequest,
  RouteSection,
  TrainInfoRequest,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationRequest";
import { isCustomInputType } from "features/CenterContent/shared/operationalInformation/utils";
import { DefaultValues } from "react-hook-form";
import { UserRole } from "shared/types/roles";

export type TrainOnTrack = TrainIdentifier & {
  direction: "ongoing" | "oncoming";
};

/*
 * Method for mapping form state to the request body (TrainInfoRequest) needed for creating a train info.
 * */
export const trainFormToRequestBody = (
  formState: TrainFormSchema,
  trainDetails: TrainOnTrack,
): TrainInfoRequest | null => {
  const { direction } = trainDetails;

  const baseRequestBody: BaseRequest = {
    incidentIds:
      formState.incidentIds && formState.incidentIds.length > 0
        ? formState.incidentIds
        : undefined,
    reason:
      formState.reason.type === "NO_REASON"
        ? null
        : {
            type: formState.reason.type,
            description:
              isCustomInputType(formState.reason.type) &&
              formState.reason.description
                ? formState.reason.description
                : null,
          },
    action:
      formState.action.type.length > 0
        ? {
            type: formState.action.type,
            description:
              isCustomInputType(formState.action.type) &&
              formState.action.description
                ? formState.action.description
                : null,
          }
        : null,
  };

  const dropsLogText =
    formState?.type &&
    formState.type !== TrainEventTypeEnum.TRAIN_CUSTOM &&
    formState?.dropsLogText?.enabled
      ? formState.dropsLogText.texts
      : undefined;

  const directionalAffectedStops =
    direction === "ongoing" ? "affectedStops" : "affectedStopsOtherDirection";

  const getTrainRouteSection = (): RouteSection => {
    if (formState.type !== TrainEventTypeEnum.TRAIN_STOPPED)
      return { fromStop: null, toStop: null };

    let routeSection = formState.trainRouteSection;
    if (direction === "ongoing") {
      return {
        fromStop: routeSection?.fromStop ?? null,
        toStop:
          (routeSection?.type === "AT_STOP" ? null : routeSection?.toStop) ??
          null,
      };
    }

    routeSection = formState.trainRouteSectionOtherDirection;
    return {
      fromStop: routeSection?.fromStop ?? null,
      toStop:
        routeSection?.type === "AT_STOP"
          ? null
          : (routeSection?.toStop ?? null),
    };
  };

  switch (formState?.type) {
    case "TRAIN_STOPPED":
      return {
        ...baseRequestBody,
        trainInformationType: formState.type,
        currentRouteSection: getTrainRouteSection(),
        dropsLogText,
      };
    case "TRAIN_CANCELLED":
      return {
        ...baseRequestBody,
        trainInformationType: formState.type,
        affectedStops:
          formState[directionalAffectedStops] ?? formState.affectedStops,
        dropsLogText,
      };
    case "TRAIN_CAPACITY_REDUCED":
      return {
        ...baseRequestBody,
        trainInformationType: formState.type,
        affectedStops:
          formState[directionalAffectedStops] ?? formState.affectedStops,
        oldCapacity: formState.oldCapacity,
        newCapacity: formState.newCapacity,
        oldCapacityUnit: formState.newCapacityUnit,
        newCapacityUnit: formState.oldCapacityUnit,
        dropsLogText,
      };
    case "TRAIN_CAPACITY_INCREASED":
      return {
        ...baseRequestBody,
        trainInformationType: formState.type,
        affectedStops:
          formState[directionalAffectedStops] ?? formState.affectedStops,
        oldCapacity: formState.oldCapacity,
        newCapacity: formState.newCapacity,
        oldCapacityUnit: formState.newCapacityUnit,
        newCapacityUnit: formState.oldCapacityUnit,
        dropsLogText,
      };
    case "TRAIN_DELAYED":
      return {
        ...baseRequestBody,
        trainInformationType: formState.type,
        affectedStops:
          formState[directionalAffectedStops] ?? formState.affectedStops,
        delayInMinutes:
          direction === "ongoing"
            ? formState.delayInMinutes
            : formState.delayInMinutesOtherDirection,
        dropsLogText,
      };
    case "TRAIN_DELAY_EXPECTED":
      return {
        ...baseRequestBody,
        trainInformationType: formState.type,
        affectedStops:
          formState[directionalAffectedStops] ?? formState.affectedStops,
        delayInMinutes:
          direction === "ongoing"
            ? formState.delayInMinutes
            : formState.delayInMinutesOtherDirection,
        dropsLogText,
      };
    case "TRAIN_LATE_TO_TRACK":
      return {
        ...baseRequestBody,
        trainInformationType: formState.type,
        affectedStops:
          formState[directionalAffectedStops] ?? formState.affectedStops,
        minutesLate:
          direction === "ongoing"
            ? formState.minutesLate
            : formState.minutesLateOtherDirection,
        dropsLogText,
      };
    case "TRAIN_NOT_STOPPING_AT_STATION":
      return {
        ...baseRequestBody,
        trainInformationType: formState.type,
        dropsLogText,
        affectedStops:
          direction === "ongoing"
            ? formState.skippedStops
            : formState.skippedStopsOtherDirection!, // TODO: fix presence properly
      };
    case "TRAIN_MISSING_PRODUCT":
      return {
        ...baseRequestBody,
        trainInformationType: formState.type,
        affectedStops:
          formState[directionalAffectedStops] ?? formState.affectedStops,
        products: formState.products,
        coverage: formState.coverage,
        dropsLogText,
      };
    case "TRAIN_STOPPING_EXTRA_AT_STATION":
      return {
        ...baseRequestBody,
        trainInformationType: formState.type,
        dropsLogText,
        affectedStops:
          direction === "ongoing"
            ? formState.extraStops
            : formState.extraStopsOtherDirection!, // TODO: fix presence properly
      };
    case "TRAIN_CLOSED_SET":
      return {
        ...baseRequestBody,
        trainInformationType: formState.type,
        affectedStops:
          formState[directionalAffectedStops] ?? formState.affectedStops,
        dropsLogText,
      };
    case "TRAIN_GENERAL":
      return {
        ...baseRequestBody,
        trainInformationType: formState.type,
        affectedStops:
          formState[directionalAffectedStops] ?? formState.affectedStops,
        severity: formState.severity,
        distributions: formState.distributions,
      };
    case "TRAIN_CHANGED_ROUTE":
      return {
        ...baseRequestBody,
        trainInformationType: formState.type,
        affectedStops: formState[directionalAffectedStops]!, // TODO: fix presence properly
        newRoute: formState.newRoute,
        cancelledStops: formState.cancelledStops,
        originalRouteName: formState.originalRouteName,
        newRouteName: formState.newRouteName,
        dropsLogText,
      };
    default:
      return null;
  }
};

export function trainDetailedResponseToForm(
  trainResponse: TrainInformationDetailedResponse,
): TrainFormSchema {
  // Opinfo has different types for TRAIN_GENERAL_INFO and _WARNING, but for UX reasons
  // the modal form handles them as the same event with severity as a different field.
  // Therefore, we have to consolidate the type here to be able to parse those events.
  const type: TrainFormSchema["type"] = trainResponseIsTrainCustomResponse(
    trainResponse,
  )
    ? TrainEventTypeEnum.TRAIN_CUSTOM
    : (trainResponse.type as TrainEventTypeEnum);
  // The zod form has an extra state field to control whether to show the drops log
  // text field, so we have to infer that state here
  const dropsLogText = trainResponseIsTrainCustomResponse(trainResponse)
    ? { enabled: false, texts: { NOB: "" } }
    : {
        enabled: !!trainResponse.dropsLogText?.NOB,
        texts: trainResponse.dropsLogText ?? { NOB: "" },
      };
  // Ideally this response should be parsed using trainFormSchema.parse,
  // but as long as event can be used to create or edit event
  // we can't, because some fields that are optional in event are required
  // in our schema, i.e. reason and affectedStops
  return { ...trainResponse, type, dropsLogText } as TrainFormSchema;
}

export const defaultFormSchema = (
  role: UserRole,
): DefaultValues<FormSchema> => {
  if (role === UserRole.lokleder) {
    return {
      trainForm: {
        type: TrainEventTypeEnum.TRAIN_STOPPED,
        reason: { type: "TECHNICAL_FAULT" },
        action: { type: "" },
        dropsLogText: { enabled: true, texts: { NOB: "" } },
      },
    };
  }
  return {
    trainForm: {
      type: undefined,
      reason: { type: "" },
      action: { type: "" },
      dropsLogText: { enabled: false, texts: { NOB: "" } },
    },
  };
};

type TrainSchema = FormSchema["trainForm"];
type InfraSchema = InfrastructureFormSchema["infrastructureForm"];
export const formStateHasCustomField = (formState: TrainSchema | InfraSchema) =>
  (formState as TrainSchema)?.type === "TRAIN_GENERAL" ||
  formState?.action?.type === "CUSTOM" ||
  formState?.reason?.type === "CUSTOM" ||
  (formState as InfraSchema)?.consequence?.type === "CUSTOM";
export const safeCapitalise = (text: string): string => {
  if (!text || text.length === 0) {
    return "";
  }
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const affectedTrainToTrainOnTrack = (
  affectedTrain: AffectedTrain,
  representativeTrain: AffectedTrain,
): TrainOnTrack => ({
  ...affectedTrain.trainId,
  direction:
    parseInt(affectedTrain.trainId.identifier, 10) % 2 ===
    parseInt(representativeTrain.trainId.identifier, 10) % 2
      ? "ongoing"
      : "oncoming",
});
