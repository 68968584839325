import { Box, CardSelect, HStack } from "@vygruppen/spor-react";
import { ReactNode } from "react";
import { darkTheme } from "shared/theme/darkTheme";
import styled from "styled-components";

// Only solution I found to round CardSelect.
// Setting style at the CardSelect
// component itself had no effect.
const CardSelectContainer = styled(Box)`
  * {
    border-radius: 99px !important;
    font-weight: bold;
  }

  span {
    order: -1; // Moves label to the left of icon
  }

  & button {
    padding-inline: 0;
    padding: 5.5px 12px;
  }
`;

// Background-color is set here (with margin: -18px)
// Since setting background color for the CardSelect
// popover was not possible otherwise.
// Also, it is likely the wrong color for now. Waiting
// for Irene to update the color patterns.
const CardSelectPopover = styled(HStack)`
  background-color: ${({ theme }) => theme.colorBackgroundMain};
  &&& {
    border-color: ${({ theme }) => theme.colorOutline};
  }
  border: 1px solid white;
  padding: 20px;
  border-radius: 12px;
  margin: -18px;

  max-width: 300px;
  flex-wrap: wrap;
`;

const IconNumber = styled.div`
  border-radius: 99px;
  background-color: ${({ theme }) => theme.__primaryLinkFocusBackgroundColor};
  color: ${darkTheme.colorTextMain};
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;

export const FilterCardSelect = ({
  label,
  children,
  selectedAmount,
}: {
  label: string;
  children: ReactNode;
  selectedAmount: number;
}) => (
  <CardSelectContainer>
    <CardSelect
      label={label}
      variant="base"
      size="sm"
      minWidth="max-content"
      icon={selectedAmount > 0 && <IconNumber>{selectedAmount}</IconNumber>}
    >
      <CardSelectPopover>{children}</CardSelectPopover>
    </CardSelect>
  </CardSelectContainer>
);
