import { Button, Tooltip } from "@chakra-ui/react";

import combine from "shared/icons/combine.svg?react";
import styled, { useTheme } from "styled-components";
import { Tab } from "./TabsWindows";

const Combine = styled(combine)`
  // This changes color for dark mode
  & path {
    fill: ${({ theme }) => theme.__textColor};
  }
`;

export const CombineButton = ({
  tabs,
  setSelectedTabIds,
}: {
  tabs: Tab[];
  setSelectedTabIds: (tabIds: string[]) => void;
}) => {
  const theme = useTheme();
  return (
    <Tooltip label="Slå sammen visning" hasArrow>
      <Button
        paddingX={1.5}
        size="xs"
        variant="tertiary"
        position="absolute"
        left="50%"
        transform="translate(-50%,0%)"
        zIndex={1000}
        backgroundColor={theme.colorBackgroundSecondary}
        onClick={() => {
          setSelectedTabIds([tabs[0].id]);
        }}
      >
        <Combine />
      </Button>
    </Tooltip>
  );
};
