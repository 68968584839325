import { FC, useEffect, useMemo, useState } from "react";
import {
  CommonSubTypeProps,
  CommonTrainInfoFormProps,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import { Select } from "shared/components/forms/Select";
import {
  ClosableAlert,
  Stack,
  StaticAlert,
  Text,
  VStack,
} from "@vygruppen/spor-react";
import { useFormContext } from "react-hook-form";
import { FormSchema } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import {
  TrainRoute,
  useTrainRouteWithoutRefetch,
} from "features/CenterContent/VehicleDetails/TrainDetails/useTrainRoute";
import { RenderErrorInPath } from "shared/components/forms/RenderErrorInPath";
import {
  TrainRouteResponse,
  useRouteChangedEvents,
} from "../../../../useRouteChangedEvents";

export interface Stretch {
  name: string;
  from: string;
  to: string;
  stops: string[];
}

const alternativeStretches: Stretch[] = [
  {
    name: "-",
    from: "",
    to: "",
    stops: [],
  },
  {
    name: "Romeriksporten",
    from: "Oslo S",
    to: "Lillestrøm",
    stops: ["OSL", "HLR", "LLS"],
  },
  {
    name: "Hovedbanen",
    from: "Oslo S",
    to: "Lillestrøm",
    stops: [
      "OSL",
      "BR",
      "BRB",
      "ALA",
      "AKE",
      "NYL",
      "GRO",
      "HGA",
      "HØB",
      "LØR",
      "HAB",
      "FJE",
      "STN",
      "SDA",
      "LLS",
    ],
  },

  {
    name: "Østfoldbanen",
    from: "Oslo S",
    to: "Ski",
    stops: [
      "OSL",
      "BEK",
      "NST",
      "LJA",
      "HTO",
      "HMA",
      "RSH",
      "KOL",
      "SOL",
      "MYV",
      "GUD",
      "OPG",
      "VEV",
      "LAN",
      "SKI",
    ],
  },
  {
    name: "Blixtunnelen",
    from: "Oslo S",
    to: "Ski",
    stops: ["OSL", "SKI"],
  },

  {
    name: "Drammenbanen",
    from: "Sandvika",
    to: "Lysaker",
    stops: ["SV", "BLO", "HVK", "STB", "LYS"],
  },
  {
    name: "Askerbanen",
    from: "Sandvika",
    to: "Lysaker",
    stops: ["SV", "LYS"],
  },

  {
    name: "Drammenbanen",
    from: "Sandvika",
    to: "Asker",
    stops: ["SV", "SLE", "BST", "HVA", "VAK", "HØN", "ASR"],
  },
  {
    name: "Askerbanen",
    from: "Sandvika",
    to: "Asker",
    stops: ["SV", "ASR"],
  },

  {
    name: "Drammenbanen",
    from: "Lysaker",
    to: "Asker",
    stops: [
      "LYS",
      "STB",
      "HVK",
      "BLO",
      "SV",
      "SLE",
      "BST",
      "HVA",
      "VAK",
      "HØN",
      "ASR",
    ],
  },
  {
    name: "Askerbanen",
    from: "Lysaker",
    to: "Asker",
    stops: ["LYS", "SV", "ASR"],
  },

  {
    name: "Hovedbanen",
    from: "Lillestrøm",
    to: "Kløfta",
    stops: ["LLS", "LSD", "FRO", "LBG", "KLØ"],
  },
  {
    name: "Gardermobanen",
    from: "Lillestrøm",
    to: "Kløfta",
    stops: ["LLS", "KLØ"],
  },

  {
    name: "Hovedbanen",
    from: "Langeland",
    to: "Eidsvoll",
    stops: ["LAL", "JEH", "NBY", "HSR", "SAD", "DAL", "BØN", "EVL"],
  },
  {
    name: "Gardermobanen",
    from: "Langeland",
    to: "Eidsvoll",
    stops: ["LAL", "GAR", "BKH", "EVV", "EVL"],
  },

  {
    name: "Via Drammen",
    from: "Hønefoss",
    to: "Oslo S",
    stops: [
      "HFS",
      "TYR",
      "VKS",
      "GHS",
      "ÅMO",
      "SEV",
      "HOK",
      "SBG",
      "MJD",
      "DLR",
      "GUL",
      "DRM",
      "BRA",
      "LIE",
      "ERU",
      "ASR",
      "SV",
      "LYS",
      "SKØ",
      "NTH",
      "OSL",
    ],
  },
  {
    name: "Via Roa",
    from: "Hønefoss",
    to: "Oslo S",
    stops: [
      "HFS",
      "HV",
      "JEV",
      "GVL",
      "ROA",
      "GRU",
      "BSR",
      "HST",
      "MON",
      "SY",
      "JEN",
      "HAK",
      "VAR",
      "ÅBY",
      "NIT",
      "MVT",
      "SNI",
      "SDM",
      "KJE",
      "NYD",
      "GRE",
      "TØY",
      "OSL",
    ],
  },
];

const findAlternativeStretch = (stretch: Stretch): Stretch => {
  if (stretch === undefined || stretch.name === "-") {
    return alternativeStretches[0];
  }
  return (
    alternativeStretches.find(
      (s) =>
        (s.from === stretch.from &&
          s.to === stretch.to &&
          s.name !== stretch.name) ||
        (s.from === stretch.to &&
          s.to === stretch.from &&
          s.name !== stretch.name),
    ) ?? alternativeStretches[0]
  );
};

const isRelevantStretch = (stretch: Stretch, route: TrainRoute | undefined) => {
  if (route === undefined) return false;
  if (stretch.name === "-") return true;
  const stops = route.stops.map((stop) => stop.stopId);
  return (
    stops.includes(stretch.stops[0]) &&
    stops.includes(stretch.stops[stretch.stops.length - 1])
  );
};

const eventAlreadyExists = (
  stretch: Stretch,
  existingEvents: TrainRouteResponse[],
  uuid?: string,
) => {
  const firstStop = stretch.stops[0];
  const lastStop = stretch.stops[stretch.stops.length - 1];
  return existingEvents.some((event) => {
    if (event.uuid === uuid) {
      return false;
    }
    if (
      firstStop === event.firstStop.ids[0].id &&
      lastStop === event.lastStop.ids[0].id
    ) {
      return true;
    }
    if (
      lastStop === event.firstStop.ids[0].id &&
      firstStop === event.lastStop.ids[0].id
    ) {
      return true;
    }
    return false;
  });
};

const shouldFlipStretchDirection = (
  stretch: Stretch,
  route: TrainRoute | undefined,
): boolean => {
  if (route === undefined) return false;
  const fromStop = stretch?.stops[0];
  const toStop = stretch?.stops[stretch.stops.length - 1];
  const stops = route?.stops.map((stop) => stop.stopId) ?? [];
  const fromIndex = stops.findIndex((stop) => stop === fromStop);
  const toIndex = stops.findIndex((stop) => stop === toStop);
  if (fromIndex === -1 && toIndex === -1) return false;
  if (fromIndex === -1 || toIndex === -1) return fromIndex < toIndex;
  return toIndex < fromIndex;
};

const formatStretchName = (stretch: Stretch, flipDirection: boolean) => {
  if (stretch.name === "-") {
    return "-";
  }
  return `${stretch.name} (${flipDirection ? stretch.to : stretch.from} - ${
    flipDirection ? stretch.from : stretch.to
  })`;
};

const affectedstopsMatchStopsInStretch = (
  affectedStops: string[],
  stops: string[],
) => {
  if (affectedStops.length !== stops.length) return false;
  const lastIndex = affectedStops.length - 1;
  return (
    (affectedStops[0] === stops[0] &&
      affectedStops[lastIndex] === stops[lastIndex]) ||
    (affectedStops[0] === stops[lastIndex] &&
      affectedStops[lastIndex] === stops[0])
  );
};

export const TrainChangedRoute: FC<
  CommonTrainInfoFormProps & CommonSubTypeProps
> = ({ trainId, nominalDate, uuid }) => {
  const {
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<FormSchema>();
  const prevAffectedStops = getValues("trainForm.affectedStops");
  const prevOldStretch = alternativeStretches.find(
    (stretch) =>
      stretch.name === getValues("trainForm.originalRouteName") &&
      affectedstopsMatchStopsInStretch(prevAffectedStops, stretch.stops),
  );
  const [oldStretch, setOldStretch] = useState<Stretch>(
    prevOldStretch || alternativeStretches[0],
  );
  const [newStretch, setNewStretch] = useState<Stretch>(
    prevOldStretch
      ? findAlternativeStretch(prevOldStretch)
      : alternativeStretches[0],
  );

  const { data: trainRoute, status: trainRouteStatus } =
    useTrainRouteWithoutRefetch({
      trainId,
      nominalDate,
    });

  const { data: existingEvents } = useRouteChangedEvents({
    trainId,
    nominalDate,
  });

  useEffect(() => {
    if (oldStretch !== undefined && newStretch !== undefined) {
      const trainRouteStops =
        trainRoute?.train?.stops.map((stop) => stop.stopId) ?? [];
      const shouldFlip = shouldFlipStretchDirection(
        oldStretch,
        trainRoute?.train,
      );

      const cancelledStops = shouldFlip
        ? oldStretch.stops
            .filter((stop) => !newStretch.stops.includes(stop))
            .toReversed()
        : oldStretch.stops.filter((stop) => !newStretch.stops.includes(stop));
      const newStops = shouldFlip
        ? newStretch.stops.toReversed()
        : newStretch.stops;
      const affectedStops = shouldFlip
        ? oldStretch.stops.toReversed()
        : oldStretch.stops;
      const numberOfStopsToReplace = trainRouteStops.slice(
        trainRouteStops.findIndex((stop) => stop === affectedStops[0]),
        trainRouteStops.findIndex(
          (stop) => stop === affectedStops[affectedStops.length - 1],
        ) + 1,
      ).length;
      const newRoute = trainRouteStops.toSpliced(
        trainRouteStops.findIndex((stop) => stop === affectedStops[0]),
        numberOfStopsToReplace,
        ...newStops,
      );
      setValue("trainForm.originalRouteName", oldStretch.name);
      setValue("trainForm.cancelledStops", cancelledStops, {
        shouldValidate: true,
      });
      setValue("trainForm.affectedStops", affectedStops, {
        shouldValidate: true,
      });
      setValue("trainForm.newRouteName", newStretch.name);
      setValue("trainForm.newRoute", newRoute, {
        shouldValidate: true,
      });
    }
  }, [newStretch, oldStretch]);

  const sameStretchAlreadySelected = useMemo(
    () => eventAlreadyExists(newStretch, existingEvents ?? [], uuid),
    [newStretch, existingEvents, uuid],
  );

  return (
    <Stack>
      <Text fontWeight="bold">Gammel rute:</Text>
      <Text pb="1rem">
        {formatStretchName(
          oldStretch,
          shouldFlipStretchDirection(oldStretch, trainRoute?.train),
        )}
      </Text>
      <VStack width="100%" gap={2} alignItems="flex-start">
        <Select
          label="Ny rute"
          defaultValue={alternativeStretches.findIndex(
            (stretch) =>
              (stretch.name === newStretch.name &&
                stretch.from === newStretch.from &&
                stretch.to === newStretch.to) ||
              (stretch.from === newStretch.to &&
                stretch.to === newStretch.from),
          )}
          onChange={(e) => {
            const index: number = Number(e.currentTarget.value);
            setNewStretch(alternativeStretches[index]);
            setOldStretch(findAlternativeStretch(alternativeStretches[index]));
          }}
        >
          {alternativeStretches.map((stretch, i) => (
            <option
              key={i}
              value={i}
              disabled={
                trainRouteStatus !== "error" &&
                !isRelevantStretch(stretch, trainRoute?.train)
              }
            >
              {formatStretchName(
                stretch,
                shouldFlipStretchDirection(stretch, trainRoute?.train),
              )}
            </option>
          ))}
        </Select>
        <RenderErrorInPath errors={errors} errorPath="trainForm.newRouteName" />
      </VStack>
      {trainRouteStatus === "error" && (
        <StaticAlert variant="warning">
          Kunne ikke hente rutedata. Listen med omkjøringer blir ikke filtrert
          automatisk. Pass på at du velger riktig omkjøring.
        </StaticAlert>
      )}
      {sameStretchAlreadySelected && (
        <ClosableAlert variant="warning">
          Samme trasé er valgt i en annen hendelse
        </ClosableAlert>
      )}
    </Stack>
  );
};
