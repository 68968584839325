import { CalendarDateTime, getLocalTimeZone } from "@internationalized/date";
import {
  DeleteOutline18Icon,
  EditOutline18Icon,
} from "@vygruppen/spor-icon-react";
import { Box, HStack, IconButton, Stack, Text } from "@vygruppen/spor-react";
import { format } from "date-fns";
import { nb } from "date-fns/locale";
import { Rule } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/formSchema";
import {
  Weekday,
  daysOfWeek,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/DayChoice";
import { WeekRepeat } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/PeriodicDuration";
import { FC } from "react";
import { useTheme } from "styled-components";

const getAllDayNames = (days: Weekday[]) =>
  days
    .map((day, i, self) => {
      if (i === 0) return `${day.name}`;
      if (self.length === i + 1) return ` og ${day.name}`;
      return `, ${day.name}`;
    })
    .join("");

const getGroupedDayNames = (days: Weekday[]) => {
  const startDay = days[0].name;
  const endDay = days[days.length - 1].name;
  return `${startDay} - ${endDay}`;
};

const getFormattedDays = (dayNumbers: number[], repeat: WeekRepeat) => {
  const days = daysOfWeek
    .sort((a, b) => a.dayNumber - b.dayNumber)
    .filter((day) => dayNumbers.includes(day.dayNumber));

  const isConsecutiveDays = days.every((day, i, self) => {
    const isLastElement = i === self.length - 1;

    return isLastElement || self[i + 1].dayNumber === day.dayNumber + 1;
  });

  const dayNames =
    days.length > 1 && isConsecutiveDays
      ? getGroupedDayNames(days)
      : getAllDayNames(days);

  return `${days.length === 7 ? `alle dager` : dayNames} ${
    repeat === WeekRepeat.WEEKLY ? `hver` : `annenhver`
  } uke`;
};

const formatDate = (date: CalendarDateTime) =>
  `${format(date.toDate(getLocalTimeZone()), "dd.MM.yyyy", {
    locale: nb,
  })}`;

const formatTime = (time: CalendarDateTime) =>
  `${format(time.toDate(getLocalTimeZone()), "HH:mm", {
    locale: nb,
  })}`;

type TimeIntervalSummaryProps = {
  rule: Rule;
  onEditClick: () => void;
  onDeleteRule: () => void;
};

export const TimeIntervalSummary: FC<TimeIntervalSummaryProps> = ({
  rule,
  onEditClick,
  onDeleteRule,
}) => {
  const theme = useTheme();
  return (
    <Box
      padding={4}
      boxShadow={`inset 0 0 0 1px ${theme.__borderColor}`}
      borderRadius="sm"
    >
      <HStack justifyContent="space-between">
        <Stack>
          <Text variant="xs" fontWeight="bold">
            {`${formatDate(rule.startDateTime)} - ${formatDate(
              rule.endDateTime,
            )}`}
          </Text>
          <Text variant="xs">
            {`Mellom kl ${formatTime(rule.startDateTime)} og
            ${formatTime(rule.endDateTime)}, ${getFormattedDays(
              rule.days,
              rule.repeat,
            )}`}
          </Text>
        </Stack>
        <HStack>
          <IconButton
            variant="ghost"
            icon={<EditOutline18Icon />}
            aria-label="Rediger regel"
            onClick={onEditClick}
          />
          <IconButton
            variant="ghost"
            icon={<DeleteOutline18Icon />}
            aria-label="Slett regel"
            onClick={onDeleteRule}
          />
        </HStack>
      </HStack>
    </Box>
  );
};
