import { zodResolver } from "@hookform/resolvers/zod";
import {
  Badge,
  Box,
  Skeleton,
  SkeletonText,
  Stack,
  StaticAlert,
  Text,
} from "@vygruppen/spor-react";
import { TrainIdentifier } from "@vygruppen/vy-train-map";
import { ErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { PreviewList } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/PreviewList";
import { OperationalIdentifier_JSON } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";
import {
  FieldErrorOrUndefined,
  FormSchema,
  formSchema,
  trainFormSchemaValidatorMessage,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { SubTypeInputs } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs";
import {
  defaultFormSchema,
  formStateHasCustomField,
  trainFormToRequestBody,
  TrainOnTrack,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/utils";
import { TrainInfoRequest } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationRequest";
import { supportsMultipleEvents } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/utils";
import { CustomEventInput } from "features/CenterContent/shared/operationalInformation/components/CustomEventInput";
import {
  EventGroup,
  useOperationalInformationTypes,
} from "features/CenterContent/shared/operationalInformation/hooks/useOperationalInformationTypes";
import {
  filterActions,
  sortAndFilterReasons,
} from "features/CenterContent/shared/operationalInformation/utils";
import { FC, useMemo } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { ActionModal } from "shared/components/ActionModal";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { InfoMessageWithRetryButton } from "shared/components/feedback/InfoMessageWithRetryButton";
import { InfrastructureEventType } from "shared/types/infrastructureResponse";
import { dropsRole } from "stores/dropsRole";
import { DropsLogTextInput } from "./DropsLogTextInput";
import { FormControlErrorMessage } from "./FormControlErrorMessage";
import { SelectIncidentsForTrain } from "./subTypeInputs/IncidentsForTrain";
import { useInfrastructureInformationForTrain } from "./useInfrastructureInformationForTrain";
import { useOperationalInfoPreview } from "./useOperationalInfoPreview";

export type CommonTrainInfoFormProps = {
  mode: "edit" | "create";
  uuid?: string;
};

export type CommonSubTypeProps = {
  trainId: string;
  nominalDate: string;
  lineNumber?: string | null;
  forOtherDirection?: boolean;
  infrastructureEvent?: InfrastructureEventType;
};

export type RequestBodyEdit = {
  type: "edit";
  body: TrainInfoRequest & {
    trainIdentifier: OperationalIdentifier_JSON;
  };
};

export type RequestBodyCreate = {
  type: "create";
  body: (TrainInfoRequest & {
    trainIdentifier: OperationalIdentifier_JSON;
  })[];
};

export type RequestBody = RequestBodyCreate | RequestBodyEdit;

type TrainInfoFormProps = {
  onSubmit: (body: RequestBody) => void;
  onClose: () => void;
  submitStatus: "error" | "pending" | "idle" | "success";
  formData?: FormSchema;
  formDataStatus?: "error" | "pending" | "idle" | "success";
  trainInfoWithOpenState: string[];
  title?: string;
  submitButtonLabel?: string;
  selectedTrain: TrainIdentifier;
};

const RenderInfrastructureDataNoDataInfo = ({
  retryFn,
}: {
  retryFn: () => void;
}) => (
  <InfoMessageWithRetryButton
    message="Fikk ikke til å hente aktive infrastrukturhendelser. Du kan fortsatt opprette toghendelse, men ikke koble den til en infrastrukturhendelse"
    retryFn={retryFn}
  />
);

/**
 * Render the train info form modal, either for editing an existing train event or creating a new train event
 * @param selectedTrain
 * @param onSubmit callback to perform when the form is submitted. The form must be valid before this callback is called.
 * @param onClose callback to perform when closing the modal
 * @param submitStatus the status of the action being performed onSubmit. Typically, the status of
 *                     the create/edit mutation
 * @param formData when editing an existing Event pass the existing status as formData
 * @param formDataStatus the status of the request that fetches formData
 * @param mode indicates if a new Event is being created, or an existing Event is being edited
 * @param uuid the id of event currently being edited
 * @param trainInfoWithOpenState a list of open train Events for this train. Used to avoid duplicate Events
 * @param title override the title
 * @param submitButtonLabel override the label on the submit button
 */

const TrainInfoFormModal: FC<TrainInfoFormProps & CommonTrainInfoFormProps> = ({
  selectedTrain,
  onSubmit,
  onClose,
  submitStatus,
  formData,
  formDataStatus = "success",
  mode,
  uuid,
  trainInfoWithOpenState,
  title,
  submitButtonLabel,
}) => {
  const { role } = dropsRole();

  const { identifier, nominalDate, countryCode } = selectedTrain;

  const { data: opInfoTypes, status: opInfoTypesStatus } =
    useOperationalInformationTypes();

  const formMethods = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: formData ? formData : defaultFormSchema(role),
    values: formData, // the formState will be updated if and when formData is updated
  });

  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
  } = formMethods;

  // Watch the form state and fetch preview data when it changes
  const formState = useWatch({
    control,
    name: "trainForm",
  });
  const { type } = formState;

  const isFormValid = isValid;

  const trainDetails: TrainOnTrack = {
    identifier,
    nominalDate,
    countryCode,
    direction: "ongoing",
  };

  const { previewData, previewStatus } = useOperationalInfoPreview({
    train: trainDetails,
    formState,
  });

  const {
    data: infrastructureData,
    status: infrastructureDataStatus,
    refetch: infrastructureDataRetryFn,
  } = useInfrastructureInformationForTrain(nominalDate, identifier);

  const reasons = useMemo(() => {
    if (opInfoTypes) {
      return sortAndFilterReasons(opInfoTypes.reasons, "TRAIN");
    }
    return [];
  }, [opInfoTypes?.reasons]);

  const tooltip = useMemo(
    () => trainFormSchemaValidatorMessage(formState),
    [formState],
  );

  // Submitting the form
  const onSubmitForm = handleSubmit((data) => {
    let requestBody: RequestBody;
    if (mode === "create") {
      requestBody = {
        type: "create",
        body: [
          {
            ...trainFormToRequestBody(data.trainForm, trainDetails)!,
            trainIdentifier: {
              country_code: trainDetails.countryCode,
              nominal_date: trainDetails.nominalDate,
              operational_identifier: trainDetails.identifier,
            },
          },
        ],
      };
    } else {
      requestBody = {
        type: "edit",
        body: {
          ...trainFormToRequestBody(data.trainForm, trainDetails)!,
          trainIdentifier: {
            country_code: trainDetails.countryCode,
            nominal_date: trainDetails.nominalDate,
            operational_identifier: trainDetails.identifier,
          },
        },
      };
    }

    if (requestBody) {
      onSubmit(requestBody);
    }
  });

  return (
    <FormProvider {...formMethods}>
      <ActionModal
        onSubmitTooltip={tooltip}
        title={
          title ??
          (mode === "create" ? "Opprett toghendelse" : "Endre toghendelse")
        }
        actionTitle={
          submitButtonLabel ??
          (mode === "create" ? "Opprett hendelse" : "Endre hendelse")
        }
        onClose={onClose}
        onSubmit={onSubmitForm}
        isLoading={submitStatus === "pending"}
        isSuccess={submitStatus === "success"}
        isError={submitStatus === "error"}
        successMessage={
          mode === "create" ? "Hendelse opprettet" : "Hendelse endret"
        }
        failureMessage={
          mode === "create"
            ? "Kunne ikke opprette hendelse. Prøv på nytt, eller kontakt IT hvis feilen vedvarer"
            : "Kunne ikke endre hendelse. Prøv på nytt, eller kontakt IT hvis feilen vedvarer"
        }
      >
        <ErrorBoundary>
          <Box w="100%" display="grid" gap={5}>
            {(opInfoTypesStatus === "pending" ||
              formDataStatus === "pending" ||
              infrastructureDataStatus === "pending") && (
              <>
                <Stack gap={2}>
                  <Skeleton height={6} />
                  <Skeleton height={6} />
                </Stack>
                <SkeletonText noOfLines={3} width="30%" />
              </>
            )}
            {(opInfoTypesStatus === "error" || formDataStatus === "error") && (
              <FailureMessage />
            )}
            {infrastructureDataStatus === "error" && (
              <RenderInfrastructureDataNoDataInfo
                retryFn={infrastructureDataRetryFn}
              />
            )}
            {opInfoTypesStatus === "success" &&
              formDataStatus === "success" && (
                <>
                  <Text variant="xs">
                    Tog:
                    <Badge
                      colorScheme="light-green"
                      borderRadius="9px"
                      fontWeight="400"
                      paddingX="12px !important"
                      marginX="6px"
                    >
                      {identifier}
                    </Badge>
                  </Text>
                  <Stack gap={2}>
                    {infrastructureData && (
                      <Stack marginBottom={4} gap={2}>
                        <SelectIncidentsForTrain items={infrastructureData} />
                      </Stack>
                    )}
                    <CustomEventInput
                      items={opInfoTypes.events.filter(
                        (event) => event.group === EventGroup.TRAIN,
                      )}
                      formFieldType="event"
                      optionDisabledPredicate={(event) =>
                        formData?.trainForm?.type !== event.type &&
                        !supportsMultipleEvents(event) &&
                        trainInfoWithOpenState?.some(
                          (info) => info === event.type,
                        )
                      }
                    />
                    <FormControlErrorMessage
                      field={errors.trainForm?.type as FieldErrorOrUndefined}
                    />
                    <CustomEventInput items={reasons} formFieldType="reason" />
                    <FormControlErrorMessage
                      field={
                        errors.trainForm?.reason?.type as FieldErrorOrUndefined
                      }
                    />
                    <CustomEventInput
                      items={filterActions(opInfoTypes.actions)}
                      formFieldType="action"
                    />
                  </Stack>
                  {type && (
                    <SubTypeInputs
                      type={type}
                      mode={mode}
                      trainId={identifier}
                      nominalDate={nominalDate}
                      uuid={uuid}
                    />
                  )}

                  {type && formState?.type !== "TRAIN_GENERAL" && (
                    <DropsLogTextInput
                      mode={mode}
                      defaultText={previewData?.internalMessage ?? ""}
                    />
                  )}

                  <PreviewList
                    previewStatus={previewStatus}
                    previewTexts={[previewData?.internalMessage ?? ""]}
                    isFormValid={isFormValid}
                  />

                  {formStateHasCustomField(formState) && (
                    <StaticAlert variant="info" id="infoBoxActionModal">
                      Hendelsen inneholder egendefinert tekst. Sjekk at
                      oppsummeringen ser riktig ut før du går videre.
                    </StaticAlert>
                  )}
                </>
              )}
          </Box>
        </ErrorBoundary>
      </ActionModal>
    </FormProvider>
  );
};

export default TrainInfoFormModal;
