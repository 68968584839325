import { Tooltip } from "@chakra-ui/react";
import {
  Box,
  ClosableAlert,
  HStack,
  Radio,
  RadioGroup,
  Skeleton,
  VStack,
} from "@vygruppen/spor-react";
import {
  CommonSubTypeProps,
  CommonTrainInfoFormProps,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import { FormSchema } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { splitPassedStopsAndBuildOptGroups } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/utils/affectedStopsUtils";
import { useTrainRouteWithoutRefetch } from "features/CenterContent/VehicleDetails/TrainDetails/useTrainRoute";
import { FC, useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { RenderErrorInPath } from "shared/components/forms/RenderErrorInPath";
import { Select } from "shared/components/forms/Select";
import { getToStopOptions } from "./utils/stationPickerUtils";

type FormValue =
  `trainForm.${"trainRouteSectionOtherDirection" | "trainRouteSection"}.${"fromStop" | "toStop" | "type"}`;

export const TrainStopped: FC<
  CommonTrainInfoFormProps & CommonSubTypeProps
> = ({ mode, trainId, nominalDate, forOtherDirection }) => {
  const {
    control,
    setValue,
    resetField,
    getValues,
    register,
    formState: { errors },
  } = useFormContext<FormSchema>();

  const fromStopFormValue: FormValue = `trainForm.${forOtherDirection ? "trainRouteSectionOtherDirection" : "trainRouteSection"}.fromStop`;
  const toStopFormValue: FormValue = `trainForm.${forOtherDirection ? "trainRouteSectionOtherDirection" : "trainRouteSection"}.toStop`;
  const typeFormValue: FormValue = `trainForm.${forOtherDirection ? "trainRouteSectionOtherDirection" : "trainRouteSection"}.type`;

  const { data: trainRoute, status: fetchStatus } = useTrainRouteWithoutRefetch(
    {
      trainId,
      nominalDate,
    },
  );

  const stops = useMemo(
    () => trainRoute?.train?.stops ?? [],
    [trainRoute?.train?.stops],
  );

  const fromStop = getValues(fromStopFormValue);
  const toStop = getValues(toStopFormValue);
  const stopSelectionType = getValues(typeFormValue);

  const firstStop = stops.slice(0, 1)[0]?.stopId;
  const lastStop = stops.slice(-1)[0]?.stopId;

  const fromStopIsLastStop = stops.length > 0 && fromStop === lastStop;

  // Try to autofill the form based on the trainRoutes currentTrainRouteSection, unless we are in editMode
  useEffect(() => {
    if (mode === "create" && fetchStatus === "success") {
      setValue(
        typeFormValue,
        trainRoute?.train?.currentTrainRouteSection?.toStopId
          ? "BETWEEN_STOPS"
          : "AT_STOP",
        { shouldValidate: true },
      );

      const fromStopId =
        trainRoute?.train?.currentTrainRouteSection?.fromStopId;
      const toStopId = trainRoute?.train?.currentTrainRouteSection?.toStopId;

      if (fromStopId) {
        setValue(fromStopFormValue, fromStopId, {
          shouldValidate: true,
        });
      }
      if (toStopId) {
        setValue(toStopFormValue, toStopId, {
          shouldValidate: true,
        });
      }
    }
  }, [fetchStatus, mode]);

  // Should not be able to select toStop if the chosen fromStop is the trains last stop
  useEffect(() => {
    if (fromStopIsLastStop) {
      setValue(typeFormValue, "AT_STOP");
    }
    // Should not be able to select a toStop that is equal to or before the fromStop
    if (
      stopSelectionType === "BETWEEN_STOPS" &&
      stops.findIndex((s) => s.stopId === toStop) <=
        stops.findIndex((s) => s.stopId === fromStop)
    ) {
      resetField(toStopFormValue);
    }
  }, [fromStop]);

  const displayStops = useMemo(
    () => splitPassedStopsAndBuildOptGroups(stops),
    [stops],
  );

  const showWarningWhenPassedStationIsChosen = () => {
    const selectedStop = stops.find((s) => s.stopId === fromStop);

    let lastArrivedStop;
    for (let i = stops.length - 1; i >= 0; i -= 1) {
      const stop = stops[i];
      if (stop.stopId && stop.isArrived) {
        lastArrivedStop = stop;
        break;
      }
    }

    if (selectedStop && selectedStop.isArrived) {
      return lastArrivedStop?.stopId !== fromStop;
    }
    return undefined;
  };

  const showWarningBox = useMemo(
    () => showWarningWhenPassedStationIsChosen(),
    [displayStops, fromStop],
  );

  if (fetchStatus === "pending") {
    return <Skeleton height={6} />;
  }

  if (
    fetchStatus === "error" ||
    !trainRoute?.train ||
    trainRoute.train.stops.length <= 0
  ) {
    return (
      <FailureMessage customMessage="Klarte ikke hente ut stoppene til toget. Prøv igjen, eller ta kontakt med IT dersom feilen vedvarer." />
    );
  }

  return (
    <VStack width="100%" alignItems="flex-start" gap={3}>
      <Controller
        control={control}
        name={typeFormValue}
        render={({ field }) => (
          <RadioGroup
            name="Stasjonsvalg"
            value={field.value ?? ""}
            onChange={field.onChange}
          >
            <Radio value="AT_STOP" name="AT_STOP">
              På stasjon
            </Radio>
            <Radio
              value="BETWEEN_STOPS"
              name="Mellom stasjoner"
              isDisabled={fromStopIsLastStop}
            >
              <Tooltip
                label={
                  fromStopIsLastStop
                    ? `Velg annen "på stasjon"-stopp enn siste stopp`
                    : undefined
                }
              >
                Mellom stasjoner
              </Tooltip>
            </Radio>
          </RadioGroup>
        )}
      />
      <HStack>
        <VStack gap={2} width="100%" alignItems="flex-start">
          <Select
            {...register(fromStopFormValue)}
            width="200px"
            label={
              stopSelectionType === "AT_STOP" ? "På stasjon" : "Fra stasjon"
            }
            placeholder="Velg stasjon"
          >
            {displayStops}
          </Select>
          <RenderErrorInPath errors={errors} errorPath={fromStopFormValue} />
        </VStack>

        {stopSelectionType !== "AT_STOP" && (
          <VStack gap={2} width="100%" alignItems="flex-start">
            <Select
              {...register(toStopFormValue)}
              width="200px"
              label="Til stasjon"
              placeholder="Velg stasjon"
            >
              {getToStopOptions(stops, fromStop)}
            </Select>
            <RenderErrorInPath errors={errors} errorPath={toStopFormValue} />
          </VStack>
        )}
      </HStack>
      {showWarningBox && (
        <Box w="73%">
          <ClosableAlert flexBasis="100%" variant="info">
            Toget har allerede passert stasjonen.
          </ClosableAlert>
        </Box>
      )}
    </VStack>
  );
};
