import {
  ChoiceChip,
  FormControl,
  FormLabel,
  Skeleton,
  Stack,
} from "@vygruppen/spor-react";
import {
  CommonSubTypeProps,
  CommonTrainInfoFormProps,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import { FormSchema } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { AffectedStopsFields } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/AffectedStopsFields";
import {
  OperationalTextContextType,
  useOperationalTextContexts,
} from "features/CenterContent/shared/operationalInformation/hooks/useOperationalInformationTypes";
import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FailureMessageWithRetryButton } from "shared/components/feedback/FailureMessageWithRetryButton";
import { DROPS_CONTEXT_TYPE } from "shared/types/types";
import { filterAndSortContexts } from "shared/utils/contextUtils";

export const TrainCustom: FC<CommonTrainInfoFormProps & CommonSubTypeProps> = ({
  forOtherDirection,
  mode,
  ...props
}) => {
  const {
    data: allContexts,
    isFetching,
    isPending,
    isSuccess,
    isError,
    refetch,
  } = useOperationalTextContexts();

  const [contexts, setContexts] = useState(allContexts ?? []);

  // The original `isLoading` from tanstack query is only true on first request, not on retries
  const isLoading = isFetching || isPending;

  const { getValues, setValue } = useFormContext<FormSchema>();

  const contextIsChecked = useCallback(
    (context: OperationalTextContextType["type"]) =>
      getValues("trainForm.distributions.contextTypes")?.some(
        (v) => v === context,
      ) ?? false,
    [getValues],
  );

  const onContextClick = useCallback(
    (
      context: OperationalTextContextType["type"],
      event: ChangeEvent<HTMLInputElement>,
    ) => {
      const oldValues = getValues("trainForm.distributions.contextTypes") ?? [];
      if (event.target.checked && !contextIsChecked(context)) {
        setValue(
          "trainForm.distributions.contextTypes",
          [...oldValues, context],
          {
            shouldValidate: true,
          },
        );
      } else {
        setValue(
          "trainForm.distributions.contextTypes",
          oldValues.filter((v) => v !== context),
          { shouldValidate: true },
        );
      }
    },
    [getValues, setValue, contextIsChecked],
  );

  useEffect(() => {
    if (mode === "create") {
      setValue("trainForm.distributions.contextTypes", [DROPS_CONTEXT_TYPE], {
        shouldValidate: true,
      });
    }
  }, []);

  useEffect(() => {
    setContexts(filterAndSortContexts(allContexts));
  }, [allContexts]);

  return (
    <>
      <AffectedStopsFields
        forOtherDirection={forOtherDirection}
        mode={mode}
        {...props}
      />

      <FormControl>
        <FormLabel as="legend">Mottagere</FormLabel>

        {isLoading && (
          <Stack flexDirection="row" width="100%">
            <Skeleton height={6} borderRadius={300} flexGrow={2} />
            <Skeleton height={6} borderRadius={300} flexGrow={3} />
            <Skeleton height={6} borderRadius={300} flexGrow={1} />
          </Stack>
        )}

        {isError && !isLoading && (
          <FailureMessageWithRetryButton
            customMessage="Kunne ikke hente mottagere fra Operational Text-tjenesten"
            retryFunction={() => refetch()}
          />
        )}

        {isSuccess && !isLoading && (
          <Stack flexDirection="row" flexWrap="wrap">
            {contexts?.map((context) => (
              <ChoiceChip
                disabled={context.type === DROPS_CONTEXT_TYPE}
                key={context.type}
                onChange={(e) => onContextClick(context.type, e)}
                isChecked={contextIsChecked(context.type)}
              >
                {context.name}
              </ChoiceChip>
            ))}
          </Stack>
        )}
      </FormControl>
    </>
  );
};
