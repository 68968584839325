export type CloudwatchRumConfig = {
  appName: string;
  appVersion: string;
  endpoint: string;
  rumAppId: string;
  identityPoolId: string;
  sessionEventLimit: number;
};

export const cloudwatchRumConfig = (): CloudwatchRumConfig | null => {
  const appName: string = import.meta.env.VITE_APP_NAME;
  const appVersion: string = import.meta.env.VITE_APP_VERSION;

  if (
    import.meta.env.DEV ||
    window.location.origin.includes("localhost") ||
    window.location.origin.includes("127.0.0.1")
  ) {
    return {
      appName,
      appVersion,
      endpoint: "https://dataplane.rum.eu-west-1.amazonaws.com",
      rumAppId: "d7fe0783-99f3-4988-ba76-0aa0e1b6a5ca",
      identityPoolId: "eu-west-1:8add8ae7-514a-428a-83bb-25b4f719c417",
      sessionEventLimit: 1000,
    };
  }
  if (window.location.origin.endsWith(".test.trafficgui.vydev.io")) {
    return {
      appName,
      appVersion,
      endpoint: "https://dataplane.rum.eu-west-1.amazonaws.com",
      rumAppId: "d7fe0783-99f3-4988-ba76-0aa0e1b6a5ca",
      identityPoolId: "eu-west-1:8add8ae7-514a-428a-83bb-25b4f719c417",
      sessionEventLimit: 1000,
    };
  }
  if (window.location.origin.endsWith(".stage.trafficgui.vydev.io")) {
    return {
      appName,
      appVersion,
      endpoint: "https://dataplane.rum.eu-west-1.amazonaws.com",
      rumAppId: "b7fd36ca-089f-4736-9f30-a18512791d39",
      identityPoolId: "eu-west-1:4a803d4d-f690-41d7-a1c5-2bf9fb294c93",
      sessionEventLimit: 1000,
    };
  }
  if (window.location.origin.endsWith("drops.vy.no")) {
    return {
      appName,
      appVersion,
      endpoint: "https://dataplane.rum.eu-west-1.amazonaws.com",
      rumAppId: "2da3ad23-b51a-480a-afa3-858a0ee9e8a7",
      identityPoolId: "eu-west-1:a6511d96-50aa-465f-a1de-6b6db9ac82da",
      sessionEventLimit: 10000,
    };
  }
  return null;
};
