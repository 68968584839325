import { fadein } from "shared/components/transitions/fade";
import { ObtrusiveScrollCss } from "shared/components/transitions/scroll";
import styled from "styled-components";

export const TodoHeader = styled.span`
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: ${({ theme }) => theme.__textColor};
  font-family: "Vy Sans";
`;

export const TodoMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: messages;
  overflow-y: auto;
  width: 420px;
  position: relative;
  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
`;

export const TodoNewWrapper = styled.div`
  display: flex;
  grid-area: todonew;
  flex-direction: column;
  flex-grow: 0;
  z-index: 5;
  margin-bottom: 6px;
`;

export const TodoElementWrapper = styled.div`
  animation: ${fadein} 300ms ease-in;
  border-bottom: 1px solid ${({ theme }) => theme.__borderColor};
  padding: 0 1rem 0.5rem;
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;
  min-height: calc(100% - 62px); /* 62px is size of ToDo header*/
  overflow: auto;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
  border-bottom: 2px solid ${({ theme }) => theme.__borderColor};
`;

export const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 12px;
`;

const TodoSection = styled.div`
  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
  width: 100%;
  padding: 0 1rem 1rem;
  border-radius: 6px;
  box-sizing: border-box;
`;

export const CheckboxListContainer = styled.div`
  display: flex;
  align-content: center;
  align-self: center;
  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
  width: 100%;
  gap: 6px;
`;

export const TodoRowContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  padding-top: 12px;
`;

export const ListContainer = styled(TodoSection)`
  padding: 0;
  display: flex;
  ${ObtrusiveScrollCss};
  overflow-y: scroll;
  overflow-x: hidden;
  flex-direction: column;
  align-items: center;
  border-radius: 0 0 3px 3px;
`;

export const TodoListHeader = styled.div`
  position: sticky;
  align-self: flex-start;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
  width: 100%;
  padding: 11px 16px;
  box-sizing: border-box;
`;
