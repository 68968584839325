import { EditOutline18Icon } from "@vygruppen/spor-icon-react";
import {
  Badge,
  Button,
  Flex,
  Input,
  Stack,
  StaticAlert,
  Text,
} from "@vygruppen/spor-react";
import { ErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { FormSchema } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/formSchema";
import { Preview } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/Preview";
import { Prognosis } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/Prognosis";
import { SelectLists } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/SelectLists";
import { DurationInput } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/DurationInput";
import { usePreview } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/hooks/usePreview";
import { isPlannedInfraStructureEvent } from "shared/utils/infrastructureEvent";
import { getStretchName } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stretchBuilderTexts";
import { formStateHasCustomField } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/utils";
import { FC, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useStretchBuilder } from "stores/useStretchBuilder";

type ModalContentProps = {
  stretchTitle: string;
};

export const InfrastructureModalContent: FC<ModalContentProps> = ({
  stretchTitle,
}) => {
  const { control, register, setValue } = useFormContext<FormSchema>();
  const formState = useWatch({
    control,
    name: "infrastructureForm",
  });

  const [
    clickedStops,
    selectedLegs,
    allStopsSelected,
    stretchBuilderModalState,
    setStretchBuilderModalState,
  ] = useStretchBuilder((state) => [
    state.clickedStops,
    state.selectedLegs,
    state.allStopsSelected,
    state.stretchBuilderModalState,
    state.setStretchBuilderModalState,
  ]);

  const mode = stretchBuilderModalState.type;

  useEffect(() => {
    if (selectedLegs.length > 0) {
      setValue(
        "infrastructureForm.affectedLegs",
        selectedLegs.map((leg) => ({
          fromStop: leg.fromStop.id,
          toStop: leg.toStop.id,
        })),
      );
      setValue(
        "infrastructureForm.stretchName",
        getStretchName(selectedLegs, clickedStops, allStopsSelected).join(", "),
      );
    }
  }, []);

  const { previewData, previewStatus } = usePreview(formState);

  if (mode !== "create" && mode !== "edit") return null;

  return (
    <ErrorBoundary>
      <Stack spacing={5} width="100%">
        <Flex gap="6px" alignItems="center">
          <Flex gap="4px" flexWrap="wrap" alignItems="center">
            <Text variant="xs">Strekning: </Text>
            {stretchTitle.split(", ").map((it) => (
              <Badge
                key={it}
                colorScheme="light-green"
                borderRadius="9px"
                fontWeight="400"
                paddingX="12px !important"
              >
                {it}
              </Badge>
            ))}
          </Flex>
          <Button
            size="xs"
            variant="tertiary"
            leftIcon={<EditOutline18Icon m="auto" />}
            aria-label="Endre strekning"
            onClick={() =>
              setStretchBuilderModalState({
                ...stretchBuilderModalState,
                stretchBuilderMode: true,
              })
            }
          >
            <Text variant="xs">Endre strekning</Text>
          </Button>
        </Flex>
        <Stack spacing={3} width="100%">
          <Input
            label="Endre strekningsnavn"
            type="string"
            {...register("infrastructureForm.stretchName")}
          />
          <SelectLists />
          {isPlannedInfraStructureEvent(formState.event) && (
            <Input
              width="13.5rem"
              label="I-sirk"
              type="string"
              {...register("infrastructureForm.isirkId")}
            />
          )}
          <DurationInput />
          {!isPlannedInfraStructureEvent(formState.event) && <Prognosis />}
        </Stack>
        <Preview
          previewStatus={previewStatus}
          previewedText={previewData?.internalMessage ?? ""}
        />
        {formStateHasCustomField(formState) && (
          <StaticAlert variant="info" id="infoBoxActionModal">
            Hendelsen inneholder egendefinert tekst. Sjekk at oppsummeringen ser
            riktig ut før du går videre.
          </StaticAlert>
        )}
      </Stack>
    </ErrorBoundary>
  );
};
