import { StationInfoRequest } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/types/stationInformationRequest";
import { distributionInput } from "features/CenterContent/shared/operationalInformation/utils";
import { FieldError, FieldErrors } from "react-hook-form";
import { z } from "zod";

export const stationThreatLevelEnum = z.enum(
  ["ALPHA", "BRAVO", "CHARLIE", "DELTA"],
  {
    errorMap: () => ({
      message: "Du må velge trusselnivå for å kunne opprette hendelsen",
    }),
  },
);

export type StationThreatLevelEnum = z.infer<typeof stationThreatLevelEnum>;

const stationThreatLevelSchema = z.object({
  stationInformationType: z.literal("STATION_THREAT_LEVEL"),
  stops: z.string().array().min(1),
  threatLevel: stationThreatLevelEnum,
});

export const stationMeansOfPaymentEnum = z.enum(["COINS", "BANKNOTE", "CARD"], {
  errorMap: () => ({
    message: "Du må velge et betalingsvalg for å kunne opprette hendelsen",
  }),
});

export type StationMeansOfPaymentEnum = z.infer<
  typeof stationMeansOfPaymentEnum
>;

const stationSalesChannelReducedSchema = z.object({
  stationInformationType: z.literal("STATION_SALES_CHANNEL_REDUCED"),
  stops: z.string().array().min(1),
  meansOfPayment: stationMeansOfPaymentEnum,
});

const stationPersonSchema = z.object({
  stationInformationType: z.enum([
    "STATION_DIFFICULT_CUSTOMER",
    "STATION_WANTED_PERSON",
  ]),
  stops: z.string().array().min(1),
  personDescription: z.object({
    NOB: z
      .string()
      .min(1, { message: "Du må fylle ut en beskrivelse av hendelsen" }),
    ENG: z
      .string()
      .min(1, { message: "Du må fylle ut en beskrivelse av hendelsen" }),
  }),
});

const singleStopSchema = z
  .string()
  .min(1, { message: "Du må velge en stasjon for å kunne opprette hendelsen" });

const stationSingleStopSchema = z.object({
  stationInformationType: z.enum([
    "STATION_ELEVATOR_FAULT",
    "STATION_SPEAKER_FAULT",
    "STATION_TRACK_MONITOR_FAULT",
    "STATION_WHEELCHAIR_RAMP_FAULT",
  ]),
  stop: singleStopSchema,
  affectedTracks: z
    .array(z.number().int().nonnegative(), {
      errorMap: () => ({
        message: "Du må velge minst ett spor for å kunne opprette hendelsen",
      }),
    })
    .min(1),
});

const arrivalAndDepartureMonitorFaultSchema = z.object({
  stationInformationType: z.literal(
    "STATION_ARRIVAL_AND_DEPARTURE_MONITOR_FAULT",
  ),
  stop: singleStopSchema,
  affectedTracks: z.array(z.number()).optional(),
});

export const stationCustomEventSeverityEnum = z.enum(["INFO", "WARNING"], {
  errorMap: () => ({ message: "Du må velge en alvorlighetsgrad" }),
});

export type StationCustomEventSeverityEnum = z.infer<
  typeof stationCustomEventSeverityEnum
>;

const stationCustomSchema = z.object({
  stationInformationType: z.literal("STATION_GENERAL"),
  stops: z.string().array().min(1),
  severity: stationCustomEventSeverityEnum,
  distributions: distributionInput,
});

const stationGeneralSchema = z.object({
  stationInformationType: z.enum([
    "STATION_PLATFORM_FAULT",
    "STATION_ALL_SALES_CHANNELS_DOWN",
    "STATION_APP_SALES_CHANNEL_DOWN",
    "STATION_MOBILE_TERMINALS_SALES_CHANNEL_DOWN",
    "STATION_TICKET_MACHINE_DOWN",
    "STATION_TICKET_VALIDATOR_DOWN",
    "STATION_DEPOSIT_MACHINE_DOWN",
    "STATION_MAIN_MONITOR_FAULT",
    "STATION_PLATFORM_SLIPPERY",
    "STATION_PARKING_ISSUE",
    "STATION_ENTRANCE_CHANGED",
    "STATION_WAITING_ROOM_CLOSED",
    "STATION_CLOSED_FOR_PASSENGERS",
    "STATION_DROPS_EVACUATION",
  ]),
  stops: z.string().array().min(1),
});

export const stationFormSchema = z.discriminatedUnion(
  "stationInformationType",
  [
    stationThreatLevelSchema,
    stationSalesChannelReducedSchema,
    stationPersonSchema,
    stationSingleStopSchema,
    arrivalAndDepartureMonitorFaultSchema,
    stationCustomSchema,
    stationGeneralSchema,
  ],
  {
    errorMap: () => ({
      message: "Du må velge en hendelsestype for å kunne opprette hendelsen",
    }),
  },
);

export type StationFormSchema = z.infer<typeof stationFormSchema>;

export type StationInformationType =
  StationFormSchema["stationInformationType"];

export const formSchema = z.object({
  stationForm: stationFormSchema,
});

export type FormSchema = z.infer<typeof formSchema>;

/* React-hook-form uses an intersection type for interacting with fields, but not with their errors.
   This means that although errors are properly populated in js, the type is wrong and only the
   common fields are possible to get errors for without casting.
   The types below allow us to manually tell TS that an error can exist for a field that doesn't
   exist on every subtype of the schema.
   It's unnecessarily difficult but apparently also something they aren't able to fix on their own:
   https://github.com/react-hook-form/react-hook-form/issues/8815
*/
type FormIntersection = z.infer<typeof stationThreatLevelSchema> &
  z.infer<typeof stationSalesChannelReducedSchema> &
  z.infer<typeof stationPersonSchema> &
  z.infer<typeof stationSingleStopSchema> &
  z.infer<typeof stationCustomSchema> &
  z.infer<typeof stationGeneralSchema>;

// For a field that exists on any subtype of StationFormSchema, add that field as a possible
// key that can have a react-hook-form FieldError.
export type FormErrorWithDiscriminatedField<T extends keyof FormIntersection> =
  | (FieldErrors<FormSchema> & {
      [key in T]?: FieldError;
    })
  | undefined;

export function formToStationInformationRequest({
  stationForm,
}: FormSchema): StationInfoRequest {
  switch (stationForm.stationInformationType) {
    case "STATION_THREAT_LEVEL":
      return {
        stationInformationType: stationForm.stationInformationType,
        stops: stationForm.stops,
        threatLevel: stationForm.threatLevel,
      };

    case "STATION_SALES_CHANNEL_REDUCED":
      return {
        stationInformationType: stationForm.stationInformationType,
        stops: stationForm.stops,
        meansOfPayment: stationForm.meansOfPayment,
      };

    case "STATION_DIFFICULT_CUSTOMER":
    case "STATION_WANTED_PERSON":
      return {
        stationInformationType: stationForm.stationInformationType,
        stops: stationForm.stops,
        personDescription: stationForm.personDescription,
      };

    case "STATION_ELEVATOR_FAULT":
    case "STATION_SPEAKER_FAULT":
    case "STATION_TRACK_MONITOR_FAULT":
    case "STATION_WHEELCHAIR_RAMP_FAULT": {
      const sortedTracks = stationForm.affectedTracks.toSorted((a, b) => a - b);
      return {
        stationInformationType: stationForm.stationInformationType,
        stop: stationForm.stop,
        affectedTracks: sortedTracks,
      };
    }

    case "STATION_ARRIVAL_AND_DEPARTURE_MONITOR_FAULT": {
      const sortedTracks = stationForm.affectedTracks?.toSorted(
        (a, b) => a - b,
      );
      return {
        stationInformationType: stationForm.stationInformationType,
        stop: stationForm.stop,
        affectedTracks: sortedTracks ?? [],
      };
    }
    case "STATION_GENERAL":
      return {
        stationInformationType: stationForm.stationInformationType,
        stops: stationForm.stops,
        severity: stationForm.severity,
        distributions: stationForm.distributions,
      };

    case "STATION_PLATFORM_FAULT":
    case "STATION_ALL_SALES_CHANNELS_DOWN":
    case "STATION_APP_SALES_CHANNEL_DOWN":
    case "STATION_MOBILE_TERMINALS_SALES_CHANNEL_DOWN":
    case "STATION_TICKET_MACHINE_DOWN":
    case "STATION_TICKET_VALIDATOR_DOWN":
    case "STATION_DEPOSIT_MACHINE_DOWN":
    case "STATION_MAIN_MONITOR_FAULT":
    case "STATION_PLATFORM_SLIPPERY":
    case "STATION_PARKING_ISSUE":
    case "STATION_ENTRANCE_CHANGED":
    case "STATION_WAITING_ROOM_CLOSED":
    case "STATION_CLOSED_FOR_PASSENGERS":
    case "STATION_DROPS_EVACUATION":
    default:
      return {
        stationInformationType: stationForm.stationInformationType,
        stops: stationForm.stops,
      };
  }
}
