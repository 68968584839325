import { Checkbox, CheckboxGroup } from "@vygruppen/spor-react";
import { ShowIfTrue } from "features/TodoOplogg/common/ShowIfTrue";
import { TodoForm } from "features/TodoOplogg/formSchema";
import { useFormContext } from "react-hook-form";
import { RenderErrorInPath } from "shared/components/forms/RenderErrorInPath";
import { roleTexts } from "shared/types/roles";
import { useSetShowRoles, useShowRoles } from "../useTodoContext";
import { ArrowIconButton } from "./ArrowIconButton";

export const RoleOptions = () => {
  const showRoles = useShowRoles();
  const setShowRoles = useSetShowRoles();

  const {
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<TodoForm>();

  const value = watch("roles");

  return (
    <>
      <ArrowIconButton text="Send til" onClick={setShowRoles} />
      <ShowIfTrue show={showRoles}>
        <CheckboxGroup
          direction="column"
          value={value}
          onChange={(val: string[]) => {
            setValue("roles", val);
          }}
        >
          {roleTexts.map((role, i) => (
            <Checkbox key={`role-${i}`} value={role.value}>
              {role.text}
            </Checkbox>
          ))}
        </CheckboxGroup>
      </ShowIfTrue>

      {value.length === 0 && (
        // Shouldnt need 'value.length === 0' if RenderErrorInPath worked correctly
        // Therefore, if you notice any error in the code here then please fix it
        <RenderErrorInPath errors={errors} errorPath="roles" />
      )}
    </>
  );
};
