import styled from "styled-components";

export const HorizontalContainer = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 1rem;
  padding: 0 1rem;
  border-style: none solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.__borderColor};

  & > * + * {
    margin-left: 1rem;
  }
`;
