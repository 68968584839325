import { ModalBody, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  Skeleton,
} from "@vygruppen/spor-react";
import { InfrastructureEventItem } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/InfrastructureEventItem";
import { useInfrastructureEvent } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/hooks/useInfrastructureEvent";
import { InfrastructureEventType } from "shared/types/infrastructureResponse";
import { isPlannedInfraStructureEvent } from "shared/utils/infrastructureEvent";
import { FC, useState } from "react";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { useStretchBuilder } from "stores/useStretchBuilder";

type EventActionProps = {
  uuids: string[];
};

const eventsByPriority = (
  a: InfrastructureEventType,
  b: InfrastructureEventType,
) => {
  const eventA = a.infrastructureInformation;
  const eventB = b.infrastructureInformation;
  if (isPlannedInfraStructureEvent(eventA.type)) return 1;
  if (isPlannedInfraStructureEvent(eventB.type)) return -1;
  return (
    eventA.priority - eventB.priority ||
    eventB.updatedAt.compare(eventA.updatedAt)
  );
};

export const InfrastructureEventAction: FC<EventActionProps> = ({ uuids }) => {
  const { data, status } = useInfrastructureEvent(uuids);
  const [askConfirmationForUuid, setAskConfirmationForUuid] = useState<
    string | null
  >(null);
  const setStretchBuilderModalState = useStretchBuilder(
    (state) => state.setStretchBuilderModalState,
  );

  const onClose = () => {
    setStretchBuilderModalState({ type: "none" });
  };

  return (
    <Modal isOpen onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent alignItems="center">
        <ModalHeader alignSelf="start">
          Aktive infrastrukturhendelser
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody display="grid" gap={2} w="100%" pb={2}>
          {status === "pending" && <Skeleton height={6} />}
          {status === "error" && <FailureMessage />}
          {status === "success" && (
            <ul>
              {Object.values(data)
                .sort(eventsByPriority)
                .map((event, i) => (
                  <InfrastructureEventItem
                    key={event.infrastructureInformation.uuid}
                    event={event.infrastructureInformation}
                    isFirstItem={i === 0}
                    onClose={onClose}
                    askConfirmation={
                      askConfirmationForUuid ===
                      event.infrastructureInformation.uuid
                    }
                    setAskConfirmation={setAskConfirmationForUuid}
                  />
                ))}
            </ul>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
