import { InfrastructureModalContent } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/InfrastructureModalContent";
import { getInfrastructureEventBody } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/createRequestUtils";
import { useCreateInfrastructureEvent } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/hooks/useCreateInfrastructureEvent";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { ActionModal } from "shared/components/ActionModal";
import { useOpenEditFields } from "stores/useOpenEditFields";
import { useStretchBuilder } from "stores/useStretchBuilder";

type ModalProps = {
  stretchTitle: string;
};

export const CreateInfrastructureEventModal: FC<ModalProps> = ({
  stretchTitle,
}) => {
  const [closeStretchBuilder] = useStretchBuilder((state) => [
    state.closeStretchBuilder,
  ]);

  const {
    mutate,
    isCreateRequestLoading,
    isCreateRequestSuccess,
    isCreateRequestError,
  } = useCreateInfrastructureEvent();

  const { handleSubmit } = useFormContext();

  const openEditFields = useOpenEditFields((state) => state.openEditFields);

  const onSubmit = handleSubmit((data) => {
    const requestBody = getInfrastructureEventBody(data.infrastructureForm);
    mutate(requestBody);
  });

  return (
    <ActionModal
      title="Opprett infrastrukturhendelse"
      actionTitle="Opprett hendelse"
      onClose={closeStretchBuilder}
      onSubmit={onSubmit}
      isLoading={isCreateRequestLoading}
      isSuccess={isCreateRequestSuccess}
      isError={isCreateRequestError}
      isSubmitDisabled={openEditFields.length > 0}
      successMessage="Hendelse opprettet"
      failureMessage="Kunne ikke opprette hendelse. Prøv på nytt, eller kontakt IT hvis feilen vedvarer."
    >
      <InfrastructureModalContent stretchTitle={stretchTitle} />
    </ActionModal>
  );
};
