import colors from "shared/theme/colors";

export const darkTheme = {
  themeName: "dark",

  colorBackgroundMain: colors.darkGloomyShade, // #212121
  colorBackgroundSecondary: colors.darkGrey, // #2B2B2C
  colorBackgroundTetriary: colors.operations, // #383E42
  colorHoverItem: "rgba(136, 139, 142, 0.3)", // #888B8E 30%
  colorTextMain: colors.lightGrey, // #F5F5F5
  colorTextSecondary: colors.steel, // #AFB2B3
  colorTextDisabled: colors.dimGrey, // #606568
  colorSeparationLine: colors.dimGrey, // #606568
  colorOutline: colors.whiteAlpha["400"], // rgba(1,1,1,0.4)
  colorAlarm: colors.rose, // #FF3370
  colorAlarmFill: colors.blossom, // #FFCCDB
  colorAlarmSecondary: colors.saffron, // #FF9B33
  colorWarning: colors.banana, // #FAE053
  colorWarningFill: colors.blonde, // #FDF3BA
  colorSuccess: colors.azure, // #38B49E
  colorSucessFill: colors.seaMist, // #CCEAE4
  colorInfo: colors.sky, // #A7BCD6
  colorInfoFill: colors.sky, // #A7BCD6
  colorIconHighlight: colors.iconHighlight, // #00685E

  //-----
  // LEGACY
  //-----

  // General
  __contentBackgroundColor: colors.operations, // Content background color
  __contentHoverColor: colors.dimGrey,
  __contentSelectedColor: colors.dimGrey,
  __appBackgroundColor: colors.osloGrey, // Base background color
  __todoOploggBackgroundColor: colors.dimGrey, // Right sidebar background color
  __textColor: colors.lightGrey,
  __textColor2: colors.steel,
  __inputBackgroundColor: colors.dimGrey,
  __borderColor: colors.dimGrey,
  __focusBorderColor: colors.greenHaze,

  __redIndicatorColor: colors.rose,
  __orangeIndicatorColor: colors.saffron,
  __yellowIndicatorColor: colors.banana,
  __greenIndicatorColor: colors.azure,
  __blueIndicatorColor: colors.sky,

  // Top Header
  __headerBackground: colors.darkGloomyShade,
  __menuTextHover: colors.blueGreen,

  // Center Content Tabs
  __tabInactiveBackground: colors.dimGrey,

  // Buttons
  __loadingBtnBackgroundColor: colors.dimGrey,
  __loadingBtnColor: colors.silver,

  __primaryBtnBackgroundColor: colors.seaMist,
  __primaryBtnHoverBackgroundColor: colors.coralGreen,
  __primaryBtnGhostHoverBackgroundColor: colors.whiteAlpha["200"],
  __primaryBtnActiveBackgroundColor: colors.mint,
  __primaryBtnColor: colors.darkTeal,
  __primaryBtnDisabledBackgroundColor: colors.dimGrey,
  __primaryBtnDisabledColor: colors.steel,
  __primaryBtnFocusBackgroundColor: colors.coralGreen,

  __underlineBtnDisabledColor: colors.osloGrey,
  __underlineBtnDisabledtextColor: colors.lightGrey,
  __underlineButtonActiveColor: colors.steel,
  __underlineButtonHoverColor: colors.platinum,
  __underlineButtonClickedColor: colors.osloGrey,

  // Links
  __primaryLinkHoverBackgroundColor: colors.dimGrey,
  __primaryLinkActiveBackgroundColor: colors.mint,
  __primaryLinkActiveColor: colors.pine,
  __primaryLinkFocusBackgroundColor: colors.pine,
  __primaryLinkFocusColor: colors.lightGrey,

  // Checkbox
  __checkboxBackgroundColor: colors.osloGrey,
  __checkboxActiveColor: colors.seaMist,
  __checkboxOutlineColor: colors.silver,
  __checkboxCheckmarkColor: colors.darkTeal,
  __checkboxDisabledColor: colors.steel,

  // SkeletonLoader
  __skeletonElementColor: colors.dimGrey,
  __skeletonGradientColor: colors.operations,

  // RadioButton
  __radioBorderColor: colors.primaryGreen,
  __radioBackgroundColor: colors.white,
  __radioFocusBorderColor: colors.azure,
  __radioFocusBackgroundColor: colors.seaMist,

  // InfoMessages
  __expandableInfoMessageBtnHover: colors.dimGrey,
  __expandableInfoMessageBtnActive: colors.darkGrey,

  // SelectList (dropdown menu)
  __dropDownListRowBg: colors.darkGrey,
  __dropDownListRowBgHover: colors.operations,
  __dropDownListtextColor: colors.white,
  __dropDownListtextColorHover: colors.white,
  __dropDownListtextColorDisabled: colors.steel,
  __dropdownHover: colors.dimGrey,
  __dropDownListActiveColor: colors.blueGreen,

  // TrainList
  __vehicleListBorderBottomSelected: colors.steel,

  // Oplogg
  __opLoggDotBorder: colors.dimGrey,
  __newOploggItem: colors.lightRed,
  __oploggDarkText: colors.darkGrey,

  // TrainStop
  __trainStopNotPassedLineColor: colors.osloGrey,
  __trainStopNotPassedColor: colors.steel,

  // Tjenestevarsling Table
  __tableRowAlertHighlight: colors.wine,
  __tableRowWarnHighlight: colors.wood,

  // Puzzle
  __onHoldBackgroundColor: colors.salmon,
  __onHoldColor: colors.darkGrey,

  // Dayplan
  __dayPlanItemBackgroundColor: colors.whiteAlpha["100"],

  // TrainInfoMessage
  __trainInformationReplacedColor: colors.steel,

  // HistoryTable
  __historyTableHeader: colors.grey[600],
  __historyTableHeadingRow: colors.darkGloomyShade,
  __historyTableContent: colors.operations,
  __historyBackground: colors.darkGrey,
  __historyTableRows: "#212121",

  // OverviewTable
  __overviewBorderColor: colors.lightGrey,
  __overviewBorderColorInner: colors.steel,

  // TrainResumed
  __alertTextColor: colors.black,
  __alertBackgroundColor: colors.platinum,

  // Bookmark Items
  __bookmarkBackgroundColor: colors.operations,
};

export type DarkTheme = typeof darkTheme;
