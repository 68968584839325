import React from "react";
import styled from "styled-components";
import { logout } from "app/Auth/auth";
import {
  PersonOutline24Icon,
  SpeechBubbleOutline24Icon,
} from "@vygruppen/spor-icon-react";
import { MenuLink } from "../MenuStyles";

const AdminLinkList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  margin: 4rem 0 0;

  & * ~ * {
    margin-top: 24px;
  }
`;

const AdminLinkItem = styled.li`
  display: flex;
  align-items: center;
`;

export const AdminLinks = () => (
  <AdminLinkList>
    <AdminLinkItem>
      <MenuLink
        href="mailto:team-brukerflater-drops@vy.no?cc=itservicesenter@service-now.com"
        target="_blank"
        rel="noopener noreferer"
      >
        <SpeechBubbleOutline24Icon />
        Feedback / Meld feil
      </MenuLink>
    </AdminLinkItem>
    <AdminLinkItem>
      <MenuLink href="#" onClick={() => logout()}>
        <PersonOutline24Icon />
        Logg ut
      </MenuLink>
    </AdminLinkItem>
  </AdminLinkList>
);
