import styled, { css } from "styled-components";

type ButtonProps = {
  slim?: boolean;
  inline?: boolean;
  large?: boolean;
};

const SlimStyle = css`
  padding: 0;
`;

export const UnderlineButton = styled.button<ButtonProps>`
  display: ${(props) => (props.inline ? "inline" : "flex")};
  justify-content: center;
  align-items: center;
  font-family: "Vy Sans", sans-serif;
  border: none;
  font-size: 16px;
  height: 36px;
  border-radius: 6px;
  margin: 0;
  padding: ${(props) => (props.large ? "1.5rem 5rem" : "0 12px")};
  cursor: pointer;
  background-color: transparent;
  text-decoration: underline;
  color: ${({ theme }) => theme.__underlineButtonActiveColor};

  &:disabled {
    background-color: ${({ theme }) => theme.__underlineBtnDisabledColor};
    color: ${({ theme }) => theme.__underlineBtnDisabledtextColor};
    border-color: ${({ theme }) => theme.__underlineBtnDisabledtextColor};
    stroke: ${({ theme }) => theme.__underlineBtnDisabledtextColor};
    cursor: initial;
  }

  ${(props: ButtonProps) => props.slim && SlimStyle}

  &:hover:enabled {
    color: ${({ theme }) => theme.__underlineButtonHoverColor};
    background-color: transparent;
  }

  &:active:enabled {
    color: ${({ theme }) => theme.__underlineButtonClickedColor};
    background-color: transparent;
  }

  &:disabled {
    color: ${({ theme }) => theme.__underlineBtnDisabledColor};
    background-color: transparent;
    text-decoration: none;
    cursor: initial;
  }
`;
