import { Badge, ChoiceChip, Flex, Spacer, Stack } from "@vygruppen/spor-react";
import {
  getClickedStopPairs,
  getStretchName,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stretchBuilderTexts";
import { FC } from "react";
import { ExpandableInfoMessage } from "shared/components/feedback/InfoMessage/ExpandableInfoMessage/ExpandableInfoMessage";
import { Text } from "shared/components/typography/TitleStyles";
import { useStretchBuilder } from "stores/useStretchBuilder";
import styled from "styled-components";

const InfoMessageWrapper = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 30rem;
  background: inherit;
  z-index: 1;
`;

export const StretchBuilderModeInfo: FC = () => {
  const [
    clickedStops,
    selectedLegs,
    selectedStops,
    allStopsSelected,
    setAllStopsSelected,
    stretchBuilderModalState,
    setStretchBuilderModalState,
    closeStretchBuilder,
  ] = useStretchBuilder((state) => [
    state.clickedStops,
    state.selectedLegs,
    state.selectedStops,
    state.allStopsSelected,
    state.setAllStopsSelected,
    state.stretchBuilderModalState,
    state.setStretchBuilderModalState,
    state.closeStretchBuilder,
  ]);
  if (
    !(
      stretchBuilderModalState.type === "create" ||
      stretchBuilderModalState.type === "edit"
    )
  )
    return null;

  const { singleClickedStop } = getClickedStopPairs(clickedStops);

  const isEditInfrastructure =
    stretchBuilderModalState.type === "edit" &&
    stretchBuilderModalState.eventType === "infrastructure";
  const isEditStation =
    stretchBuilderModalState.type === "edit" &&
    stretchBuilderModalState.eventType === "station";

  const disableInfrastructureButton =
    isEditStation || selectedLegs.length === 0 || singleClickedStop !== null;

  const disableStationButton =
    isEditInfrastructure || selectedStops.length === 0;

  const cancelButton = {
    title: "Avbryt",
    onClick: closeStretchBuilder,
  };

  const infrastructureEventButton = (isDisabled: boolean) => ({
    title: "Infrastruktur",
    isDisabled,
    onClick: () => {
      setStretchBuilderModalState({
        ...stretchBuilderModalState,
        eventType: "infrastructure",
        stretchBuilderMode: false,
      });
    },
  });

  const stationEventButton = (isDisabled: boolean) => ({
    title: "Stasjon",
    isDisabled,
    onClick: () => {
      setStretchBuilderModalState({
        ...stretchBuilderModalState,
        eventType: "station",
        stretchBuilderMode: false,
      });
    },
  });

  const getActionButtons = () => [
    infrastructureEventButton(disableInfrastructureButton),
    stationEventButton(disableStationButton),
    cancelButton,
  ];

  const getStretchInfoBadge = () => {
    const stretchNames = getStretchName(
      selectedLegs,
      clickedStops,
      allStopsSelected,
    );
    return (
      <Flex gap="4px" flexWrap="wrap">
        {stretchNames.map((stretchName) => (
          <Badge
            key={stretchName}
            colorScheme="light-green"
            borderRadius="9px"
            fontWeight="400"
            paddingX="12px !important"
          >
            {stretchName}
          </Badge>
        ))}
        {singleClickedStop && (
          <Badge
            colorScheme="light-green"
            borderRadius="9px"
            fontWeight="400"
            paddingX="12px !important"
          >
            {singleClickedStop.name}
          </Badge>
        )}
      </Flex>
    );
  };

  const stopOrStretchIsSelected = selectedLegs.length > 0 || singleClickedStop;

  return (
    <InfoMessageWrapper>
      <ExpandableInfoMessage
        severity="info"
        title={`${
          stretchBuilderModalState.type === "create" ? `Opprett` : `Endre`
        } hendelse`}
        actionButtons={getActionButtons()}
        initialOpenState
      >
        <Text>Stasjon(er) eller strekning</Text>
        <Spacer height="8px" />
        {stopOrStretchIsSelected ? (
          getStretchInfoBadge()
        ) : (
          <Text secondary>
            Velg stasjon(er) eller definer strekning for å opprette en hendelse.
            Du kan også velge et definert område:
          </Text>
        )}
        <Stack my={3}>
          <Text>Område</Text>
          <ChoiceChip
            size="sm"
            isChecked={allStopsSelected}
            onChange={() => setAllStopsSelected(!allStopsSelected)}
          >
            Hele landet
          </ChoiceChip>
        </Stack>
        Type hendelse
      </ExpandableInfoMessage>
    </InfoMessageWrapper>
  );
};
