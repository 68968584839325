import { AddOutline24Icon } from "@vygruppen/spor-icon-react";
import { Button, ButtonGroup } from "@vygruppen/spor-react";
import { InfrastructureEventAction } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/InfrastructureEventAction";
import { UpcomingEventsButtonAndModal } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/UpcomingEventsButtonAndModal";
import { CreateInfrastructureEvent } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/CreateInfrastructureEvent";
import { EditInfrastructureEvent } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/EditInfrastructureEvent";
import { CreateStationEvent } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/CreateStationEvent";
import { EditStationEvent } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/EditStationEvent";
import { StationEventAction } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/StationEventAction";
import { FC } from "react";
import { useStretchBuilder } from "stores/useStretchBuilder";
import { useTheme } from "styled-components";

export const StretchBuilder: FC = () => {
  const theme = useTheme();
  const [stretchBuilderModalState, setStretchBuilderModalState] =
    useStretchBuilder((state) => [
      state.stretchBuilderModalState,
      state.setStretchBuilderModalState,
    ]);
  const getContent = () => {
    switch (stretchBuilderModalState.type) {
      case "create":
        return stretchBuilderModalState.eventType === "infrastructure" ? (
          <CreateInfrastructureEvent />
        ) : (
          <CreateStationEvent />
        );
      case "edit":
        if (
          stretchBuilderModalState.eventType === "infrastructure" &&
          "type" in stretchBuilderModalState.eventInfo
        ) {
          return (
            <EditInfrastructureEvent
              event={stretchBuilderModalState.eventInfo}
            />
          );
        }
        if (
          stretchBuilderModalState.eventType === "station" &&
          "stationInformationType" in stretchBuilderModalState.eventInfo
        ) {
          return (
            <EditStationEvent event={stretchBuilderModalState.eventInfo} />
          );
        }
        return null;
      case "closeOrEdit": {
        if (stretchBuilderModalState.uuids.station.length > 0) {
          return (
            <StationEventAction
              uuids={stretchBuilderModalState.uuids.station}
            />
          );
        }
        return stretchBuilderModalState.uuids.infrastructure?.length > 0 ? (
          <InfrastructureEventAction
            uuids={stretchBuilderModalState.uuids.infrastructure}
          />
        ) : null;
      }
      case "none":
      default:
        return (
          <ButtonGroup position="absolute" top="12px" left="12px">
            <Button
              _active={{ backgroundColor: theme.__contentHoverColor }}
              leftIcon={<AddOutline24Icon />}
              onClick={() => {
                setStretchBuilderModalState({
                  type: "create",
                  eventType: "infrastructure",
                  stretchBuilderMode: true,
                });
              }}
            >
              Ny hendelse
            </Button>
            <UpcomingEventsButtonAndModal />
          </ButtonGroup>
        );
    }
  };
  return getContent();
};
