import { ChangeEvent, FC, useEffect, useState } from "react";
import { useFormContext, UseFormSetValue } from "react-hook-form";
import { Select } from "shared/components/forms/Select";
import { isPlannedInfraStructureEvent } from "shared/utils/infrastructureEvent";
import { InfraStructureInformation } from "shared/types/infrastructureResponse";
import { log, LogLevel } from "api/cloudWatch";
import { customInputInfo } from "shared/types/form";
import {
  InfrastructureConsequence,
  mapInfrastructureConsequence,
} from "features/CenterContent/RoleContent/AffectedTrains/utils/utils";
import { Checkbox } from "@vygruppen/spor-react";
import { FormSchema, TrainFormSchema } from "../formSchema";
import { GroupedInformationResponse } from "../useInfrastructureInformationForTrain";

const mapKeysToLabel: Record<keyof GroupedInformationResponse, string> = {
  directlyAffecting: "Påvirker direkte",
  rest: "Øvrige",
};

type NarrowedGroup = InfraStructureInformation & { incidentId: string };

function mapToOptions(
  groupedInformation: GroupedInformationResponse,
): Option[] {
  return Object.keys(groupedInformation)
    .map((key) => {
      const typedKey = key as keyof GroupedInformationResponse;
      if (groupedInformation[typedKey].length === 0) {
        return null;
      }
      return {
        label: mapKeysToLabel[typedKey],
        selection: groupedInformation[typedKey]
          .filter((group): group is NarrowedGroup => group.incidentId !== null)
          .map((group) => {
            let displayValue = `${group.stretchName}`;

            if (isPlannedInfraStructureEvent(group.type)) {
              displayValue = `${displayValue} (planlagt)`;
            }

            return {
              id: group.uuid,
              value: group.incidentId,
              displayValue,
            };
          }),
      };
    })
    .filter((opt: Option | null): opt is Option => opt !== null);
}

type Option = {
  label: string;
  selection: {
    id: string;
    value: string;
    displayValue: string;
  }[];
};

function buildOptGroups(...options: Option[]) {
  return (
    <>
      {options.map((group) => (
        <optgroup key={group.label} label={group.label}>
          {group.selection.map((opt) => (
            <option key={opt.value} value={opt.value}>
              {opt.displayValue}
            </option>
          ))}
        </optgroup>
      ))}
    </>
  );
}

type IncidentsForTrainProps = {
  items: GroupedInformationResponse;
};

const handleIncidentSelectChange = ({
  selectedId,
  infrastructureData,
  setValue,
}: {
  selectedId: string;
  infrastructureData: GroupedInformationResponse;
  setValue: UseFormSetValue<{ trainForm: TrainFormSchema }>;
}) => {
  const flattenedInfrastructureData = [
    ...infrastructureData.directlyAffecting,
    ...infrastructureData.rest,
  ];

  const selectedInfrastructureData = flattenedInfrastructureData.find(
    (val) => val.incidentId === selectedId,
  );

  if (!selectedInfrastructureData) {
    return;
  }

  const { consequence, reason, action } = selectedInfrastructureData;

  const updates = [
    {
      name: customInputInfo.event.formField,
      value: mapInfrastructureConsequence(
        consequence?.type as InfrastructureConsequence,
      ),
    },
    {
      name: customInputInfo.reason.formField,
      value: reason?.type ?? "",
    },
    {
      name: customInputInfo.action.formField,
      value: action?.type ?? "",
    },
  ];

  updates.forEach(({ name, value }) => {
    setValue(name, value);
  });
};

export const SelectIncidentsForTrain: FC<IncidentsForTrainProps> = ({
  items,
}) => {
  const label = "Infrastrukturhendelse";
  const formField = "trainForm.incidentIds";

  const [showIncidentsCombobox, setShowIncidentsCombobox] = useState(false);

  const { register, setValue, getValues, reset } = useFormContext<FormSchema>();

  const displayIncidents = buildOptGroups(...mapToOptions(items));

  useEffect(() => {
    if (showIncidentsCombobox) {
      return;
    }

    const currentIncidentId = getValues(formField);

    if (currentIncidentId === undefined) {
      return;
    }

    reset();
  }, [showIncidentsCombobox]);

  return (
    <>
      <Checkbox
        isChecked={showIncidentsCombobox}
        onChange={() => setShowIncidentsCombobox((prev) => !prev)}
      >
        Knytt hendelse til infrastrukturhendelse
      </Checkbox>
      {showIncidentsCombobox && (
        <Select
          label={label}
          placeholder="Velg en infrastrukturhendelse"
          {...register(formField, {
            setValueAs: (v) => {
              if (Array.isArray(v)) {
                return v;
              }

              if (typeof v === "string") {
                if (v === "") {
                  return undefined;
                }

                return [v];
              }

              log(
                LogLevel.error,
                `SelectIncidentsForTrain`,
                `Invalid type for value: ${typeof v}`,
              );

              throw new Error("Invalid type for value");
            },
            onChange: (e: ChangeEvent<HTMLSelectElement>) => {
              handleIncidentSelectChange({
                selectedId: e.target.value,
                infrastructureData: items,
                setValue,
              });
            },
          })}
        >
          {displayIncidents}
        </Select>
      )}
    </>
  );
};
