const VY_DOMAIN_NAME = "@vy.no";
const TEST_DOOMAIN_NAME = "@testdomenet.no";

const removeDomainNameFromUsername = (emailAddress: string) =>
  emailAddress.slice(0, emailAddress.indexOf("@"));

export const removeDomainNameIfPresent = (
  username: string | null | undefined,
) => {
  if (!username) return "";
  if (
    !(username.includes(TEST_DOOMAIN_NAME) || username.includes(VY_DOMAIN_NAME))
  )
    return username;
  return removeDomainNameFromUsername(username);
};
