import React from "react";
import styled from "styled-components";
import { MenuLink } from "../MenuStyles";

const LinkItem = styled.li`
  padding: 12px 0;
`;

export const ExternalLinkItem = ({
  url,
  name,
}: {
  url: string;
  name: string;
}) => (
  <LinkItem>
    <MenuLink href={url} target="_blank" rel="noreferrer noopener">
      {name}
    </MenuLink>
  </LinkItem>
);
