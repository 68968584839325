import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
} from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { mutationFnPOST, queryFnGET } from "api/tanStackQuery/helpers";
import { queryClient } from "api/tanStackQuery/queryClient";
import constate from "constate";
import { useEffect, useState } from "react";
import { useDropsWebsocket } from "websocket/drops/DropsWebsocketContext";
import { OploggForm } from "../formSchema";
import { DropsLogResponse } from "./OploggElement/types";

const PAGINATION_LIMIT = 20;
function useOplogg() {
  const [notifications, setNotifications] = useState(0);
  const { message } = useDropsWebsocket("useDropsLog", ["DROPS_LOG"]);

  const { mutate: post, status: postStatus } = useMutation({
    mutationFn: (data: OploggForm) =>
      mutationFnPOST<undefined, OploggForm>(
        `${getBackendUrl()}/drops-log/logs`,
        data,
      ),
  });

  const { data, hasNextPage, status, isFetchingNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: ["dropsLog"],
      initialPageParam: 0,
      queryFn: ({ signal, pageParam }) =>
        queryFnGET<DropsLogResponse>({
          signal,
          url: `${getBackendUrl()}/drops-log/logs?pageSize=${PAGINATION_LIMIT}${
            pageParam > 0 ? `&lastSeen=${pageParam}` : ""
          }`,
        }),
      getNextPageParam: (lastPage) =>
        lastPage.hasNext ? lastPage.lastSeen : null,
      refetchOnWindowFocus: true,
    });

  useEffect(() => {
    if (message.status === "received" && message.data.topic === "DROPS_LOG") {
      setNotifications(notifications + 1);
      const newMessage = message.data.message;
      queryClient.setQueryData<InfiniteData<DropsLogResponse> | undefined>(
        ["dropsLog"],
        (prev) =>
          prev
            ? {
                ...prev,
                pages: prev.pages.map((page, index) => ({
                  ...page,
                  entries:
                    index === 0
                      ? [
                          newMessage,
                          ...page.entries.filter(
                            (entry) => entry.id !== newMessage.id,
                          ),
                        ]
                      : [
                          ...page.entries.filter(
                            (entry) => entry.id !== newMessage.id,
                          ),
                        ],
                })),
              }
            : prev,
      );
    }
  }, [message]);

  return {
    post,
    postStatus,
    data,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    notifications,
    setNotifications,
  };
}

const [
  OploggProvider,
  usePost,
  usePostStatus,
  useData,
  useStatus,
  useHasNextPage,
  useIsFetchingNextPage,
  useFetchNextPage,
  useNotifications,
  useSetNotifications,
] = constate(
  useOplogg,
  (value) => value.post,
  (value) => value.postStatus,
  (value) => value.data,
  (value) => value.status,
  (value) => value.hasNextPage,
  (value) => value.isFetchingNextPage,
  (value) => value.fetchNextPage,
  (value) => value.notifications,
  (value) => value.setNotifications,
);

export {
  OploggProvider,
  useData,
  useFetchNextPage,
  useHasNextPage,
  useIsFetchingNextPage,
  useNotifications,
  usePost,
  usePostStatus,
  useSetNotifications,
  useStatus,
};
