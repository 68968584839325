import { withErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { StretchBuilder } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/StretchBuilder";
import { TrainMapRenderer } from "features/CenterContent/RoleContent/TrainMap/TrainMapRenderer";
import { useTrainMapTopology } from "features/CenterContent/RoleContent/TrainMap/useTrainMapTopology";
import { useEffect, useRef, useState } from "react";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { Text } from "shared/components/typography/TitleStyles";
import { dropsRole } from "stores/dropsRole";
import { useStretchBuilder } from "stores/useStretchBuilder";
import styled from "styled-components";

const TrainMapWrapper = styled.div`
  position: relative;
  border-radius: 6px;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TrainMap = () => {
  const pageRef = useRef<HTMLDivElement>(null);
  const [mapHeight, setMapHeight] = useState(
    document.documentElement.offsetHeight,
  );
  const [mapWidth, setMapWidth] = useState(
    document.documentElement.offsetWidth,
  );

  const [isStretchBuilderMode, setStretchBuilderModalState] = useStretchBuilder(
    (state) => [state.isStretchBuilderMode, state.setStretchBuilderModalState],
    Object.is,
  );

  const { isVaktleder } = dropsRole();
  const stretchBuilderEnabled = isVaktleder();

  const { trainMapTopology, trainMapTopologyStatus } = useTrainMapTopology();

  const observer = useRef<ResizeObserver>(
    new ResizeObserver(() => {
      if (pageRef.current) {
        setMapHeight(pageRef.current.clientHeight);
        setMapWidth(pageRef.current.clientWidth);
      }
    }),
  );

  useEffect(() => {
    if (pageRef.current) {
      observer.current.observe(pageRef.current);
    }
    return () => {
      observer.current.disconnect();
    };
  }, []);

  const getContent = () => {
    switch (trainMapTopologyStatus) {
      case "pending":
        return <Text>Laster togkart ...</Text>;
      case "success":
        return (
          trainMapTopology && (
            <>
              <TrainMapRenderer
                mapHeight={mapHeight}
                mapWidth={mapWidth}
                topology={trainMapTopology}
                stretchBuilderMode={
                  stretchBuilderEnabled && isStretchBuilderMode()
                }
                onEventClick={(ids: {
                  infrastructure: string[];
                  station: string[];
                }) => {
                  if (stretchBuilderEnabled) {
                    setStretchBuilderModalState({
                      type: "closeOrEdit",
                      uuids: ids,
                    });
                  }
                }}
              />
              {stretchBuilderEnabled && <StretchBuilder />}
            </>
          )
        );
      case "error":
      default:
        return <FailureMessage />;
    }
  };

  return <TrainMapWrapper ref={pageRef}>{getContent()}</TrainMapWrapper>;
};

export default withErrorBoundary(TrainMap);
