import { HStack, useCheckboxGroup } from "@chakra-ui/react";
import { ChoiceChip } from "@vygruppen/spor-react";
import { ShowIfTrue } from "features/TodoOplogg/common/ShowIfTrue";
import { TodoForm, VEHICLE_SET_TYPES } from "features/TodoOplogg/formSchema";
import { useFormContext } from "react-hook-form";
import { useSetShowVehicleSets, useShowVehicleSets } from "../useTodoContext";
import { ArrowIconButton } from "./ArrowIconButton";

export const VehicleSetOptions = () => {
  const showVehicleSets = useShowVehicleSets();
  const setShowVehicleSets = useSetShowVehicleSets();

  const { watch, setValue } = useFormContext<TodoForm>();

  const value = watch("vehicleSetTypes");
  const { getCheckboxProps } = useCheckboxGroup({
    onChange: (val: string[]) => {
      setValue("vehicleSetTypes", val);
    },
  });
  return (
    <>
      <ArrowIconButton text="Marker med:" onClick={setShowVehicleSets} />
      <ShowIfTrue show={showVehicleSets}>
        <HStack maxWidth="375px" wrap="wrap">
          {VEHICLE_SET_TYPES.map((vh, i) => (
            <ChoiceChip
              key={i}
              isChecked={value?.includes(vh.value)}
              {...getCheckboxProps({ value: vh.value })}
            >
              {vh.text}
            </ChoiceChip>
          ))}
        </HStack>
      </ShowIfTrue>
    </>
  );
};
