import { Button, Text } from "@vygruppen/spor-react";
import { FunctionComponent } from "react";
import styled from "styled-components";
import collapseIcon from "../../../../shared/icons/collapse-icon.svg?react";
import expandIcon from "../../../../shared/icons/expand-icon.svg?react";

const MakeToggleIcon = (item: FunctionComponent) => styled(item)<{
  $inverted?: boolean;
}>`
  width: 20px;
  height: auto;

  transform: rotate(${({ $inverted }) => ($inverted ? "180deg" : "0")});

  // This changes color for dark mode
  & g {
    & line {
      stroke: ${({ theme }) => theme.__textColor};
    }
    & g {
      fill: ${({ theme }) => theme.__textColor};
    }
  }
`;

const ExpandIcon = MakeToggleIcon(expandIcon);
const CollapseIcon = MakeToggleIcon(collapseIcon);

const StyledToggleButton = styled(Button)<{
  $isOpen: boolean;
  $expand?: boolean;
}>`
  &&& {
    display: ${({ $isOpen }) => $isOpen && "none"};
    padding: 6px ${({ $expand }) => $expand && "12px"};
  }

  * {
    column-gap: ${({ $expand }) => !$expand && "0px !important"};
    justify-content: center !important;
  }
`;

export interface ButtonProps {
  label?: string;
  isOpen: boolean;
  setOpen: (newState: boolean) => void;
  inverted?: boolean;
  title?: string;
}

interface BaseToggleProps {
  toggleType: "expand" | "collapse";
}

const BaseToggleButton = ({
  label,
  isOpen,
  setOpen,
  inverted = false,
  title,
  toggleType,
  ...rest
}: ButtonProps & BaseToggleProps) => {
  const expand = toggleType === "expand";
  const invert = expand === inverted;
  const ToggleIcon = expand ? ExpandIcon : CollapseIcon;
  return (
    <StyledToggleButton
      $isOpen={isOpen}
      onClick={() => {
        setOpen(!isOpen);
      }}
      $expand={expand}
      title={title}
      variant="ghost"
      leftIcon={invert ? undefined : <ToggleIcon $inverted={invert} />}
      rightIcon={invert ? <ToggleIcon $inverted={invert} /> : undefined}
      {...rest}
    >
      {label && (
        <Text fontSize="xs" fontWeight="400">
          {label}
        </Text>
      )}
    </StyledToggleButton>
  );
};

BaseToggleButton.defaultProps = {
  inverted: false,
};

export default BaseToggleButton;
