import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Flex, FormControl } from "@vygruppen/spor-react";
import { ExpandableTextarea } from "features/TodoOplogg/common/ExpandableTextarea";
import { ShowIfTrue } from "features/TodoOplogg/common/ShowIfTrue";
import {
  TodoForm,
  commonPadding,
  todoForm,
} from "features/TodoOplogg/formSchema";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { SectionTitle } from "shared/components/typography/TitleStyles";
import { dropsRole } from "stores/dropsRole";
import {
  usePost,
  usePostStatus,
  useSetShowRoles,
  useSetShowVehicleSets,
  useShowRoles,
  useShowVehicleSets,
} from "../useTodoContext";
import { RoleOptions } from "./RoleOptions";
import { VehicleSetOptions } from "./VehicleSetOptions";

export const TodoInput = () => {
  const { role: currentRole } = dropsRole();

  const [textareaExpanded, setTextareaExpanded] = useState(false);

  const post = usePost();
  const postStatus = usePostStatus();

  const formMethods = useForm<TodoForm>({
    resolver: zodResolver(todoForm),
    defaultValues: {
      description: "",
      roles: [currentRole],
      vehicleSetTypes: undefined,
    },
  });

  const { reset, handleSubmit } = formMethods;

  const onSubmit = handleSubmit((data) => {
    const { vehicleSetTypes, ...rest } = data;

    if (vehicleSetTypes === undefined) {
      post({ ...rest });
      return;
    }

    post({
      vehicleSetTypes: vehicleSetTypes.sort().join(","),
      ...rest,
    });
  });

  const showRoles = useShowRoles();
  const setShowRoles = useSetShowRoles();
  const showVehicleSets = useShowVehicleSets();
  const setShowVehicleSets = useSetShowVehicleSets();

  useEffect(() => {
    if (postStatus !== "success") {
      return undefined;
    }

    const timeout = setTimeout(() => {
      reset();
      setShowRoles(false);
      setShowVehicleSets(false);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [postStatus]);

  return (
    <Flex flexDirection="column" gap={2} padding={commonPadding}>
      <SectionTitle>Ny melding</SectionTitle>
      <FormProvider {...formMethods}>
        <form onSubmit={onSubmit}>
          <FormControl>
            <ExpandableTextarea
              label="Skriv inn tekst her"
              onExpandChange={setTextareaExpanded}
              formField="description"
              forceExpand={showRoles || showVehicleSets}
            />
          </FormControl>
          <ShowIfTrue show={textareaExpanded}>
            <Flex
              justifyContent="space-between"
              marginTop={2}
              flexDirection="column"
              gap={4}
            >
              <RoleOptions />
              <VehicleSetOptions />
              <Button
                type="submit"
                alignSelf="end"
                size="sm"
                variant="primary"
                isLoading={postStatus === "pending"}
              >
                Send
              </Button>
            </Flex>
          </ShowIfTrue>
        </form>
      </FormProvider>
    </Flex>
  );
};
