import { Text } from "@vygruppen/spor-react";
import { TrainInformationAggregated } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationAggregated";
import { FC } from "react";
import { formatDayDateString } from "shared/utils/datetime";
import { useTheme } from "styled-components";

type TrainInfoVersionsProps = {
  trainInfo: TrainInformationAggregated;
  depth?: number;
  maxDepth?: number;
};

/**
 * Render train info versions, including recursive rendering of the replaced information
 * @param trainInfo the TrainInformationAggregated to render versions for
 * @param depth used to track the number of recursive iterations to avoid crashing when the input contains cycles
 * @param maxDepth used to limit the max amount of versions to render
 */
export const TrainInfoVersions: FC<TrainInfoVersionsProps> = ({
  trainInfo,
  depth = 0,
  maxDepth = 20,
}) => {
  const theme = useTheme();

  return (
    <>
      {trainInfo.versions
        .filter((version) => version.state !== "REPLACED")
        .map((version) => (
          <li key={version.updatedAt}>
            <Text variant="xs">{version.message}</Text>
            <Text variant="xs" textColor={theme.colorTextSecondary}>
              {formatDayDateString(version.updatedAt)}
            </Text>
          </li>
        ))}
      {trainInfo.replaced && depth + 1 < maxDepth && (
        <TrainInfoVersions
          trainInfo={trainInfo.replaced}
          depth={depth + 1}
          maxDepth={maxDepth}
        />
      )}
    </>
  );
};
