import { useMutation, useQueryClient } from "@tanstack/react-query";
import { EditOutline24Icon } from "@vygruppen/spor-icon-react";
import { Text } from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import { DropsLogTextInput } from "features/CenterContent/RoleContent/AffectedTrains/DropsLogTextInput";
import { supportedOpInfoTypes } from "features/CenterContent/shared/operationalInformation/supportedOpInfoTypes";
import { EditTrainInfoModal } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/EditTrainInfoModal";
import { TrainInfoVersions } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoVersions";
import { TrainEventTypeEnum } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainEventTypeEnum";
import {
  TrainInfoMessage as TrainInfoMsg,
  TrainInformationAggregated,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationAggregated";
import { getUrgencySeverityMapping } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/utils";
import { DetailsParams } from "features/CenterContent/VehicleDetails/TrainDetails/TrainDetails";
import { routeChangedEventsQueryKey } from "features/CenterContent/VehicleDetails/TrainDetails/useRouteChangedEvents";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { ActionButton } from "shared/components/feedback/InfoMessage/ActionButtons";
import { ExpandableInfoMessage } from "shared/components/feedback/InfoMessage/ExpandableInfoMessage/ExpandableInfoMessage";
import { formatDayDateString } from "shared/utils/datetime";
import styled, { useTheme } from "styled-components";

const Versions = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
  list-style: none;
  padding: 0;
  margin: 12px 30px 0 0;
`;

type TrainInfoMessageProps = {
  info: TrainInformationAggregated;
  trainInfoWithOpenState: string[];
};

export const TrainInfoMessage: FC<TrainInfoMessageProps> = ({
  info,
  trainInfoWithOpenState,
}) => {
  const theme = useTheme();

  const { trainNumber, trainDate } = useParams<DetailsParams>();
  const queryClient = useQueryClient();

  const [dropsLogText, setDropsLogText] = useState<string | null>(null);
  const { mutate: closeTrainStoppedStatus } = useMutation({
    mutationKey: ["closeTrainStoppedStatus", info.uuid],
    mutationFn: () =>
      mutationFnPOST<string, { dropsLogText?: string }>(
        `${getBackendUrl()}/trainInformation/information/${info.uuid}/close`,
        dropsLogText ? { dropsLogText } : {},
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [routeChangedEventsQueryKey, trainNumber, trainDate],
      });
    },
  });

  const [showSpinner, setShowSpinner] = useState(false);
  const [askConfirmation, setAskConfirmation] = useState<boolean>(false);

  useEffect(() => {
    setShowSpinner(false);
    setAskConfirmation(false);
  }, [info.versions.length]);

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const actionButtons: ActionButton[] = [
    {
      title: "Friskmeld",
      isLoading: showSpinner,
      onClick: () => {
        setAskConfirmation(true);
      },
    },
    {
      title: "Endre",
      onClick: () => setModalOpen(true),
      leftIcon: <EditOutline24Icon />,
      variant: "ghost",
    },
  ];

  const confirmActionButtons: ActionButton[] = [
    {
      title: "Ja, friskmeld",
      variant: "primary",
      isLoading: showSpinner,
      onClick: () => {
        setShowSpinner(true);
        closeTrainStoppedStatus();
      },
    },
    { title: "Avbryt", onClick: () => setAskConfirmation(false) },
  ];

  function getActionButtons(): ActionButton[] {
    if (
      (info.versions[0].state === "OPEN" &&
        supportedOpInfoTypes.includes(info.versions[0].type)) ||
      info.versions[0].type === "TRAIN_GENERAL_INFO" ||
      info.versions[0].type === "TRAIN_GENERAL_WARNING"
    ) {
      if (askConfirmation) {
        return confirmActionButtons;
      }
      return actionButtons;
    }
    return [];
  }

  const getTitle = (trainInfoMessage: TrainInfoMsg): string => {
    switch (trainInfoMessage.state) {
      case "CLOSED":
        return `${trainInfoMessage.title} [friskmeldt]`;
      case "REPLACED":
        return `${trainInfoMessage.title} [erstattet av ny hendelse]`;
      default:
        return trainInfoMessage.title;
    }
  };

  if (trainNumber === undefined || trainDate === undefined) {
    return (
      <FailureMessage customMessage="Kunne ikke hente tognummer eller dato for dette toget." />
    );
  }

  return (
    <ExpandableInfoMessage
      style={{ width: "100%" }}
      key={info.uuid}
      severity={getUrgencySeverityMapping(info.versions[0])}
      colorOverride={
        info.versions[0].state === "REPLACED"
          ? theme.__trainInformationReplacedColor
          : undefined
      }
      title={getTitle(info.versions[0])}
      subTitle={formatDayDateString(info.versions[0].updatedAt)}
      actionButtons={getActionButtons()}
      hideSubtitleWhileOpen
    >
      <Versions>
        {askConfirmation && (
          <Text variant="xs" fontWeight="bold">
            Er du sikker på at du vil friskmelde hendelsen?
          </Text>
        )}
        <TrainInfoVersions
          trainInfo={info}
          maxDepth={askConfirmation ? 1 : undefined}
        />
        {askConfirmation && (
          <DropsLogTextInput
            dropsLogText={dropsLogText}
            setDropsLogText={setDropsLogText}
            uuid={info.uuid}
            defaultEnabled={
              // We almost always want "Tog står" to go to drops log
              info.versions[0].type === TrainEventTypeEnum.TRAIN_STOPPED
            }
          />
        )}
      </Versions>

      {modalOpen && (
        <EditTrainInfoModal
          uuid={info.uuid}
          setModalOpen={setModalOpen}
          trainId={trainNumber}
          nominalDate={trainDate}
          countryCode="NO" // TODO: CountryCode should not be hardcoded
          trainInfoWithOpenState={trainInfoWithOpenState}
        />
      )}
    </ExpandableInfoMessage>
  );
};
