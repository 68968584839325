import { useMutation } from "@tanstack/react-query";
import {
  CheckmarkOutline24Icon,
  CloseFill24Icon,
  TrainOutline24Icon,
} from "@vygruppen/spor-icon-react";
import { Button, HStack } from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import HandledAffectedTrain from "features/CenterContent/RoleContent/AffectedTrains/HandledAffectedTrain";
import { AffectedTrain } from "features/CenterContent/RoleContent/Vaktleder/types";
import { FC, useEffect, useMemo, useState } from "react";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { useLoadingWithTimeout } from "shared/hooks/useLoadingWithTimeout";
import { getFilteredTrains } from "features/CenterContent/RoleContent/AffectedTrains/utils/filterAffectedTrains";
import { sortByInfoUpdatedAt } from "features/CenterContent/RoleContent/AffectedTrains/utils/AffectedTrainSorter";
import {
  AffectedTrainFilter,
  TrainFilter,
} from "features/CenterContent/RoleContent/AffectedTrains/TrainFilter";
import { CloseTrainInformationBatchBody } from "features/CenterContent/RoleContent/AffectedTrains/types";
import { CloseTrainsModal } from "features/CenterContent/RoleContent/AffectedTrains/CloseTrainsModal";

type HandledTrainsProps = {
  trains: AffectedTrain[];
  showFilter: boolean;
};
const HandledTrains: FC<HandledTrainsProps> = ({ trains, showFilter }) => {
  const [filter, setFilter] = useState<AffectedTrainFilter>(null);
  const [selectedTrainIds, setSelectedTrainIds] = useState<AffectedTrain[]>([]);
  const [waitingForUpdatedEvents, setWaitingForUpdatedEvents] = useState(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const {
    mutate: closeAffectedTrainsStatus,
    isError,
    error,
    isPending,
    isSuccess,
    reset: resetMutationState,
  } = useMutation({
    mutationFn: (body: CloseTrainInformationBatchBody) =>
      mutationFnPOST<string, CloseTrainInformationBatchBody>(
        `${getBackendUrl()}/trainInformation/information/close`,
        body,
      ),
  });

  const selectTrain = (trainId: AffectedTrain) => {
    if (selectedTrainIds.includes(trainId)) {
      setSelectedTrainIds(selectedTrainIds.filter((it) => it !== trainId));
    } else {
      setSelectedTrainIds([...selectedTrainIds, trainId]);
    }
  };

  const filteredTrains = getFilteredTrains(filter, trains);

  // On data update and successfully finished mutation
  useEffect(() => {
    if (isSuccess) {
      setWaitingForUpdatedEvents(false);
      resetMutationState();
      setSelectedTrainIds([]);
    }
  }, [trains]);

  useEffect(() => {
    if (isError) {
      setWaitingForUpdatedEvents(false);
      resetMutationState();
    }
  }, [selectedTrainIds]);

  const selectableTrains = useMemo(
    () =>
      filteredTrains.filter((train) => {
        const trainInfo = train.referencedOperationalTrainInformation.at(0);
        const newestVersion = trainInfo?.versions.at(0);
        const newestVersionIsOpen = newestVersion?.state === "OPEN";
        return newestVersionIsOpen;
      }),
    [filteredTrains],
  );

  const {
    setIsLoading: setCloseStatusIsLoading,
    hasTimedOut: closeStatusHasTimedOut,
  } = useLoadingWithTimeout(30_000);

  useEffect(() => {
    if (!isPending) {
      setCloseStatusIsLoading(false);
    }
  }, [isPending]);

  const closeTrains = () => {
    const uuids = selectedTrainIds
      .map((it) => it.referencedOperationalTrainInformation.at(0)?.uuid ?? null)
      .filter(Boolean) as string[];
    closeAffectedTrainsStatus({ uuids });
    setCloseStatusIsLoading(true);
    setWaitingForUpdatedEvents(true);
  };

  return (
    <>
      {modalOpen && (
        <CloseTrainsModal
          setSelectedTrainIds={setSelectedTrainIds}
          selectedTrainIds={selectedTrainIds}
          closeTrains={closeTrains}
          closeModal={() => setModalOpen(false)}
        />
      )}
      {showFilter && (
        <TrainFilter trains={trains} filter={filter} setFilter={setFilter} />
      )}
      {(isError || closeStatusHasTimedOut) && (
        <FailureMessage
          style={{
            margin: "12px",
          }}
          customMessage={`Friskmelding mislyktes: ${error?.message ?? "Tidsavbrudd"}`}
        />
      )}
      {selectedTrainIds.length === 0 && selectableTrains.length > 0 && (
        <HStack mt={2} justifyContent="space-between" px={2}>
          <Button
            size="xs"
            leftIcon={<CheckmarkOutline24Icon />}
            variant="tertiary"
            fontWeight="600"
            onClick={() => {
              setSelectedTrainIds(selectableTrains);
            }}
          >
            Velg alle tog
          </Button>
        </HStack>
      )}
      {(isError || !waitingForUpdatedEvents) && selectedTrainIds.length > 0 && (
        <HStack mt={2} justifyContent="space-between" px={2}>
          <Button
            size="xs"
            variant="secondary"
            fontWeight="600"
            leftIcon={<CloseFill24Icon m="auto" />}
            onClick={() => {
              setSelectedTrainIds([]);
            }}
          >
            Fjern valgte
          </Button>
          <Button
            size="xs"
            leftIcon={<TrainOutline24Icon m="auto" />}
            variant="primary"
            fontWeight="600"
            onClick={() => {
              setModalOpen(true);
            }}
          >
            Friskmeld {selectedTrainIds.length} tog
          </Button>
        </HStack>
      )}
      <ul>
        {filteredTrains.toSorted(sortByInfoUpdatedAt).map((train) => (
          <HandledAffectedTrain
            train={train}
            key={`${train.trainId.identifier}_${train.trainId.nominalDate}_${train.incidentId}`}
            selectedTrainIds={selectedTrainIds}
            selectTrain={selectTrain}
            isLoading={
              !isError &&
              waitingForUpdatedEvents &&
              selectedTrainIds.includes(train)
            }
          />
        ))}
      </ul>
    </>
  );
};

export default HandledTrains;
