import { Tooltip } from "@chakra-ui/react";
import { Badge, Checkbox, HStack, VStack } from "@vygruppen/spor-react";
import { isToday } from "date-fns";
import { makeTimeDisplay } from "features/CenterContent/RoleContent/AffectedTrains/utils/timeDisplay";
import {
  getColorSchemeForTrainInfo,
  getInfoTitle,
} from "features/CenterContent/RoleContent/AffectedTrains/utils/utils";
import { AffectedTrain as AffectedTrainType } from "features/CenterContent/RoleContent/Vaktleder/types";
import { FC } from "react";
import { Text } from "shared/components/typography/TitleStyles";
import { InfrastructureIncidentIcon } from "shared/icons/InfrastructureIncidentIcon";
import { formatDateString } from "shared/utils/datetime";
import styled, { useTheme } from "styled-components";

const CustomGrid = styled.li<{ $borderColor: string }>`
  container-type: inline-size;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content 225px 1fr;
  align-items: center;

  grid-template-rows: 1fr;
  padding: 6px 12px;
  border-bottom: 1px solid ${({ $borderColor }) => $borderColor};

  #journey {
    grid-column: 2;
  }

  #existing-event {
    grid-column: 3;
  }

  #incident {
    grid-column: 4;
  }

  @container (width < 460px) {
    grid-template-rows: 1fr 1fr;

    #journey {
      grid-column: 2;
    }

    #existing-event {
      margin-top: 6px;
      grid-column: 2;
      grid-row: 2;
    }

    #incident {
      grid-column: 3;
      grid-row: 1 / 3;
    }
  }
  @container (width < 330px) {
    #journey {
      grid-column: 2;
    }

    #existing-event {
      margin-top: 6px;
      grid-column: 2;
      grid-row: 2;
    }

    #incident {
      margin-top: 6px;
      grid-column: 2;
      grid-row: 3;
    }
  }
`;

type AffectedTrainProps = {
  train: AffectedTrainType;
  selectedTrains: AffectedTrainType[];
  updateSelectedTrains: (train: AffectedTrainType) => void;
  isPending: boolean;
  currentTime: Date;
};

const AffectedTrain: FC<AffectedTrainProps> = ({
  train,
  selectedTrains,
  updateSelectedTrains,
  isPending,
  currentTime,
}) => {
  const theme = useTheme();
  const trainDateIsToday = isToday(new Date(train.trainId.nominalDate));
  const additionalInfo = train.otherOperationalTrainInformation.at(0);
  const newestVersion = additionalInfo?.versions.at(0);
  const newestVersionIsOpen = newestVersion?.state === "OPEN";

  const inSelectedBatch = selectedTrains.some(
    ({ trainId, incidentId }) =>
      train.trainId.identifier === trainId.identifier &&
      train.incidentId === incidentId &&
      train.trainId.nominalDate === trainId.nominalDate,
  );

  const matchSelectedIncidentId =
    selectedTrains.length > 0
      ? train.incidentId === selectedTrains[0].incidentId
      : true;

  const timeDisplay = makeTimeDisplay(train, currentTime);

  return (
    <CustomGrid $borderColor={theme.__borderColor}>
      <Tooltip
        hasArrow
        placement="right"
        label="Ikke fra samme brudd som valgte tog"
        isDisabled={matchSelectedIncidentId}
        gutter={0}
        shouldWrapChildren
      >
        <Checkbox
          mr={3}
          gridColumn="1"
          isDisabled={isPending || !matchSelectedIncidentId}
          isChecked={inSelectedBatch}
          onChange={() => {
            updateSelectedTrains(train);
          }}
        />
      </Tooltip>

      <HStack id="journey" alignItems="flex-start" flexGrow="1" gap={3}>
        <HStack flexWrap="wrap" justifyContent="space-between" flexGrow="1">
          <VStack alignItems="flex-start">
            <HStack gap={2}>
              <Text bold>{train.trainId.identifier}</Text>
              <Text
                secondary
                style={{
                  color: theme.colorTextSecondary,
                }}
              >
                {timeDisplay}
              </Text>
            </HStack>
            <Text>
              {train.origin} - {train.destination}
            </Text>

            {!trainDateIsToday && (
              <Text secondary>
                {formatDateString(train.trainId.nominalDate, "dd.MM.yy")}
              </Text>
            )}
          </VStack>
        </HStack>
      </HStack>
      {newestVersionIsOpen && additionalInfo && (
        <HStack id="existing-event">
          <Badge
            {...getColorSchemeForTrainInfo(newestVersion)}
            key={additionalInfo.uuid}
            size="xs"
          >
            {getInfoTitle(newestVersion)}
          </Badge>
        </HStack>
      )}
      <HStack id="incident">
        <InfrastructureIncidentIcon />
        <Text
          style={{
            // Behaviour: Use max 3 lines and show "..." at overflow
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: "3",
            WebkitBoxOrient: "vertical",
          }}
        >
          {isPending ? "Behandles..." : train.eventStretchName}
        </Text>
      </HStack>
    </CustomGrid>
  );
};

export default AffectedTrain;
