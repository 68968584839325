import { ChoiceChip, Flex, Skeleton, VStack } from "@vygruppen/spor-react";
import {
  CommonSubTypeProps,
  CommonTrainInfoFormProps,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import { FormSchema } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import {
  Stop,
  useTrainRouteWithoutRefetch,
} from "features/CenterContent/VehicleDetails/TrainDetails/useTrainRoute";
import { FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { RenderErrorInPath } from "shared/components/forms/RenderErrorInPath";
import { Select } from "shared/components/forms/Select";

const BASE_CASE = "INVALID";

export const TrainNotStoppingAtStation: FC<
  CommonTrainInfoFormProps & CommonSubTypeProps
> = ({ mode, trainId, nominalDate }) => {
  const { data: trainRoute, status: fetchStatus } = useTrainRouteWithoutRefetch(
    {
      trainId,
      nominalDate,
    },
  );

  const {
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<FormSchema>();

  const trainStops: Stop[] = trainRoute?.train?.stops ?? [];
  const [selectedStops, setSelectedStops] = useState<string[] | undefined>(
    undefined,
  );

  useEffect(() => {
    if (mode === "create") {
      setValue("trainForm.skippedStops", [], {
        shouldValidate: true,
      });
    }
  }, []);

  useEffect(() => {
    if (mode === "edit" && fetchStatus === "success") {
      const oldStops = getValues("trainForm.skippedStops") ?? [];
      setSelectedStops(
        trainStops
          .filter((stop) => oldStops.includes(stop.stopId))
          .map((stop) => stop.stopId),
      );
    }
  }, [fetchStatus]);

  useEffect(() => {
    if (selectedStops) {
      setValue("trainForm.skippedStops", selectedStops, {
        shouldValidate: true,
      });
    }
  }, [selectedStops]);

  if (fetchStatus === "pending") {
    return <Skeleton height={6} />;
  }

  if (
    fetchStatus === "error" ||
    !trainRoute?.train ||
    trainRoute.train.stops.length <= 0
  ) {
    return (
      <FailureMessage customMessage="Klarte ikke hente ut stoppene til toget. Prøv igjen, eller ta kontakt med IT dersom feilen vedvarer." />
    );
  }

  return (
    <VStack width="100%" alignItems="flex-start" gap={3}>
      <Flex width="100%" gap={1} wrap="wrap">
        {trainStops
          .filter((s) =>
            selectedStops ? selectedStops.includes(s.stopId) : false,
          )
          .map((stop) => (
            <ChoiceChip
              key={stop.stopId}
              chipType="filter"
              size="sm"
              isChecked
              onChange={() => {
                setSelectedStops((prev) =>
                  prev ? prev.filter((s) => s !== stop.stopId) : [],
                );
              }}
            >
              {stop.name}
            </ChoiceChip>
          ))}
      </Flex>
      <Flex width="50%">
        <VStack width="100%" alignItems="flex-start" gap={2}>
          <Select
            onChange={(e) => {
              const { value } = e.target;
              if (value !== BASE_CASE) {
                setSelectedStops((prev) =>
                  prev ? [...prev, value.toString()] : [value.toString()],
                );
              }
            }}
            label="Velg stasjon"
          >
            <option value={BASE_CASE}>Stasjon</option>
            {trainStops
              .filter((stop) =>
                selectedStops ? !selectedStops.includes(stop.stopId) : true,
              )
              .map((stop) => (
                <option value={stop.stopId} key={stop.stopId}>
                  {stop.name}
                </option>
              ))}
          </Select>
          <RenderErrorInPath
            errors={errors}
            errorPath="trainForm.skippedStops"
          />
        </VStack>
      </Flex>
    </VStack>
  );
};
