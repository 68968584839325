import {
  CloseOutline30Icon,
  IconsOutline30Icon,
} from "@vygruppen/spor-icon-react";
import { Box, Divider, Flex, IconButton, Text } from "@vygruppen/spor-react";
import { trainIdKey } from "features/CenterContent/RoleContent/AffectedTrains/utils/utils";
import { ObtrusiveScrollCss } from "shared/components/transitions/scroll";
import styled from "styled-components";
import { TrainResumedElement } from "./TrainResumedElement";
import { TrainResumed } from "./useTrainResumed";

const AlertsContainer = styled.div`
  display: flex;
  width: 430px;
  min-height: 5rem;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
  border: 1px solid ${({ theme }) => theme.colorOutline};
  border-radius: 6px;
  box-sizing: border-box;

  z-index: 1;
`;

const TrainResumedContainer = styled.div`
  ${ObtrusiveScrollCss};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 6px;
  max-height: 55vh;
`;

export const AlertsModal = ({
  trainsResumed,
  setTrainsResumed,
  setShowModal,
}: {
  trainsResumed: TrainResumed[];
  setTrainsResumed: (value: TrainResumed[]) => void;
  setShowModal: (value: boolean) => void;
}) => (
  <div
    style={{
      marginTop: "60px",
      marginLeft: "88px",
      position: "absolute",
      transform: "translateX(-100%)",
    }}
  >
    <AlertsContainer>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        px="10px"
        pb="5px"
        pt="10px"
      >
        <Text fontWeight="bold">Oppdaterte hendelser</Text>
        <IconButton
          variant="ghost"
          aria-label="close"
          icon={<CloseOutline30Icon />}
          onClick={() => {
            setShowModal(false);
          }}
        />
      </Flex>
      <Divider />
      <Box p="10px">
        {trainsResumed.length === 0 && (
          <Flex alignItems="center" gap="5px">
            <IconsOutline30Icon />
            <Text>Her var det ryddig og fint!</Text>
          </Flex>
        )}
        <TrainResumedContainer>
          {trainsResumed.map((trainResumed) => (
            <TrainResumedElement
              key={trainIdKey(trainResumed.trainIdentifier)}
              trainResumed={trainResumed}
              trainResumedIsFinished={() =>
                setTrainsResumed(
                  trainsResumed.filter((it) => trainResumed !== it),
                )
              }
            />
          ))}
        </TrainResumedContainer>
      </Box>
    </AlertsContainer>
  </div>
);
