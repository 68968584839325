import { useState, useEffect } from "react";
import { ArrowIcon } from "shared/icons/ArrowIcon";
import styled from "styled-components";

const DropItButton = styled.button`
  display: flex;
  border-top: 1px solid rgba(0, 0, 0, 0.4);
  padding: 4px 0;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  transition: border-top 0.3s ease;

  &:hover {
    border-top: 1px solid rgba(0, 0, 0, 1);
  }
`;

export const ArrowIconButton = ({
  text,
  onClick,
}: {
  text: string;
  onClick: (currentValue: boolean) => void;
}) => {
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    onClick(clicked);
  }, [clicked]);

  return (
    <DropItButton
      onClick={(e) => {
        e.preventDefault();
        setClicked((prev) => !prev);
      }}
    >
      {text}
      <span className="icon">
        <ArrowIcon rotate={clicked ? -180 : 0} />
      </span>
    </DropItButton>
  );
};
