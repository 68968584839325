import { useQuery } from "@tanstack/react-query";
import { queryFnPOST } from "api/tanStackQuery/helpers";
import {
  OperationalIdentifier_JSON,
  PreviewResponse,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";
import { getBackendUrl } from "api/common";
import {
  FormSchema,
  trainFormSchema,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { useEffect } from "react";
import {
  trainFormToRequestBody,
  TrainOnTrack,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/utils";
import { TrainInfoRequest } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationRequest";

type PreviewProps = {
  formState: FormSchema["trainForm"];
  train: TrainOnTrack;
};

export const useOperationalInfoPreview = ({
  formState,
  train,
}: PreviewProps) => {
  const parsedForm = trainFormSchema.safeParse(formState);

  const {
    status: previewStatus,
    data: previewData,
    refetch,
  } = useQuery({
    queryKey: [formState, train],
    refetchOnWindowFocus: false,
    enabled: false,
    queryFn: ({ signal }) => {
      const { identifier, countryCode, nominalDate } = train;

      const trainForm = parsedForm.success
        ? trainFormToRequestBody(parsedForm.data, train)
        : null;

      let body:
        | (TrainInfoRequest & { trainIdentifier: OperationalIdentifier_JSON })
        | null = null;

      if (trainForm) {
        body = {
          ...trainForm,
          trainIdentifier: {
            operational_identifier: identifier,
            country_code: countryCode,
            nominal_date: nominalDate,
          },
        };
      }

      return queryFnPOST<PreviewResponse>({
        url: `${getBackendUrl()}/trainInformation/preview/${countryCode ?? "NO"}/${identifier}/${nominalDate}`,
        body,
        signal,
      });
    },
  });

  useEffect(() => {
    if (parsedForm.success) {
      refetch();
    }
  }, [parsedForm]);

  return { previewData, previewStatus };
};
