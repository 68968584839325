import { format } from "date-fns/format";
import { isToday } from "date-fns/isToday";
import { nb } from "date-fns/locale/nb";
import {
  IndicatorWrapper,
  LogItem,
  VerticalLine,
  VerticalLineForHighlightedBox,
} from "features/TodoOplogg/Oplogg/OploggElement/styles";
import { DropsLogEntry } from "features/TodoOplogg/Oplogg/OploggElement/types";
import {
  getAge,
  getAgeColor,
} from "features/TodoOplogg/Oplogg/OploggElement/utils";
import React, { FC, useEffect, useState } from "react";
import { CircleIndicator } from "shared/components/dataDisplay/CircleIndicator";
import { useTheme } from "styled-components";
import { HStack, Text } from "@vygruppen/spor-react";

type OploggElementProps = {
  logEntry: DropsLogEntry;
  isLastEntry: boolean;
  isHighlighted: boolean;
  onClick: () => void;
};

export const OploggElement: FC<OploggElementProps> = ({
  logEntry,
  isLastEntry,
  isHighlighted,
  onClick,
}) => {
  const {
    author,
    reportedTime,
    text,
    originSystem,
    originSystemHumanReadable,
  } = logEntry;
  const reportedDateTime = new Date(reportedTime);
  const [age, setAge] = useState(getAge(reportedDateTime));

  const theme = useTheme();
  const textColor = age === "NEW" ? theme.__oploggDarkText : theme.__textColor;
  const subtextColor =
    age === "NEW" ? theme.__oploggDarkText : theme.__textColor2;

  useEffect(() => {
    let interval: any;
    if (age === "NEW" || age === "ALMOST_NEW") {
      interval = setInterval(() => {
        setAge(getAge(reportedDateTime));
      }, 60000); // check age every 60 seconds
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, []);

  const getAuthor = () => {
    if (originSystem === "DROPS_DASHBOARD")
      return (
        author?.slice(
          0,
          author?.includes("@") ? author.indexOf("@") : author.length,
        ) ?? originSystemHumanReadable
      );
    return author
      ? `${author} (${originSystemHumanReadable})`
      : originSystemHumanReadable;
  };

  const isFromToday = isToday(reportedDateTime);

  return (
    <>
      <LogItem bookmarked={isHighlighted} age={age} onClick={onClick}>
        <IndicatorWrapper>
          <CircleIndicator
            $color={getAgeColor(age, theme)}
            style={{ margin: "5px auto 0 auto" }}
          />
          {!isLastEntry && !isHighlighted && (
            <VerticalLine color={theme.__opLoggDotBorder} />
          )}
        </IndicatorWrapper>
        <Text
          variant="xs"
          fontWeight={isHighlighted ? "bold" : "normal"}
          style={{ color: textColor }}
        >
          {format(reportedDateTime, "HH:mm", { locale: nb })}
        </Text>
        <Text
          variant="xs"
          fontWeight={isHighlighted ? "bold" : "normal"}
          style={{
            color: textColor,
            gridColumn: "3",
          }}
        >
          {text}
        </Text>
        <Text
          variant="xs"
          style={{
            color: subtextColor,
            gridColumn: "3",
            textAlign: "end",
          }}
        >
          {getAuthor()}
          {!isFromToday &&
            ` - ${format(reportedDateTime, "dd.MM", { locale: nb })}`}
        </Text>
      </LogItem>
      {isHighlighted && (
        <HStack style={{ alignItems: "flex-start" }}>
          <VerticalLineForHighlightedBox color={theme.__opLoggDotBorder} />
        </HStack>
      )}
    </>
  );
};
