import React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import * as ROUTES from "shared/utils/routes";
import { dropsRole } from "stores/dropsRole";
import { isValidUserRole, roleTexts } from "shared/types/roles";
import { TabIds } from "features/CenterContent/RoleContent/getTabsForRole";
import SelectList from "../../../shared/components/SelectList/SelectList";

const SelectRole = () => {
  const navigate = useNavigate();

  const { role, setRole } = dropsRole();

  return (
    <div title="Velg DROPS-funksjon">
      <SelectList
        id="role"
        width="250px"
        name="role"
        options={roleTexts}
        selected={role}
        onChange={(value: string) => {
          if (!isValidUserRole(value)) return;
          setRole(value);
          navigate(
            generatePath(ROUTES.LANDING, {
              roleIdParam: value,
              tabIdParam: TabIds.START,
            }),
          );
        }}
      />
    </div>
  );
};

export default SelectRole;
