import styled from "styled-components";

export const SummaryWrapper = styled.div`
  display: grid;
  grid-template-columns: min-content;
  column-gap: 9px;
  row-gap: 2px;
  align-items: baseline;

  & .fillrate-failure {
    grid-row: 1;
    grid-column: 2;
  }

  & .train-numbers {
    grid-column: 1 / span 2;
    margin-top: 6px;
  }

  & .route-details {
    grid-column: 1 / span 2;
  }

  & .train-times {
    grid-column: 1 / span 2;
  }
`;

export const TrainNumbers = styled.div`
  display: flex;
  gap: 12px;
`;

export const TrainDate = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.__textColor2};
  margin-left: 12px;
`;
