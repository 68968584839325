import { FC } from "react";
import {
  CommonSubTypeProps,
  CommonTrainInfoFormProps,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import { AffectedStopsFields } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/AffectedStopsFields";
import { useFormContext } from "react-hook-form";
import { FormSchema } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { Input } from "@vygruppen/spor-react";
import { clamp } from "../../../../../../shared/utils";

export const TrainLateToTrack: FC<
  CommonTrainInfoFormProps & CommonSubTypeProps
> = ({ forOtherDirection, ...props }) => {
  const { register, setValue } = useFormContext<FormSchema>();
  const MIN_VALUE = 1;
  const MAX_VALUE = 60 * 24;

  const minutesLateFormValue = forOtherDirection
    ? "trainForm.minutesLateOtherDirection"
    : "trainForm.minutesLate";

  return (
    <>
      <AffectedStopsFields forOtherDirection={forOtherDirection} {...props} />
      <Input
        {...register(minutesLateFormValue)}
        type="number"
        step={1}
        width="13.5rem"
        onChange={(e) =>
          setValue(
            minutesLateFormValue,
            clamp(MAX_VALUE, MIN_VALUE, parseInt(e.target.value, 10)),
          )
        }
        label="Forsinkelse (min)"
      />
    </>
  );
};
