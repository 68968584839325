import React, { FC } from "react";
import styled, { css } from "styled-components";

const focusStyling = css`
  outline: transparent solid 2px;
  border-color: ${({ theme }) => theme.__radioFocusBorderColor};
  background-color: ${({ theme }) => theme.__radioFocusBackgroundColor};

  &:checked {
    background-color: ${({ theme }) => theme.__radioFocusBorderColor};
    box-shadow: inset 0 0 0 4px
      ${({ theme }) => theme.__radioFocusBackgroundColor};
  }
`;

const checkedStyling = css`
  box-shadow: inset 0 0 0 4px ${({ theme }) => theme.__radioBackgroundColor};
  background-color: ${({ theme }) => theme.__radioBorderColor};
`;

const Label = styled.label`
  color: ${({ theme }) => theme.__textColor};
  font-family: VYSans, sans-serif;
  font-size: 1rem;
  line-height: 1.33rem;
  display: inline-flex;
  align-items: center;
  vertical-align: top;
  gap: 6px;
  cursor: pointer;
`;

const RadioInput = styled.input`
  width: 24px;
  height: 24px;
  margin: 0;
  appearance: none;
  /* Safari support */
  -webkit-appearance: none;
  border-color: ${({ theme }) => theme.__radioBorderColor};
  border-style: solid;
  border-width: 2px;
  background-color: ${({ theme }) => theme.__radioBackgroundColor};
  border-radius: 50%;
  cursor: pointer;

  &:focus {
    ${focusStyling};
  }

  &:focus:not(:focus-visible) {
    background-color: ${({ theme }) => theme.__radioBackgroundColor};
    border-color: ${({ theme }) => theme.__radioBorderColor};
    &:checked {
      ${checkedStyling}
    }
  }

  &:focus-visible {
    ${focusStyling};
  }

  &:checked {
    ${checkedStyling}
  }
`;

interface RadioButtonProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  name: string;
  value: string;
  checked: boolean;
}

export const RadioButton: FC<RadioButtonProps> = ({
  name,
  value,
  checked,
  children,
  ...props
}) => (
  <Label {...props}>
    <RadioInput
      type="radio"
      value={value}
      name={name}
      defaultChecked={checked}
    />
    {children}
  </Label>
);
