import { CloseOutline18Icon } from "@vygruppen/spor-icon-react";
import { Text } from "@vygruppen/spor-react";
import { useEffect } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { CrossButton } from "shared/components/buttons/CrossButton";
import { getRoleText } from "shared/types/roles";
import { dropsRole } from "stores/dropsRole";
import { useSidePanelsState } from "stores/useSidePanelsState";
import styled, { useTheme } from "styled-components";
import * as ROUTES from "../../../../shared/utils/routes";
import { getTabsForRole } from "../../RoleContent/getTabsForRole";
import ExpandButton from "./ExpandButton";
import {
  ActiveTab,
  CloseTabs,
  InactiveTab,
  TabList,
  TabText,
  TabsContentWrapper,
} from "./TabsStyle";
import { useActiveTrainTabs, useSetActiveTrainTabs } from "./useTabsContext";

const MAX_TABS = 7;
const LAST_TAB = 8;

const ButtonTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Tabs = () => {
  const navigate = useNavigate();
  const { trainNumber, trainDate, tabIdParam, countryCode } = useParams();

  const hasParams = trainDate && trainNumber && countryCode;

  const { role } = dropsRole();

  const activeTrainTabs = useActiveTrainTabs();
  const setActiveTrainTabs = useSetActiveTrainTabs();
  const storage = sessionStorage.getItem("openTabs");

  const {
    trainListOpen,
    setTrainListOpen,
    oploggTodoListOpen,
    setOploggTodoListOpen,
  } = useSidePanelsState();

  function arrSelectedTrain(prevArr: any) {
    return [
      ...prevArr,
      {
        trainNumber,
        trainDate,
        countryCode,
      },
    ];
  }

  useEffect(() => {
    if (storage) {
      setActiveTrainTabs(JSON.parse(storage));
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem("openTabs", JSON.stringify(activeTrainTabs));
  }, [activeTrainTabs]);

  useEffect(() => {
    const dontExist =
      activeTrainTabs.filter(
        (it: any) =>
          it.trainNumber === trainNumber &&
          it.trainDate === trainDate &&
          it.countryCode === countryCode,
      ).length === 0;
    const notInStorage = storage
      ? JSON.parse(storage).filter(
          (it: any) =>
            it.trainNumber === trainNumber &&
            it.trainDate === trainDate &&
            it.countryCode === countryCode,
        ).length === 0
      : true;

    if (hasParams) {
      if (dontExist && notInStorage && activeTrainTabs.length <= MAX_TABS) {
        setActiveTrainTabs((prevArr: any) => arrSelectedTrain(prevArr));
      }

      if (dontExist && notInStorage && activeTrainTabs.length === LAST_TAB) {
        setActiveTrainTabs((prevArr: any) => {
          prevArr.shift();
          return arrSelectedTrain(prevArr);
        });
      }
    }
  }, [trainNumber, trainDate, countryCode]);

  const theme = useTheme();

  const tabsForAGivenRole = getTabsForRole(role);

  return (
    <TabsContentWrapper>
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* ExpandButton for TrainList */}
        <div style={{ marginBottom: "3px" }}>
          <ExpandButton
            label={"Togliste"}
            isOpen={trainListOpen}
            setOpen={setTrainListOpen}
          />
        </div>
        <TabList style={{ flexGrow: 1 }}>
          {activeTrainTabs.length > 0
            ? activeTrainTabs.map((it: any, i: number) => {
                const selected =
                  it.trainNumber === trainNumber && it.trainDate === trainDate;
                const TabElement = selected ? ActiveTab : InactiveTab;
                return (
                  <TabElement
                    title={`${it.trainNumber} - ${it.trainDate}`}
                    key={i}
                    onClick={(event) => {
                      event.preventDefault();
                      navigate(
                        generatePath(ROUTES.TRAIN, {
                          countryCode: it.countryCode,
                          trainNumber: it.trainNumber,
                          trainDate: it.trainDate,
                        }),
                      );
                    }}
                    style={{
                      padding: "4px 10px",
                      borderRadius: "6px 6px 0px 0px",
                    }}
                  >
                    <ButtonTextContainer>
                      <Text variant="xs" margin="auto">
                        {it.trainNumber || ""}
                      </Text>
                      <CrossButton
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          if (selected) {
                            if (activeTrainTabs.length === 1) {
                              sessionStorage.removeItem("openTabs");
                              navigate(ROUTES.DASHBOARD);
                            } else {
                              const { trainNumber, trainDate, countryCode } =
                                activeTrainTabs[i - 1]
                                  ? activeTrainTabs[i - 1]
                                  : activeTrainTabs[i + 1];

                              navigate(
                                generatePath(ROUTES.TRAIN, {
                                  countryCode,
                                  trainNumber,
                                  trainDate,
                                }),
                              );
                            }
                          }
                          setActiveTrainTabs((prevArr: any) => {
                            prevArr.splice(i, 1);
                            return Array.from(prevArr);
                          });
                        }}
                      >
                        <CloseOutline18Icon marginLeft="4px" />
                      </CrossButton>
                    </ButtonTextContainer>
                  </TabElement>
                );
              })
            : null}
          {activeTrainTabs.length > 1 && (
            <CloseTabs
              title="Lukk alle"
              onClick={() => {
                sessionStorage.removeItem("openTabs");
                setActiveTrainTabs([]);
                navigate(ROUTES.DASHBOARD);
              }}
            >
              <ButtonTextContainer>
                <Text variant="xs" margin="auto">
                  Lukk alle
                </Text>
                <CloseOutline18Icon
                  marginLeft="4px"
                  color={theme.__underlineButtonActiveColor}
                />
              </ButtonTextContainer>
            </CloseTabs>
          )}
        </TabList>
        <TabList>
          {tabsForAGivenRole?.map((it, i) => {
            const isActive = !hasParams && tabIdParam === it.id;
            const TabForRoleItem = isActive ? ActiveTab : InactiveTab;
            const icon = isActive ? it.iconActive : it.iconInactive;
            return (
              <TabForRoleItem
                key={i}
                title="Vis hovedside for valgt funksjon"
                onClick={() => {
                  navigate(
                    generatePath(ROUTES.LANDING, {
                      roleIdParam: role,
                      tabIdParam: it.id,
                    }),
                  );
                }}
              >
                <TabText>
                  {icon}
                  {getRoleText(it.name) ? getRoleText(it.name) : it.name}
                </TabText>
              </TabForRoleItem>
            );
          })}
        </TabList>
        <div style={{ marginBottom: "3px" }}>
          <ExpandButton
            label={"Oplogg"}
            isOpen={oploggTodoListOpen}
            setOpen={setOploggTodoListOpen}
            inverted
          />
        </div>
      </div>
    </TabsContentWrapper>
  );
};

export default Tabs;
