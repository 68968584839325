import { useState } from "react";
import { useOnOutsideMouseclick } from "shared/hooks/useOnOutsideMouseclick";
import { AdminLinks } from "./adminLinks/AdminLinks";
import { ExternalLinks } from "./externalLinks/ExternalLinks";
import { MenuContent, MenuButton, MenuButtonLine } from "./MenuStyles";

export const Menu = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const { mouseclickRef } = useOnOutsideMouseclick(() => {
    setMenuOpen(false);
  });

  return (
    <div ref={mouseclickRef}>
      <MenuContent $isOpen={menuOpen}>
        <ExternalLinks />
        <AdminLinks />
      </MenuContent>

      <div style={{ display: "flex" }}>
        <MenuButton
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
        >
          <MenuButtonLine $isOpen={menuOpen} />
          <MenuButtonLine $isOpen={menuOpen} />
          <MenuButtonLine $isOpen={menuOpen} />
        </MenuButton>
      </div>
    </div>
  );
};
