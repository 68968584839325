import { Box, Textarea } from "@vygruppen/spor-react";
import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { RenderErrorInPath } from "shared/components/forms/RenderErrorInPath";

type ExpandableTextareaProps = {
  label: string;
  onExpandChange: (currentValue: boolean) => void;
  formField: string;
  forceExpand?: boolean;
};

export const ExpandableTextarea = ({
  label,
  onExpandChange,
  formField,
  forceExpand,
}: ExpandableTextareaProps) => {
  const [expanded, setExpanded] = useState(false);
  const updateExpandedDelay = useRef(setTimeout(() => {}, 0));

  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext();

  const description = watch(formField);
  if (description === undefined) {
    throw new Error(
      "FormField must have a defaultValue for this component to behave correctly",
    );
  }
  const shouldBeExpanded = description !== "";

  useEffect(() => {
    onExpandChange(expanded);
  }, [expanded]);

  const onFocus = () => {
    // onBlur sometimes trigger when you focus aswell, wierd bug
    clearTimeout(updateExpandedDelay.current);
    setTimeout(() => {
      setExpanded(true);
    }, 250);
  };

  const updateExpanded = () => {
    // Important to clear in order to update the referenced variables
    clearTimeout(updateExpandedDelay.current);

    updateExpandedDelay.current = setTimeout(() => {
      setExpanded(forceExpand || shouldBeExpanded);
    }, 500);
  };

  useEffect(() => {
    if (expanded !== shouldBeExpanded) {
      updateExpanded();
    }
  }, [description, forceExpand]);

  return (
    <>
      <Textarea
        {...register(formField)}
        onFocus={onFocus}
        onMouseDown={onFocus}
        onBlur={updateExpanded}
        label={label}
        aria-expanded={expanded}
        css={`
          height: 2em;
          width: 100%;
          transition: all 250ms ease;
          resize: none;

          &[aria-expanded="true"] {
            min-height: 8em;
          }
        `}
      />
      {expanded && (
        <Box mt="12px">
          <RenderErrorInPath errors={errors} errorPath={formField} />
        </Box>
      )}
    </>
  );
};
