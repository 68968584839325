import {
  HStack,
  Skeleton,
  Stack,
  StaticAlert,
  tokens,
} from "@vygruppen/spor-react";
import { FormSchema } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/formSchema";
import { InfrastructureProvider } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/InfrastructureProvider";
import {
  getInitialOpenEndedDuration,
  getInitialTimedDuration,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/initialDurationValues";
import { isPlannedInfraStructureEvent } from "shared/utils/infrastructureEvent";
import { FormControlErrorMessage } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/FormControlErrorMessage";
import { FieldErrorOrUndefined } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { InfrastructureCustomInput } from "features/CenterContent/shared/operationalInformation/components/InfrastructureCustomInput";
import {
  EventGroup,
  useOperationalInformationTypes,
} from "features/CenterContent/shared/operationalInformation/hooks/useOperationalInformationTypes";
import {
  filterActions,
  sortAndFilterReasons,
} from "features/CenterContent/shared/operationalInformation/utils";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { Select } from "shared/components/forms/Select";
import styled from "styled-components";

export const SelectListContainer = styled.div`
  display: grid;
  gap: ${tokens.default.size.spacing[3]};
`;

export const SelectLists: FC = () => {
  const { data: opInfoTypes, status: opInfoTypesStatus } =
    useOperationalInformationTypes();
  const {
    register,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<FormSchema>();

  const event = getValues("infrastructureForm.event");
  const durationType = watch("infrastructureForm.durations.type");
  const eventProps = register("infrastructureForm.event", {
    onChange(e) {
      if (isPlannedInfraStructureEvent(e.target.value)) {
        setValue("infrastructureForm.durations", getInitialTimedDuration(), {
          shouldValidate: true,
        });
        setValue("infrastructureForm.reason.type", "WORK_ON_TRACK", {
          shouldValidate: true,
        });
      } else if (durationType !== "OPEN_ENDED") {
        setValue(
          "infrastructureForm.durations",
          getInitialOpenEndedDuration(),
          {
            shouldValidate: true,
          },
        );
      }
    },
  });

  switch (opInfoTypesStatus) {
    case "success":
      return (
        <SelectListContainer>
          <HStack>
            <Select
              label="Hendelse*"
              placeholder="Velg en hendelse"
              {...eventProps}
            >
              {opInfoTypes.events
                .filter((it) => it.group === EventGroup.INFRASTRUCTURE)
                .map((item) => (
                  <option key={item.type} value={item.type}>
                    {item.label}
                  </option>
                ))}
            </Select>
            {!isPlannedInfraStructureEvent(event) && (
              <Select
                label="Aksjonskort"
                placeholder="-"
                {...register("infrastructureForm.actionCard")}
              >
                {opInfoTypes.actionCards.map((item) => (
                  <option key={item.type} value={item.type}>
                    {item.label}
                  </option>
                ))}
              </Select>
            )}
          </HStack>
          <FormControlErrorMessage field={errors?.infrastructureForm?.event} />
          <InfrastructureProvider
            isPlanned={isPlannedInfraStructureEvent(event)}
          />
          <InfrastructureCustomInput
            items={sortAndFilterReasons(opInfoTypes.reasons, "INFRASTRUCTURE")}
            formFieldType="reason"
            isDisabled={isPlannedInfraStructureEvent(event)}
          />
          <FormControlErrorMessage
            field={
              errors?.infrastructureForm?.reason?.type as FieldErrorOrUndefined
            }
          />
          <InfrastructureCustomInput
            items={opInfoTypes.consequences}
            formFieldType="consequence"
          />
          <InfrastructureCustomInput
            items={filterActions(
              opInfoTypes.actions,
              isPlannedInfraStructureEvent(event),
            )}
            formFieldType="action"
          />
        </SelectListContainer>
      );
    case "pending":
      return (
        <Stack gap={2}>
          <Skeleton height={6} />
          <Skeleton height={6} />
          <Skeleton height={6} />
        </Stack>
      );
    case "error":
    default:
      return (
        <StaticAlert variant="error">
          Noe gikk galt ved henting av hendelser, aksjonskort, årsaker og
          konsevenser. Last siden på nytt for å prøve igjen. Ta kontakt med IT
          hvis feilen vedvarer.
        </StaticAlert>
      );
  }
};
