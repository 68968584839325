import { Tooltip } from "@chakra-ui/react";
import {
  Box,
  ClosableAlert,
  HStack,
  Radio,
  RadioGroup,
  Skeleton,
  Text,
  VStack,
} from "@vygruppen/spor-react";
import {
  CommonSubTypeProps,
  CommonTrainInfoFormProps,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import { FormSchema } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import {
  AffectedStopOption,
  splitPassedStopsAndBuildOptGroups,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/utils/affectedStopsUtils";
import { ExpandableInfoMessage } from "shared/components/feedback/InfoMessage/ExpandableInfoMessage/ExpandableInfoMessage";
import {
  Stop,
  useTrainRouteWithoutRefetch,
} from "features/CenterContent/VehicleDetails/TrainDetails/useTrainRoute";
import { FC, useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { Select } from "shared/components/forms/Select";
import { InfrastructureEventType } from "shared/types/infrastructureResponse";
import { RenderErrorInPath } from "shared/components/forms/RenderErrorInPath";
import { getToStopOptions } from "./utils/stationPickerUtils";

type AffectedStopsFieldsProps = {
  alreadySelectedStops?: string[];
  infrastructureEvent?: InfrastructureEventType;
};

type FormValue =
  `trainForm.${"trainRouteSectionOtherDirection" | "trainRouteSection"}.${"fromStop" | "toStop" | "type"}`;

type AffectedStopFormValue =
  `trainForm.${"affectedStops" | "affectedStopsOtherDirection"}`;
export const AffectedStopsFields: FC<
  CommonTrainInfoFormProps & CommonSubTypeProps & AffectedStopsFieldsProps
> = ({
  mode,
  trainId,
  nominalDate,
  alreadySelectedStops,
  forOtherDirection,
  infrastructureEvent,
}) => {
  const {
    control,
    setValue,
    resetField,
    getValues,
    register,
    formState: { errors },
  } = useFormContext<FormSchema>();

  const fromStopFormValue: FormValue = `trainForm.${forOtherDirection ? "trainRouteSectionOtherDirection" : "trainRouteSection"}.fromStop`;
  const toStopFormValue: FormValue = `trainForm.${forOtherDirection ? "trainRouteSectionOtherDirection" : "trainRouteSection"}.toStop`;
  const typeFormValue: FormValue = `trainForm.${forOtherDirection ? "trainRouteSectionOtherDirection" : "trainRouteSection"}.type`;
  const affectedStopsFormValue: AffectedStopFormValue = `trainForm.${forOtherDirection ? "affectedStopsOtherDirection" : "affectedStops"}`;

  const { data: trainRoute, status: fetchStatus } = useTrainRouteWithoutRefetch(
    {
      trainId,
      nominalDate,
    },
  );

  const stops = trainRoute?.train?.stops ?? [];

  const fromStop = getValues(fromStopFormValue);
  const toStop = getValues(toStopFormValue);
  const stopSelectionType = getValues(typeFormValue);

  const firstStop = stops.slice(0, 1)[0]?.stopId;
  const lastStop = stops.slice(-1)[0]?.stopId;

  const fromStopIsLastStop =
    stops.length > 0 && fromStop === stops[stops.length - 1].stopId;

  // When in edit mode we need to see if stop selection should be whole route
  // as this does not come from backend (comes through as between stops)
  useEffect(() => {
    if (mode !== "edit" || stops.length === 0) {
      return;
    }
    if (fromStop === firstStop && toStop === lastStop) {
      setValue(typeFormValue, "WHOLE_ROUTE");
    }
  }, [fetchStatus]);

  useEffect(() => {
    if (stopSelectionType !== undefined) {
      return;
    }
    if (stops.length === 0) {
      return;
    }
    // Try to autofill from InfrastructureEvent
    if (mode === "create" && infrastructureEvent) {
      const affectedLegs =
        infrastructureEvent.infrastructureInformation.affectedLegs.flatMap(
          (leg) => [leg.fromStop, leg.toStop],
        );

      const firstStopIndex = stops.findIndex(({ stopId }) =>
        affectedLegs.includes(stopId),
      );
      const lastStopIndex = stops.findLastIndex(({ stopId }) =>
        affectedLegs.includes(stopId),
      );
      if (firstStopIndex === 0 && lastStopIndex === stops.length - 1) {
        setValue(typeFormValue, "WHOLE_ROUTE");
      } else {
        setValue(typeFormValue, "BETWEEN_STOPS");
      }

      if (firstStopIndex !== -1) {
        setValue(fromStopFormValue, stops[firstStopIndex].stopId);
      }
      if (lastStopIndex !== -1) {
        setValue(toStopFormValue, stops[lastStopIndex].stopId);
      }

      if (firstStopIndex !== -1 && lastStopIndex !== -1) {
        const affectedStops = stops
          .slice(firstStopIndex, lastStopIndex + 1)
          .map(({ stopId }) => stopId);

        setValue(affectedStopsFormValue, affectedStops);
      }
    }

    // Try to autofill the form based on the trainRoutes currentTrainRouteSection:
    // 'fromStop' is the next stop on the route, while 'toStop' is the last stop on the route
    else if (!infrastructureEvent) {
      const currentStopId =
        trainRoute?.train?.currentTrainRouteSection?.fromStopId;
      const currentStopIndex = stops.findIndex(
        (stop) => stop.stopId === currentStopId,
      );
      const nextStopId = currentStopIndex
        ? stops[currentStopIndex + 1]?.stopId
        : currentStopId;

      setValue(
        typeFormValue,
        currentStopId === lastStop ? "AT_STOP" : "BETWEEN_STOPS",
        {
          shouldValidate: true,
        },
      );

      if (nextStopId) {
        setValue(fromStopFormValue, nextStopId, {
          shouldValidate: true,
        });
      } else if (currentStopId) {
        setValue(fromStopFormValue, currentStopId, {
          shouldValidate: true,
        });
      }

      if (lastStop) {
        setValue(toStopFormValue, lastStop, {
          shouldValidate: true,
        });
      }
    }
  }, [fetchStatus, infrastructureEvent, stopSelectionType]);

  // Hold stopSelectionType in sync with from/to
  useEffect(() => {
    if (stops.length === 0 || stopSelectionType === "WHOLE_ROUTE") {
      return;
    }
    if (fromStop === firstStop && toStop === lastStop) {
      setValue(typeFormValue, "WHOLE_ROUTE");
    }
  }, [fromStop, toStop]);

  useEffect(() => {
    // If whole route is chosen, auto fill to first and last
    if (stops.length === 0 || stopSelectionType !== "WHOLE_ROUTE") {
      return;
    }
    setValue(fromStopFormValue, firstStop, {
      shouldValidate: true,
    });
    setValue(toStopFormValue, lastStop, {
      shouldValidate: true,
    });
  }, [stopSelectionType]);

  // Should not be able to select toStop if the chosen fromStop is the trains last stop
  useEffect(() => {
    if (fromStopIsLastStop) {
      setValue(typeFormValue, "AT_STOP", {
        shouldValidate: true,
      });
    }

    // Should not be able to select a toStop that is equal to or before the fromStop
    if (
      stopSelectionType === "BETWEEN_STOPS" &&
      stops.findIndex((s) => s.stopId === toStop) <=
        stops.findIndex((s) => s.stopId === fromStop)
    ) {
      resetField(toStopFormValue);
    }
  }, [fromStop]);

  // keep affectedStops in sync with fromStop and toStop
  useEffect(() => {
    // Don't try to handle affectedStops before fromStop has a value
    if (fromStop === undefined || toStop === undefined) return;

    if (fromStop === null) {
      setValue(affectedStopsFormValue, [], {
        shouldValidate: true,
      });
    } else {
      const fromIndex = stops.findIndex((stop) => stop.stopId === fromStop);
      const toIndex =
        toStop && stopSelectionType !== "AT_STOP"
          ? stops.findIndex((stop) => stop.stopId === toStop)
          : fromIndex;

      setValue(
        affectedStopsFormValue,
        stops.slice(fromIndex, toIndex + 1).map((stop) => stop.stopId),
        {
          shouldValidate: true,
        },
      );
    }
  }, [fromStop, toStop, stopSelectionType, stops]);

  const displayStops = useMemo(
    () => splitPassedStopsAndBuildOptGroups(stops),
    [stops],
  );

  const showTrainPassedWarningBox = useMemo(() => {
    if (infrastructureEvent) return false;

    const selectedStop = stops.find((s) => s.stopId === fromStop);
    return !!(selectedStop && selectedStop.isArrived);
  }, [displayStops, fromStop, infrastructureEvent]);

  const stopsAlreadySelected = useMemo(() => {
    const stopIds = stops.map((s) => s.stopId);

    const selectedStopIds = stopIds.slice(
      fromStop ? stopIds.indexOf(fromStop) : 0,
      toStop ? stopIds.indexOf(toStop) + 1 : -1,
    );

    return (
      (alreadySelectedStops
        ?.filter((stop) => selectedStopIds.includes(stop))
        .map((stopId) => stops.find((s) => s.stopId === stopId))
        .filter((s) => s !== undefined) as Stop[]) ?? []
    );
  }, [fromStop, toStop, alreadySelectedStops, stops]);

  const memoGetFromSelect = useMemo(
    () => getToStopOptions(stops, fromStop),
    [stops, fromStop, infrastructureEvent],
  );

  if (fetchStatus === "pending") {
    return <Skeleton height={6} />;
  }

  if (
    fetchStatus === "error" ||
    !trainRoute?.train ||
    trainRoute.train.stops.length <= 0
  ) {
    return (
      <FailureMessage customMessage="Klarte ikke hente ut stoppene til toget. Prøv igjen, eller ta kontakt med IT dersom feilen vedvarer." />
    );
  }
  return (
    <VStack width="100%" alignItems="flex-start" gap={3}>
      <Controller
        control={control}
        name={typeFormValue}
        render={({ field }) => (
          <RadioGroup
            name="Stasjonsvalg"
            value={field.value ?? ""}
            onChange={field.onChange}
          >
            <Radio value="AT_STOP" name="AT_STOP">
              På stasjon
            </Radio>
            <Radio
              value="BETWEEN_STOPS"
              name="Mellom stasjoner"
              isDisabled={fromStopIsLastStop}
            >
              <Tooltip
                label={
                  fromStopIsLastStop
                    ? `Velg annen "på stasjon"-stopp enn siste stopp`
                    : undefined
                }
              >
                Mellom stasjoner
              </Tooltip>
            </Radio>
            <Radio value="WHOLE_ROUTE" name="Hel strekning">
              Hel strekning
            </Radio>
          </RadioGroup>
        )}
      />
      <VStack gap={2} width="100%" alignItems="flex-start">
        <HStack>
          <VStack gap={2} width="100%" alignItems="flex-start">
            <Select
              {...register(fromStopFormValue)}
              width="200px"
              label={
                stopSelectionType === "AT_STOP"
                  ? "På stasjon"
                  : "Fra og med stasjon"
              }
              placeholder="Velg stasjon"
            >
              {infrastructureEvent
                ? stops.map((stop) => (
                    <AffectedStopOption key={stop.stopId} stop={stop} />
                  ))
                : displayStops}
            </Select>
            <RenderErrorInPath errors={errors} errorPath={fromStopFormValue} />
          </VStack>
          {stopSelectionType !== "AT_STOP" && (
            <VStack gap={2} width="100%" alignItems="flex-start">
              <Select
                {...register(toStopFormValue)}
                width="200px"
                label="Til og med stasjon"
                placeholder="Velg stasjon"
              >
                {memoGetFromSelect}
              </Select>
              <RenderErrorInPath errors={errors} errorPath={toStopFormValue} />
            </VStack>
          )}
        </HStack>
        <RenderErrorInPath errors={errors} errorPath={affectedStopsFormValue} />
      </VStack>
      {showTrainPassedWarningBox && stopSelectionType !== "WHOLE_ROUTE" && (
        <Box w="73%">
          <ClosableAlert flexBasis="100%" variant="info">
            Toget har allerede passert stasjonen.
          </ClosableAlert>
        </Box>
      )}
      {stopsAlreadySelected.length > 0 && (
        <ExpandableInfoMessage
          severity="warning"
          title="Stopp på valgt strekning er allerede innstilt"
        >
          <VStack alignItems="flex-start">
            <Text variant="xs" pt={2} fontWeight="bold">
              Følgende stopp er allerede innstilt:
            </Text>
            <Text variant="xs">
              {stopsAlreadySelected.map((s) => s.name.trim()).join(", ")}
            </Text>
          </VStack>
        </ExpandableInfoMessage>
      )}
    </VStack>
  );
};
