import { Flex } from "@chakra-ui/react";
import { withErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import TodoComponent from "./TodoComponent";
import { TodoInput } from "./TodoInput";

const Todo = () => (
  <Flex flexDir="column" overflowY="auto">
    <TodoInput />
    <TodoComponent />
  </Flex>
);

export default withErrorBoundary(Todo);
