import styled, { css } from "styled-components";

export const VehicleStopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
  padding-top: 15px;
  min-width: 170px;
  width: 170px;
  box-sizing: border-box;

  & .route_fraction {
    margin-bottom: 6px;
  }
`;

export const RouteFraction = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 15px;
`;

export const Line = styled.div<{
  $color: string;
  $isInBetweenStops: boolean;
  $isMissingData: boolean;
}>`
  height: 2px;
  background: ${({ $isInBetweenStops, $color, $isMissingData, theme }) =>
    $isInBetweenStops
      ? `linear-gradient(to right, ${$color} 50%, ${
          $isMissingData
            ? theme.__textColor
            : theme.__trainStopNotPassedLineColor
        } 50%)`
      : $color};
  width: 100%;
`;

export const TrainTime = styled.p<{
  $isCancelled: boolean;
  $hasActualTime: boolean;
}>`
  padding: 0;
  color: ${({ $hasActualTime, theme }) =>
    $hasActualTime ? theme.__textColor : theme.__textColor2};
  line-height: 17px;
  height: 17px;
  font-family: VYSans, sans-serif;
  margin: 0;

  ${({ $isCancelled }) =>
    $isCancelled &&
    css`
      text-decoration: line-through;
    `};
`;
