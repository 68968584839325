import {
  CapacitySeatFill18Icon,
  DropdownDownFill18Icon,
  DropdownUpFill18Icon,
} from "@vygruppen/spor-icon-react";
import { Text } from "shared/components/typography/TitleStyles";
import styled, { useTheme } from "styled-components";
import EstimatedPassengerSVG from "../icons/EstimatedPassanger.svg?react";
import { Count } from "../types";

const FillRateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3px;

  & .passenger_count {
    margin-right: 6px;
  }
`;

export const FillRate = ({ count }: { count: Count }) => {
  const theme = useTheme();

  return (
    <FillRateWrapper>
      {count.passengerCountStatus === "ACTUAL" ? (
        <>
          <CapacitySeatFill18Icon
            color={theme.__textColor2}
            title="Målt antall passasjerer"
          />
          <Text
            secondary
            className="passenger_count"
            title="Målt antall passasjerer"
          >
            {count.passengerCount}
          </Text>
        </>
      ) : (
        <>
          <EstimatedPassengerSVG
            color={theme.__textColor2}
            title="Estimert antall passasjerer"
          />
          <Text
            secondary
            className="passenger_count"
            title="Estimert antall passasjerer"
          >
            {count.passengerCount}
          </Text>
        </>
      )}
      <DropdownUpFill18Icon title="Påstigning" color={theme.__textColor2} />
      <Text secondary title="Påstigning">
        {count.passengersIn}
      </Text>
      <DropdownDownFill18Icon title="Avstigning" color={theme.__textColor2} />
      <Text secondary title="Avstigning">
        {count.passengersOut}
      </Text>
    </FillRateWrapper>
  );
};
