import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@vygruppen/spor-react";
import { UpcomingEventsList } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/UpcomingEventsList";
import { FC } from "react";
import { InfraStructureInformation } from "shared/types/infrastructureResponse";
import { isPlannedInfraStructureEvent } from "shared/utils/infrastructureEvent";
import { useStretchBuilder } from "stores/useStretchBuilder";

type PlannedInfraListProps = {
  data: InfraStructureInformation[];
};

export const UpcomingEvents: FC<PlannedInfraListProps> = ({ data }) => {
  const setStretchBuilderModalState = useStretchBuilder(
    (state) => state.setStretchBuilderModalState,
  );
  const onEditClick = (event: InfraStructureInformation) =>
    setStretchBuilderModalState({
      type: "edit",
      eventType: "infrastructure",
      eventInfo: event,
      stretchBuilderMode: false,
    });

  const plannedEvents = data.filter((event) =>
    isPlannedInfraStructureEvent(event.type),
  );
  const unplannedEvents = data.filter(
    (event) => !isPlannedInfraStructureEvent(event.type),
  );

  return (
    <Tabs
      variant="base"
      colorScheme="base"
      isFitted
      gap={3}
      size="sm"
      marginTop={3}
    >
      <TabList>
        <Tab>Vis alle</Tab>
        <Tab>Planlagt</Tab>
        <Tab>Oppstått</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <UpcomingEventsList data={data} onEditClick={onEditClick} />
        </TabPanel>
        <TabPanel>
          <UpcomingEventsList data={plannedEvents} onEditClick={onEditClick} />
        </TabPanel>
        <TabPanel>
          <UpcomingEventsList
            data={unplannedEvents}
            onEditClick={onEditClick}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
