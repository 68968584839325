import { Switch, useColorMode } from "@vygruppen/spor-react";
import React from "react";
import {
  NightOutline24Icon,
  SummerOutline24Icon,
} from "@vygruppen/spor-icon-react";
import styled from "styled-components";

const DarkModeSwitchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin-left: 12px;
`;

const DarkModeSwitch = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <DarkModeSwitchContainer>
      <SummerOutline24Icon />
      <Switch
        size="sm"
        isChecked={colorMode === "dark"}
        onChange={toggleColorMode}
        title="Nattmodus"
      />

      <NightOutline24Icon />
    </DarkModeSwitchContainer>
  );
};

export default DarkModeSwitch;
