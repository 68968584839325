import styled from "styled-components";
import { ObtrusiveScrollCss } from "../transitions/scroll";

type Props = {
  $shouldHighlight?: boolean;
  $disabled?: boolean;
  $width?: string;
  $maxDropDownHeight?: string;
};

export const SelectListContainer = styled.div`
  position: relative;
  color: ${({ theme }) => theme.__textColor};
  cursor: pointer;
`;

export const SelectListLITag = styled.li<Props>`
  padding: 0.5rem 1rem;
  font-size: 16px;
  transition: all 0.1s ease-in;
  background-color: ${(props: Props) =>
    props.$shouldHighlight
      ? ({ theme }) => theme.__dropDownListRowBgHover
      : ({ theme }) => theme.__dropDownListRowBg}
  color: ${(props: Props) => {
    if (props.$disabled) {
      return ({ theme }) => theme.__dropDownListtextColorDisabled;
    }
    if (props.$shouldHighlight) {
      return ({ theme }) => theme.__dropDownListtextColorHover;
    }
    return ({ theme }) => theme.__dropDownListtextColor;
  }};
  
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.__dropdownHover};
    color: ${(props: Props) =>
      props.$disabled
        ? ({ theme }) => theme.__dropDownListtextColorDisabled
        : ({ theme }) => theme.__dropDownListtextColorHover};
  }
`;

export const SelectListULTag = styled.ul<Props>`
  list-style: none;
  position: absolute;
  right: initial;
  top: initial;
  text-align: left;
  overflow: auto;
  background-color: ${({ theme }) => theme.__dropDownListRowBg};
  width: ${(props) => (props.$width ? props.$width : "100%")};
  max-height: ${(props) =>
    props.$maxDropDownHeight ? props.$maxDropDownHeight : "auto"};
  z-index: 100;
  margin: 0;
  padding: 0;
  border-radius: 0 0 6px 6px;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2));
  ${ObtrusiveScrollCss};
`;

type DropDownBtnProps = {
  $isActive: boolean;
};

export const DropDownBtn = styled.button<DropDownBtnProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 180px;
  border-style: solid;
  border-width: 1px;
  border-color: ${(props: DropDownBtnProps) =>
    props.$isActive
      ? ({ theme }) => theme.__dropDownListActiveColor
      : ({ theme }) => theme.__textColor2};
  color: ${({ theme }) => theme.__textColor};
  padding: 0.5rem 12px 0.6rem;
  text-align: left;
  border-radius: ${(props: DropDownBtnProps) =>
    props.$isActive ? "6px 6px 0 0" : "6px"};
  height: 42px;
  cursor: pointer;

  &:hover {
    ${({ $isActive, theme }) =>
      !$isActive && `  box-shadow: 0 1px 5px ${theme.__textColor}40;`}
  }
`;
