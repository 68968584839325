import { AffectedTrainFilter } from "features/CenterContent/RoleContent/AffectedTrains/TrainFilter";
import { groupTrains } from "features/CenterContent/RoleContent/AffectedTrains/utils/groupTrainSeries";
import { AffectedTrain } from "features/CenterContent/RoleContent/Vaktleder/types";

export const setSessionStorageFilter = (filter: AffectedTrainFilter) =>
  sessionStorage.setItem("unhandledTrainsFilter", JSON.stringify(filter));

export const getSessionStorageFilter = (): AffectedTrainFilter => {
  const filterDataFromSessionStorage = sessionStorage.getItem(
    "unhandledTrainsFilter",
  );

  return filterDataFromSessionStorage
    ? JSON.parse(filterDataFromSessionStorage)
    : null;
};

export const getFilteredTrains = (
  filter: AffectedTrainFilter,
  allTrains: AffectedTrain[],
) => {
  const allTrainsGrouped = groupTrains(allTrains);
  if (
    filter === null ||
    !Object.keys(allTrainsGrouped).includes(filter.incidentId)
  )
    return allTrains;
  const trainSeriesInIncident = allTrainsGrouped[filter.incidentId].trainSeries;

  const filteredSeries = Object.entries(trainSeriesInIncident)
    .filter(([seriesId]) => filter.trainSeriesIds.includes(seriesId))
    .flatMap(([, train]) => train);
  return filteredSeries;
};
