import { Flex, LinkOverlay, Tooltip } from "@chakra-ui/react";
import {
  PositionFill24Icon,
  PositionOutline24Icon,
} from "@vygruppen/spor-icon-react";
import { Badge } from "@vygruppen/spor-react";
import { format } from "date-fns/format";
import { isToday } from "date-fns/isToday";
import { nb } from "date-fns/locale/nb";
import { DateSpan } from "features/VehicleList/common/DateSpan";
import ElementWrapper from "features/VehicleList/common/ElementWrapper";
import { Header } from "features/VehicleList/common/Header";
import { Link, generatePath, useSearchParams } from "react-router-dom";
import { CallButton } from "shared/components/buttons/CallButton";
import { Text } from "shared/components/typography/TitleStyles";
import { AlternativeTransport } from "shared/types/alternativeTransport";
import { formatDateString } from "shared/utils/datetime";
import { BUS } from "shared/utils/routes";
import { useTheme } from "styled-components";

type Props = {
  alternativeTransport: AlternativeTransport;
  elementSelected: boolean;
  hasTracking: boolean;
};

export const AlternativeTransportElement = ({
  alternativeTransport,
  elementSelected,
  hasTracking,
}: Props) => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const {
    scheduledArrivalTimeDestination,
    scheduledDepartureTimeOrigin,
    workShiftId,
    driverPhoneNumber,
    trainIds,
    originStopName,
    destinationStopName,
    isShuttleVehicle,
    vehicleStatus,
  } = alternativeTransport;

  const originTime = scheduledDepartureTimeOrigin
    ? format(scheduledDepartureTimeOrigin, "HH:mm", { locale: nb })
    : "";

  const destinationTime = scheduledArrivalTimeDestination
    ? format(scheduledArrivalTimeDestination, "HH:mm", { locale: nb })
    : "";

  const isNotToday =
    scheduledArrivalTimeDestination &&
    !isToday(new Date(scheduledArrivalTimeDestination));

  const isCancelled = vehicleStatus === "CANCELLED";

  const headerText = [workShiftId, trainIds.join(", ")]
    .filter((str): str is string => str !== null)
    .join(" - ");

  const textDecorationStyle = {
    textDecoration: isCancelled ? "line-through" : "none",
  };

  function getVehicleLabel() {
    switch (vehicleStatus) {
      case "PLANNED":
        return <Badge colorScheme="light-yellow">Ikke bekreftet</Badge>;
      case "CANCELLED":
        return <Badge colorScheme="red">Kansellert</Badge>;
      case "CONFIRMED":
      default:
        return driverPhoneNumber ? (
          <CallButton phoneNumber={driverPhoneNumber} size="xs" />
        ) : null;
    }
  }

  return (
    <ElementWrapper selected={elementSelected}>
      <LinkOverlay
        as={Link}
        display="flex"
        flexDir="column"
        gap={0.5}
        flex={1}
        to={`${generatePath(BUS, { vehicleUuid: alternativeTransport.id })}?${searchParams.toString()}`}
        _focusVisible={{
          outline: `2px solid ${theme.__focusBorderColor}`,
          borderRadius: "6px",
        }}
      >
        <Header>
          <Text
            bold
            style={{
              display: "block",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              maxWidth: "9rem",
              ...textDecorationStyle,
            }}
            title={headerText}
          >
            {headerText}
          </Text>
          {isShuttleVehicle && (
            <Text secondary style={{ ...textDecorationStyle }}>
              Pendel
            </Text>
          )}
        </Header>
        <Text
          style={{
            gridColumn: "2",
            gap: "3px",
            ...textDecorationStyle,
          }}
          title={`${originStopName ?? ""} - ${destinationStopName ?? ""}`}
        >
          {`${originStopName ?? ""} - ${destinationStopName ?? ""}`}
        </Text>
        <Text
          style={{
            gridColumn: "2",
            ...textDecorationStyle,
          }}
        >
          {originTime} - {destinationTime}
          {isNotToday && (
            <DateSpan>
              ({formatDateString(scheduledArrivalTimeDestination, "dd.MM.yy")})
            </DateSpan>
          )}
        </Text>
      </LinkOverlay>

      <Flex gap="18px" alignItems="center" zIndex={1}>
        {getVehicleLabel()}

        {hasTracking ? (
          <Tooltip label="Denne bussen har aktiv sporing">
            <PositionFill24Icon color={theme.colorIconHighlight} />
          </Tooltip>
        ) : (
          <Tooltip label="Denne bussen har ikke aktiv sporing">
            <PositionOutline24Icon />
          </Tooltip>
        )}
      </Flex>
    </ElementWrapper>
  );
};
