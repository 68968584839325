import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";

export type TrainRouteResponse = {
  uuid: string;
  firstStop: Stop;
  lastStop: Stop;
};

type Stop = {
  ids: Array<StopId>;
};

type StopId = {
  id: string;
  name?: string;
};

type Props = {
  trainId: string | undefined;
  nominalDate: string | undefined;
};

export const routeChangedEventsQueryKey = "routeChangedEvents";
export const useRouteChangedEvents = ({ trainId, nominalDate }: Props) => {
  const { status, data, isSuccess } = useQuery({
    queryKey: [routeChangedEventsQueryKey, trainId, nominalDate],
    queryFn: ({ signal }) =>
      queryFnGET<TrainRouteResponse[]>({
        signal,
        url: `${getBackendUrl()}/trainInformation/train/existing-changes/NO/${trainId}/${nominalDate}`,
      }),
  });
  return { status, data, isSuccess };
};
