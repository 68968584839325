import { ErrorOutline24Icon } from "@vygruppen/spor-icon-react";
import React from "react";
import { FCWC } from "shared/utils/FCWC";
import { PrimaryLink } from "../../navigation/PrimaryLink";
import { Message, Wrapper } from "./styles";

interface FailureMessageProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  customMessage?: string;
}

export const FailureMessage: FCWC<FailureMessageProps> = ({
  customMessage,
  ...props
}) => (
  <Wrapper data-testid="failureMessage" {...props}>
    <ErrorOutline24Icon />
    <Message>
      {customMessage ? (
        customMessage
      ) : (
        <>
          Noe gikk galt ved lasting av innhold. Last siden på nytt for å prøve
          igjen. Ta kontakt med IT
          <PrimaryLink
            href="mailto:team-brukerflater-drops@vy.no?cc=itservicesenter@service-now.com"
            target="_blank"
            rel="noopener noreferer"
            style={{ margin: "0 0.5ch", display: "inline" }}
          >
            her
          </PrimaryLink>
          hvis feilen vedvarer.
        </>
      )}
    </Message>
  </Wrapper>
);
