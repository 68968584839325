import z from "zod";

export type TrainInformationAggregatedResponse = {
  trainInformation: TrainInformationAggregated[];
};

const trainIdentifier = z.object({
  nominal_date: z.string(),
  country_code: z.string(),
  operational_identifier: z.string(),
});

// NB! The order of the enum values is important, it's being used to sort by urgency
export enum TrainInfoState {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
  REPLACED = "REPLACED",
}
const trainInfoState = z.nativeEnum(TrainInfoState);

export const trainInfoMessageSchema = z.object({
  version: z.number(),
  state: trainInfoState,
  updatedAt: z.string(), // represents an ISO Date (on the form 2023-11-27T09:45:40.976+01:00)
  priority: z.number(),
  trainIdentifier,
  message: z.string(),
  title: z.string(),
  type: z.string(),
  dropsLogText: z.object({ NOB: z.string() }).nullish(),
  fromStop: z.string().nullish(),
  toStop: z.string().nullish(),
});
export type TrainInfoMessage = z.infer<typeof trainInfoMessageSchema>;

/**
 * Unfortunately we need this elaborate trick to allow for recursive types in zod.
 * @see https://github.com/colinhacks/zod#recursive-types
 */
const trainInformationAggregatedBaseSchema = z.object({
  uuid: z.string(),
  versions: trainInfoMessageSchema.array(),
});
export type TrainInformationAggregated = z.infer<
  typeof trainInformationAggregatedBaseSchema
> & {
  replaced?: TrainInformationAggregated;
};
export const trainInformationAggregatedSchema: z.ZodType<TrainInformationAggregated> =
  trainInformationAggregatedBaseSchema.extend({
    replaced: z.lazy(() => trainInformationAggregatedSchema.optional()),
  });
